import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { postData, apiUrl } from "../Networking/Api";
import Swal from "sweetalert2";

function AssistanceProfile() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    photo: "",
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const [photoFile, setPhotoFile] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const getAssistanceDetails = async () => {
    try {
      const clinicId = localStorage.getItem("clinicId");
      const refernceId = localStorage.getItem("refernceId");
      const payload = {
        modelName: "assistances",
        whereCondition: {
          clinic_id: clinicId,
          id: refernceId,
          is_active: 1,
        },
      };

      const response = await postData("masters/getMasterList", payload);
      console.log("Res", response);

      if (response && response.data && response.data.length > 0) {
        const assistance = response.data[0];
        setFormData({
          firstName: assistance.f_name,
          lastName: assistance.l_name,
          email: assistance.email,
          photo: assistance.assistant_profile_photo,
        });
      }
    } catch (error) {
      console.error("Error fetching clinic details:", error);
    }
  };

  useEffect(() => {
    getAssistanceDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "photo" && files && files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData({
          ...formData,
          photo: reader.result,
        });
        setPhotoFile(files[0]);
      };
      reader.readAsDataURL(files[0]);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName.trim()) {
      errors.firstName = "First Name Is Required";
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "Last Name Is Required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email Is Required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Enter Valid Email";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const updateAssistanceDetails = async () => {
    if (!validateForm()) {
      return;
    }
    setIsUpdating(true);
    try {
      const clinicId = localStorage.getItem("clinicId");
      const refernceId = localStorage.getItem("refernceId");
      const payload = {
        modelName: "assistances",
        id: refernceId,
        inputData: {
          f_name: formData.firstName,
          l_name: formData.lastName,
          email: formData.email,
          assistant_profile_photo: photoFile
            ? formData.photo
            : formData.photo.replace(apiUrl, ""),
          clinicId: clinicId,
        },
      };

      const response = await postData("masters/createAndUpdateMaster", payload);
      console.log("Update Res", response);
      if (response && response.data) {
        Swal.fire({
          title: "Success",
          text: "Assistance Info Updated Successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: true,
        });
        getAssistanceDetails();
      }
    } catch (error) {
      console.error("Error updating assistance details:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      <Header />
      <div className="pt-16">
        <div>
          <div>
            <div className="container mx-auto px-4 mt-8 ">
              <h1 className="font-fontPoppins flex justify-center  text-2xl font-semibold xss:text-xs sm:text-lg leading-normal mt-3 mb-3">
                Assistance Profile
              </h1>
              <div className="bg-gray-100 rounded-lg p-3">
                <div className="w-full">
                  <div className="w-full md:flex items-center gap-3">
                    <div className="w-full relative">
                      <label className="font-fontPoppins text-xs font-semibold">
                        First Name<span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <input
                          className={`w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow ${
                            formErrors.firstName && "border-red-500"
                          }`}
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value={formData.firstName}
                          onChange={handleInputChange}
                        />
                        {formErrors.firstName && (
                          <p className="text-red-500 text-xs absolute top-full left-0">
                            {formErrors.firstName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="w-full xss:mt-3 sm:mt-3 relative">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Last Name<span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <input
                          className={`w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow ${
                            formErrors.lastName && "border-red-500"
                          }`}
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={formData.lastName}
                          onChange={handleInputChange}
                        />
                        {formErrors.lastName && (
                          <p className="text-red-500 text-xs absolute top-full left-0">
                            {formErrors.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:flex items-center gap-3 mt-3">
                    <div className="w-full">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Email<span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <input
                          className={`w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow ${
                            formErrors.email && "border-red-500"
                          }`}
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        {formErrors.email && (
                          <p className="text-red-500 text-xs absolute top-full left-0">
                            {formErrors.email}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:flex flex-start gap-3 mt-3">
                    <div className="sm:w-80">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Profile Photo<span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center gap-3">
                        <div className="w-full relative">
                          <span className=" z-10 absolute px-3 py-1">
                            <i className="fa-solid fa-camera"></i>
                          </span>
                          <input
                            className="rounded-full w-full h-9 px-3 text-sm indent-5 py-1 font-fontPoppins text-gray-400  focus:outline-none focus:ring ring-gray-200 shadow"
                            type="file"
                            placeholder="Upload"
                            name="photo"
                            accept="image/jpeg, image/png"
                            onChange={handleInputChange}
                          />
                          {formData.photo && (
                            <img
                              // src={
                              //   photoFile
                              //     ? formData.photo
                              //     : `${apiUrl}/${formData.assistant_profile_photo}`
                              // }
                              src={
                                formData.photo.startsWith("data:")
                                  ? formData.photo
                                  : `${apiUrl}/${formData.photo}` ||
                                    "/placeholder.png"
                              }
                              alt=""
                              className="absolute top-1/2 transform -translate-y-1/2 right-2 w-9 h-9 rounded-full"
                            />
                          )}
                        </div>
                        <button
                          type="submit"
                          className="w-24 h-9 bg-blueColor text-white font-fontPoppins text-xs rounded-full"
                          onClick={updateAssistanceDetails}
                          disabled={isUpdating}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AssistanceProfile;
