import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Tabs = ({ tabs, children }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
   const { tabId } = useParams();
    const navigate = useNavigate();
     useEffect(() => {
       if (tabId) {
         setActiveTab(tabId);
       }
     }, [tabId]);

 const handleTabChange = (id) => {
   setActiveTab(id);
 };
  return (
    <div className="tabs bg-white shadow-md rounded-lg">
      {/* Tab List Wrapper */}
      <div className="tab-list bg-gray-200 rounded-lg p-2 flex gap-2 sm:gap-4 mb-6 lg:w-1/2 mx-auto">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => handleTabChange(tab.id)}
            className={`flex-grow px-2 py-2 text-sm sm:px-4 sm:py-3 sm:text-lg rounded-md inline-flex items-center justify-center ${
              activeTab === tab.id ? "bg-blue-500 text-white" : "text-black"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div>
        {children.map((child) => (
          <div
            key={child.props.id}
            className={`${activeTab === child.props.id ? "" : "hidden"}`}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
