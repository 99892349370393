import Header from "./Header";
import Footer from "./Footer";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { postData } from "../Networking/Api";
import StepsForRegistration from "./StepsForRegistration";
import CustomSelect from "./CustomSelect";
import CustomSelectHealth from "./CustomSelectHealth";
import Swal from "sweetalert2";
import Loader2 from "./Loader2";

function HealthInsuranceCarrier() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    userDetails,
    organizationData,
    providerData,
    clinicServicesMapping,
    clinicServicesData,
    providerTimeslots,
    addLocations,
    providerisChecked,
    assistanceisChecked,
    mappingisChecked,
  } = location.state || {};

  const [formData, setFormData] = useState({
    insuranceCarrierName: "",
    selectedPlan: "",
    is_active: 1,
  });
  const [insuranceOptions, setInsuranceOptions] = useState([]);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [selectedCarrierId, setSelectedCarrierId] = useState(null);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [errors, setErrors] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const [records, setRecords] = useState([]);
  const [navError, setNavError] = useState("");
  const [OrganizationData, setOrganizationData] = useState({});
  const [providerisCheck, setProviderIsCheck] = useState();
  const [mappingisCheck, setMappingisCheck] = useState();
  const [assistanceisCheck, setAssistanceisCheck] = useState();
  const [ProviderData, setProviderData] = useState({});
  const [ClinicService, setClinicServicesData] = useState({});
  const [ClinicServiceMapping, setClinicServicesMapping] = useState({});
  const [addLocation, setAddLocation] = useState([]);
  const [providersTimeSlot, setProvidersTimeSlots] = useState({});
  const [UserDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [insuranceisChecked, setinsuranceisChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const organizationId = localStorage.getItem("organizationId");

      if (organizationId) {
        try {
          setLoading(true);
          const payload = {
            modelName: "subscription_temp",
            whereCondition: {
              id: parseInt(organizationId),
            },
          };

          const apiResponse = await postData("masters/getMasterList", payload);

          if (apiResponse.code === 1 && apiResponse.data.length > 0) {
            setLoading(false);
            const parsedPayloadData = JSON.parse(
              apiResponse.data[0].payload_data
            );

            const {
              clinic_services_data,
              clinic_services_mapping,
              practitioners_data,
              clinic_locations,
              providerTimeslots,
              clinics_data,
              assistances,
              clinic_insurances,
              providerisChecked,
              assistanceisChecked,
              insuranceisChecked,
              mappingisChecked,
            } = parsedPayloadData;
            setUserDetails(assistances || []);
            setClinicServicesData(clinic_services_data || []);
            setClinicServicesMapping(clinic_services_mapping || []);
            setAddLocation(clinic_locations || []);
            setProvidersTimeSlots(providerTimeslots || []);
            setOrganizationData(clinics_data || []);
            setProviderData(practitioners_data || []);
            setRecords(clinic_insurances || []);
            setProviderIsCheck(providerisChecked || "");
            setMappingisCheck(mappingisChecked || "");
            setAssistanceisCheck(assistanceisChecked || "");
            setinsuranceisChecked(insuranceisChecked || "");
          } else {
            setLoading(false);
            console.error("No data found for organizationId:", organizationId);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const getHealthCarrierOptions = async () => {
  //     try {
  //       const payload = {
  //         modelName: "health_insurance_carrier_master",
  //         whereCondition: {
  //           is_active: 1,
  //         },
  //       };
  //       const response = await postData("/masters/getMasterList", payload);
  //       setCarrierOptions(response.data);
  //     } catch (error) {
  //       console.error("Error fetching insurance options:", error);
  //     }
  //   };

  //   getHealthCarrierOptions();
  // }, []);
  useEffect(() => {
    const getHealthCarrierOptions = async () => {
      try {
        const payload = {
          modelName: "health_insurance_carrier_master",
          pagination: {
            page: 1,
            pageSize: 100,
          },
          whereCondition: {
            is_active: 1,
          },
        };

        const response = await postData("/masters/getMasterList", payload);
        const carriers = response.data;

        setCarrierOptions(carriers);
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };

    getHealthCarrierOptions();
  }, []);

  useEffect(() => {}, [formData]);

  const validateForm = () => {
    let isValid = true;
    const errors = {};
    if (!formData.insuranceCarrierName) {
      isValid = false;
      errors.insuranceCarrierName =
        "Health/Dental Insurance Carrier Name Is Required";
    }
    if (!formData.selectedPlan) {
      isValid = false;
      errors.selectedPlan = "Health/Dental Insurance Plan Is Required";
    } else if (formData.selectedPlan === "Select Health Insurance Plans") {
      errors.selectedPlan = "Health/Dental Insurance Plan Is Required";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    if (name === "insuranceCarrierName") {
      const selectedCarrier = carrierOptions.find(
        (option) => option.health_insurance_carrier_name === value
      );
      const carrierId = selectedCarrier ? selectedCarrier.id : null;
      setSelectedCarrierId(carrierId);

      if (carrierId) {
        await getInsurancePlans(carrierId);
        setFormData((prevFormData) => ({
          ...prevFormData,
          selectedPlan: "Select Health Insurance Plans",
        }));
      } else {
        setInsuranceOptions([]);
      }
    }
    if (name === "selectedPlan") {
      const selectedProvider = insuranceOptions.find(
        (option) => option.insurance_name === value
      );
      const providerId = selectedProvider ? selectedProvider.id : null;
      setSelectedProviderId(providerId);
    }
  };

  const navigateToBrandIdentityMedia = async () => {
    if (!insuranceisChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }
    console.log("records", records);
    if (records.length > 0) {
      try {
        setLoading(true);
        const organizationId = localStorage.getItem("organizationId");
        let payload;

        if (organizationId) {
          const fetchPayload = {
            modelName: "subscription_temp",
            whereCondition: {
              id: parseInt(organizationId),
            },
          };

          const apiResponse = await postData(
            "masters/getMasterList",
            fetchPayload
          );

          let existingData = {};
          if (apiResponse.code === 1 && apiResponse.data.length > 0) {
            existingData = JSON.parse(apiResponse.data[0].payload_data);
          }

          const isFirstTime =
            !existingData.clinic_insurances && !existingData.insuranceisChecked;

          if (isFirstTime) {
            payload = {
              modelName: "subscription_temp",
              id: organizationId,
              inputData: {
                payload_data: JSON.stringify({
                  clinics_data: OrganizationData,
                  providerisChecked: providerisCheck,
                  assistanceisChecked: assistanceisCheck,
                  mappingisChecked: mappingisCheck,
                  practitioners_data: ProviderData,
                  clinic_services_data: ClinicService,
                  clinic_services_mapping: ClinicServiceMapping,
                  assistances: UserDetails,
                  clinic_locations: addLocation,
                  clinic_insurances: records,
                  insuranceisChecked: insuranceisChecked,
                  providerTimeslots: providerTimeslots,
                }),
              },
            };
            // console.log("records", {
            //   clinics_data: OrganizationData,
            //   practitioners_data: ProviderData,
            //   clinic_services_data: ClinicService,
            //   clinic_services_mapping: ClinicServiceMapping,
            //   assistances: UserDetails,
            //   clinic_locations: addLocation,
            //   clinic_insurances: records,
            //   providersTimeSlots: providersTimeSlot,
            // });
          } else {
            const updatedData = {
              ...existingData,
              clinics_data: existingData.clinics_data || OrganizationData,
              providerisChecked:
                existingData.providerisChecked || providerisCheck,
              assistanceisChecked:
                existingData.assistanceisChecked || assistanceisCheck,
              mappingisChecked: existingData.mappingisChecked || mappingisCheck,
              practitioners_data:
                existingData.practitioners_data || ProviderData,
              clinic_services_data:
                existingData.clinic_services_data || ClinicService,
              clinic_services_mapping:
                existingData.clinic_services_mapping || ClinicServiceMapping,
              providerTimeslots:
                existingData.providerTimeslots || providersTimeSlot,
              assistances: existingData.assistances || UserDetails,
              clinic_locations: existingData.clininc_locations || addLocation,
              insuranceisChecked: insuranceisChecked,
              clinic_insurances: records,
            };

            payload = {
              modelName: "subscription_temp",
              id: organizationId,
              inputData: {
                payload_data: JSON.stringify(updatedData),
              },
            };
          }
        }
        const response = await postData(
          "masters/createAndUpdateMaster",
          payload
        );
        setLoading(false);

        navigate("/pricing", {
          state: {
            userDetails,
            organizationData,
            providerData,
            clinicServicesMapping,
            clinicServicesData,
            providerisChecked,
            providerTimeslots,
            records,
            addLocations,
            assistanceisChecked,
            mappingisChecked,
          },
        });
      } catch (error) {
        setLoading(false);
        console.error("Error saving data:", error);
        setNavError("Failed to save data. Please try again.");
        setTimeout(() => setNavError(""), 3000);
      }
    } else {
      setLoading(false);
      setNavError("Please Add Health Insurance Details.");
      setTimeout(() => setNavError(""), 3000);
    }
  };
  // const getInsurancePlans = async (carrierId) => {
  //   try {
  //     const response = await postData("/masters/getMasterList", {
  //       modelName: "insurance_masters",
  //       whereCondition: {
  //         health_insurance_carrier_master_id: carrierId,
  //         is_active: 1,
  //       },
  //     });
  //     setInsuranceOptions(response.data);
  //   } catch (error) {
  //     console.error("Error fetching insurance options:", error);
  //   }
  // };

  const getInsurancePlans = async (carrierId) => {
    try {
      const response = await postData("/masters/getMasterList", {
        modelName: "insurance_masters",
        pagination: {
          page: 1,
          pageSize: 100,
        },
        whereCondition: {
          health_insurance_carrier_master_id: carrierId,
          is_active: 1,
        },
      });

      const plans = response.data;

      setInsuranceOptions(plans);
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };
  const handleDelete = (index) => {
    if (index === editingIndex) {
      Swal.fire({
        title: "Cannot Delete!",
        text: "You Cannot Delete A Record While It Is Being Edited.",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
      return;
    }

    const updatedRecords = records.filter((_, i) => i !== index);
    setRecords(updatedRecords);
  };
  const handleEdit = async (index) => {
    const record = records[index];

    const selectedCarrier = carrierOptions.find(
      (option) => option.id === record.health_insurance_carrier_id
    );
    const carrierId = selectedCarrier ? selectedCarrier.id : null;

    if (carrierId) {
      await getInsurancePlans(carrierId);
    }

    setFormData({
      insuranceCarrierName: record.insuranceCarrierName,
      selectedPlan: record.selectedPlan,
    });
    setSelectedCarrierId(record.health_insurance_carrier_id);
    setSelectedProviderId(record.insurance_provider_id);
    setEditingIndex(index);
  };
  const handleAddSubmit = async (e) => {
    if (validateForm()) {
      e.preventDefault();

      const selectedOption = insuranceOptions.find(
        (option) => option.insurance_name === formData.selectedPlan
      );
      const carrierOption = carrierOptions.find(
        (option) =>
          option.health_insurance_carrier_name === formData.insuranceCarrierName
      );
      if (!selectedOption) {
        console.error("Selected plan not found in insurance options.");
        return;
      }
      if (!carrierOption) {
        console.error("Selected Carrier name not found in carrier options.");
        return;
      }

      const isDuplicate = records.some(
        (record, index) =>
          index !== editingIndex &&
          record.insuranceCarrierName === formData.insuranceCarrierName &&
          record.selectedPlan === formData.selectedPlan
      );

      if (isDuplicate) {
        Swal.fire({
          title: "Duplicate Entry",
          text: "This Record Is Already Present.",
          icon: "warning",
          timer: 2000,
          button: "OK",
        });
        return;
      }
      const formDataWithId = {
        ...formData,
        insurance_provider_id: selectedProviderId,
        health_insurance_carrier_id: selectedCarrierId,
      };

      let updatedRecords;
      if (editingIndex !== null) {
        updatedRecords = records.map((record, index) =>
          index === editingIndex ? { ...formDataWithId, is_active: 1 } : record
        );
        setEditingIndex(null);
      } else {
        updatedRecords = [...records, { ...formDataWithId, is_active: 1 }];
      }
      setRecords(updatedRecords);

      setFormData({
        insuranceCarrierName: "",
        selectedPlan: "",
      });
    }
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <div className="container mx-auto px-4 flex items-center justify-between gap-16 xss:gap-2 sm:gap-5 relative">
            <StepsForRegistration />
            <h1 className="font-fontPoppins md:text-xl font-semibold text-xs xss:text-xs sm:text-sm leading-normal absolute left-1/2  sm:left-1/2 sm:transform sm:-translate-x-1/2 xss:right-0">
              Insurance Info
            </h1>
          </div>

          <section className="w-full">
            <div className="container mx-auto px-4">
              <div className="bg-grayColorLight rounded-lg p-3">
                {/* <h2 className="font-fontPoppins text-xs font-bold text-center text-gray-700 bg-gray-100  rounded shadow-md">
              Please Add Your Accepted Health/Dental Insurance Details
            </h2> */}
                <h2 className="font-fontPoppins text-lg font-bold text-center text-gray-700 bg-gray-100  rounded shadow-md">
                  Please Add All Insurance Carriers and Plans Accepted
                </h2>

                <div className="w-full mt-3">
                  <div className="w-full md:flex items-center gap-3">
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-bold">
                        Insurance Carriers Accepted
                        <span className="text-red-500">*</span>
                      </label>

                      <CustomSelectHealth
                        name="insuranceCarrierName"
                        options={carrierOptions.map((option) => ({
                          value: option.health_insurance_carrier_name,
                          label: option.health_insurance_carrier_name,
                        }))}
                        value={formData.insuranceCarrierName}
                        onChange={handleChange}
                        placeholder={"Select All Insurance Carriers Accepted"}
                      />
                      {errors.insuranceCarrierName && (
                        <p className="text-red-500 text-xs">
                          {errors.insuranceCarrierName}
                        </p>
                      )}
                    </div>

                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Insurance Plans Accepted
                        <span className="text-red-500">*</span>
                      </label>

                      <CustomSelectHealth
                        name="selectedPlan"
                        options={insuranceOptions.map((option) => ({
                          value: option.insurance_name,
                          label: option.insurance_name,
                        }))}
                        value={formData.selectedPlan}
                        onChange={handleChange}
                        placeholder={"Select All Insurance Plans Accepted"}
                      />
                      {errors.selectedPlan && (
                        <p className="text-red-500 text-xs">
                          {errors.selectedPlan}
                        </p>
                      )}
                    </div>

                    <div className="w-32 xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold text-transparent xss:hidden">
                        .
                      </label>
                      <div className="w-full">
                        <button
                          onClick={handleAddSubmit}
                          className="w-full h-9 bg-blueColor rounded-full font-fontPoppins text-xs font-normal text-white hover:opacity-85"
                        >
                          {editingIndex !== null ? "Update" : "Add"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full mt-3 overflow-auto">
                <table className="border-collapse table-auto w-full text-sm">
                  <thead>
                    <tr>
                      <th className="rounded-tl-lg font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                        Insurance Carriers Accepted
                      </th>
                      <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                        Insurance Plans Accepted
                      </th>
                      <th className="rounded-tr-lg font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {records.map((record, index) => (
                      <tr key={index}>
                        <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                          {record.insuranceCarrierName}
                        </td>
                        <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                          {record.selectedPlan}
                        </td>
                        <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                          <span
                            onClick={() => handleEdit(index)}
                            className="text-gray-700 mr-2 cursor-pointer hover:text-blueColor"
                          >
                            <i className="fa-solid fa-pencil"></i> Edit
                          </span>
                          {/* <span className="text-gray-400 mr-2 cursor-pointer hover:text-blueColor">
                          <i className="fa-solid fa-circle-minus"></i> Disable
                        </span> */}
                          <span
                            onClick={() => handleDelete(index)}
                            className="text-gray-700 mr-2 cursor-pointer hover:text-red-500"
                          >
                            <i className="fa-solid fa-trash"></i> Delete
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex   mt-4 items-center gap-2">
                <input
                  type="checkbox"
                  checked={insuranceisChecked}
                  onChange={(e) => setinsuranceisChecked(e.target.checked)}
                />

                <span className="font-fontPoppins text-xs font-semibold relative">
                  I have verified all the information and provide consent to
                  Samara Well to use and display this information accordingly.
                </span>
              </div>
              {errorMessage && (
                <p className="text-red-500  text-xs absolute">{errorMessage}</p>
              )}
            </div>
          </section>

          <section className="w-full mt-10 xss:mt-5 sm:mt-5 lg:min-h-210">
            <div className="container mx-auto px-4 text-center">
              <button
                onClick={navigateToBrandIdentityMedia}
                className="w-64 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
              >
                <span className="me-2">Add Subscription</span>
                <i className="fa-solid fa-arrow-right-long"></i>
              </button>
              {<p className="text-red-500 ml-2">{navError}</p>}
            </div>
          </section>

          <Footer />
        </div>
      </>
    </>
  );
}

export default HealthInsuranceCarrier;
