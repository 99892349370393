import Header from "./Header";
import Footer from "./Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { apiUrl, postData } from "../Networking/Api";
import StepsForRegistration from "./StepsForRegistration";
import Loader2 from "./Loader2";
import Swal from "sweetalert2";

function FrontDeskAssistantDetails() {
  const location = useLocation();
  const {
    organizationData,
    providerData,
    clinicServicesMapping,
    clinicServicesData,
    providerTimeslots,
    addLocations,
    providerisChecked,
    mappingisChecked,
  } = location.state || {};
  console.log("providerTimeslots", providerTimeslots);
  const [formData, setFormData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    assistant_profile_photo: "",
    is_active: 1,
  });
  const [errors, setErrors] = useState({});
  const [navError, setNavError] = useState("");
  const fileInputRef = useRef(null);
  const [userDetails, setUserDetails] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [OrganizationData, setOrganizationData] = useState({});
  const [ProviderData, setProviderData] = useState({});
  const [ClinicService, setClinicServicesData] = useState({});
  const [ClinicServiceMapping, setClinicServicesMapping] = useState({});
  const [addLocation, setAddLocation] = useState([]);
  const [providersTimeSlots, setProvidersTimeSlots] = useState({});
  const [providerisCheck, setProviderisCheck] = useState();
  const [mappingisCheck, setMappingisCheck] = useState();
  const [loading, setLoading] = useState(false);
  const [assistanceisChecked, setAssistanceisChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {}, [formData]);
  useEffect(() => {
    const fetchData = async () => {
      const organizationId = localStorage.getItem("organizationId");

      if (organizationId) {
        try {
          setLoading(true);
          const payload = {
            modelName: "subscription_temp",
            whereCondition: {
              id: parseInt(organizationId),
            },
          };

          const apiResponse = await postData("masters/getMasterList", payload);

          if (apiResponse.code === 1 && apiResponse.data.length > 0) {
            setLoading(false);
            const parsedPayloadData = JSON.parse(
              apiResponse.data[0].payload_data
            );

            const {
              clinic_services_data,
              clinic_services_mapping,
              practitioners_data,
              clinic_locations,
              providerTimeslots,
              clinics_data,
              providerisChecked,
              mappingisChecked,
              assistanceisChecked,
              assistances,
            } = parsedPayloadData;
            setUserDetails(assistances || []);
            setClinicServicesData(clinic_services_data || []);
            setClinicServicesMapping(clinic_services_mapping || []);
            setProvidersTimeSlots(providerTimeslots || []);
            setOrganizationData(clinics_data || []);
            setProviderData(practitioners_data || []);
            setAddLocation(clinic_locations || []);
            setProviderisCheck(providerisChecked || "");
            setMappingisCheck(mappingisChecked || "");
            setAssistanceisChecked(assistanceisChecked);
          } else {
            console.error("No data found for organizationId:", organizationId);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {}, [formData]);

  const validateForm = async () => {
    let isValid = true;
    const errors = {};
    if (!formData.f_name.trim()) {
      isValid = false;
      errors.f_name = "First Name Is Required.";
    } else if (/^\s/.test(formData.f_name)) {
      isValid = false;
      errors.f_name = "First Name Should Not Start With A Space.";
    } else if (!/^[A-Za-z]+$/.test(formData.f_name)) {
      isValid = false;
      errors.f_name = "First Name Should Contain Only Alphabets.";
    } else if (formData.f_name.length < 3) {
      isValid = false;
      errors.f_name = "First Name Must Be At Least 3 Alphabets Long.";
    }

    if (!formData.l_name.trim()) {
      isValid = false;
      errors.l_name = "Last Name Is Required.";
    } else if (/^\s/.test(formData.l_name)) {
      isValid = false;
      errors.l_name = "Last Name Should Not Start With A Space.";
    } else if (!/^[A-Za-z]+$/.test(formData.l_name)) {
      isValid = false;
      errors.l_name = "Last Name Should Contain Only Alphabets.";
    } else if (formData.l_name.length < 3) {
      isValid = false;
      errors.l_name = "Last Name Must Be At Least 3 Alphabets Long.";
    }

    if (!formData.email.trim()) {
      isValid = false;
      errors.email = "Email Is Required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)
    ) {
      isValid = false;
      errors.email = "Invalid Email Format.";
    } else {
      const emailExists = await checkEmailExists(formData.email);
      if (emailExists) {
        isValid = false;
        errors.email = "This Email Already Exists.";
      }
    }
    // if (!formData.assistant_profile_photo) {
    //   isValid = false;
    //   errors.assistant_profile_photo = "Profile Photo Is Required.";
    // }
    setErrors(errors);
    return isValid;
  };

  const checkEmailExists = async (email) => {
    try {
      const payload = {
        modelName: "assistances",
        whereCondition: {
          email: email,
          is_active: 1,
        },
      };
      const apiResponse = await postData("masters/getMasterList", payload);
      return apiResponse.code === 1;
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };

  const handleEdit = (index) => {
    const user = userDetails[index];
    setFormData(user);
    setEditingIndex(index);
  };

  const handleDelete = (index) => {
    if (index === editingIndex) {
      Swal.fire({
        title: "Cannot Delete!",
        text: "You Cannot Delete A Record While It Is Being Edited.",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
      return;
    }

    const updatedDetails = userDetails.filter((_, i) => i !== index);

    setUserDetails(updatedDetails);
  };
  const handleChange = async (e) => {
    var { name, value, files } = e.target;
    if (name === "f_name" || name === "l_name") {
      value = value.replace(/[^a-zA-Z]/g, "");
    }
    if (name === "email") {
      value = value.replace(/^\s+/, "");
    }
    if (name === "assistant_profile_photo" && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, assistant_profile_photo: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailExists = userDetails.some(
      (user, index) => user.email === formData.email && index !== editingIndex
    );

    if (emailExists) {
      setErrors({
        ...errors,
        email: "Record Is Already Present. Please Use A Different Email.",
      });
      return;
    }
    const isValid = await validateForm();
    if (isValid) {
      let updatedDetails;
      if (editingIndex !== null) {
        updatedDetails = userDetails.map((record, index) =>
          index === editingIndex ? { ...formData, is_active: 1 } : record
        );
        setEditingIndex(null);
      } else {
        updatedDetails = [...userDetails, { ...formData, is_active: 1 }];
      }
      setUserDetails(updatedDetails);

      setFormData({
        f_name: "",
        l_name: "",
        email: "",
        assistant_profile_photo: "",
        is_active: 1,
      });
      fileInputRef.current.value = null;
    }
  };

  const handleNavigate = async () => {
    if (!assistanceisChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }
    if (userDetails.length > 0) {
      setNavError("");
      try {
        setLoading(true);
        const organizationId = localStorage.getItem("organizationId");
        let payload;

        if (organizationId) {
          const fetchPayload = {
            modelName: "subscription_temp",
            whereCondition: {
              id: parseInt(organizationId),
            },
          };

          const apiResponse = await postData(
            "masters/getMasterList",
            fetchPayload
          );

          let existingData = {};
          if (apiResponse.code === 1 && apiResponse.data.length > 0) {
            existingData = JSON.parse(apiResponse.data[0].payload_data);
          }

          const isFirstTime =
            !existingData.assistances && !existingData.setAssistanceisChecked;

          if (isFirstTime) {
            payload = {
              modelName: "subscription_temp",
              id: organizationId,
              inputData: {
                payload_data: JSON.stringify({
                  clinics_data: organizationData,
                  practitioners_data: providerData,
                  clinic_locations: addLocations,
                  providerisChecked: providerisChecked,
                  mappingisChecked: mappingisChecked,
                  clinic_services_data: clinicServicesData,
                  providerTimeslots: providerTimeslots,
                  clinic_services_mapping: clinicServicesMapping,
                  assistanceisChecked: assistanceisChecked,

                  assistances: userDetails,
                }),
              },
            };
          } else {
            const updatedData = {
              ...existingData,
              clinics_data: existingData.clinics_data || organizationData,
              practitioners_data:
                existingData.practitioners_data || providerData,
              clinic_services_data:
                existingData.clinic_services_data || clinicServicesData,
              clinic_services_mapping:
                existingData.clinic_services_mapping || clinicServicesMapping,
              providerTimeslots:
                existingData.providerTimeslots || providerTimeslots,
              clinic_locations: existingData.clinic_locations || addLocations,
              providerisChecked:
                existingData.providerisChecked || providerisChecked,
              mappingisChecked: mappingisChecked || mappingisChecked,
              assistanceisChecked: assistanceisChecked,
              assistances: userDetails,
            };

            payload = {
              modelName: "subscription_temp",
              id: organizationId,
              inputData: {
                payload_data: JSON.stringify(updatedData),
              },
            };
          }
        }
        const response = await postData(
          "masters/createAndUpdateMaster",
          payload
        );
        setLoading(false);
        navigate("/healthInsuranceCarrier", {
          state: {
            userDetails,
            organizationData,
            providerData,
            clinicServicesMapping,
            clinicServicesData,
            providerTimeslots,
            addLocations,
            providerisChecked,
            mappingisChecked,
            assistanceisChecked,
          },
        });
      } catch (error) {
        setLoading(false);
        console.error("Error saving data:", error);
        setNavError("Failed to save data. Please try again.");
        setTimeout(() => setNavError(""), 3000);
      }
    } else {
      setLoading(false);
      setNavError("Please Add At Least One Clinic Assistant Details.");
      setTimeout(() => setNavError(""), 3000);
    }
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <div className="container mx-auto px-4 flex items-center justify-between gap-16 xss:gap-2 sm:gap-5 relative">
            <StepsForRegistration />
            <h1 className="font-fontPoppins md:text-xl font-semibold text-xs xss:text-xs sm:text-sm leading-normal absolute left-1/2  sm:left-1/2 sm:transform sm:-translate-x-1/2 xss:right-0">
              Front Office Team
            </h1>
          </div>
          <section className="w-full">
            <div className="container mx-auto px-4">
              <div className="bg-grayColorLight rounded-lg p-3">
                <h2 className="font-fontPoppins text-lg font-bold text-center text-gray-700 bg-gray-100  rounded shadow-md">
                  Please Enter The Details For Your Organization
                </h2>
                <div className="w-full mt-3">
                  <div className="w-full md:flex items-center gap-3">
                    <div className="w-full relative">
                      <label className="font-fontPoppins text-xs font-semibold">
                        First Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="First Name"
                        name="f_name"
                        value={formData.f_name}
                        onChange={handleChange}
                      />
                      {errors.f_name && (
                        <p className="text-red-500 text-xs absolute">
                          {errors.f_name}
                        </p>
                      )}
                    </div>
                    <div className="w-full xss:mt-3 sm:mt-3 relative">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Last Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Last Name"
                        name="l_name"
                        value={formData.l_name}
                        onChange={handleChange}
                      />
                      {errors.l_name && (
                        <p className="text-red-500 text-xs absolute">
                          {errors.l_name}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="w-full md:flex items-center gap-3 mt-3 relative">
                    <div className="w-full">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Email<span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Enter Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <p className="text-red-500 text-xs absolute">
                          {errors.email}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="w-full md:flex flex-start gap-3 mt-3 relative">
                    <div className="sm:w-80">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Profile Photo
                      </label>
                      <div className="flex items-center gap-3">
                        <div className="w-full relative">
                          <span className="z-10 absolute left-3 top-3">
                            <i className="fa-solid fa-camera"></i>
                          </span>
                          <input
                            className="w-full h-9 text-center indent-5 text-xs rounded-full px-4 py-1 font-fontPoppins text-gray-700 mt-2 focus:outline-none focus:ring ring-gray-200 shadow"
                            type="file"
                            placeholder="Upload"
                            name="assistant_profile_photo"
                            accept="image/jpeg, image/png"
                            ref={fileInputRef}
                            onChange={handleChange}
                          />
                          {errors.assistant_profile_photo && (
                            <p className="text-red-500 text-xs absolute">
                              {errors.assistant_profile_photo}
                            </p>
                          )}
                          {formData.assistant_profile_photo && (
                            <img
                              src={
                                formData.assistant_profile_photo.startsWith(
                                  "data:"
                                )
                                  ? formData.assistant_profile_photo
                                  : `${apiUrl}/${formData.assistant_profile_photo}` ||
                                    "/placeholder.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/placeholder.png";
                              }}
                              alt="photo"
                              className="absolute top-1/2 transform -translate-y-1/2 right-2 w-9 h-9 rounded-full"
                            />
                          )}
                        </div>
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="w-24 h-9 mt-2 bg-blueColor text-white font-fontPoppins text-xs rounded-full"
                        >
                          {editingIndex !== null ? "Update" : "Add"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overflow-auto container mx-auto px-4 mt-8">
              <table className="overflow-auto border-collapse table-auto w-full text-sm">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="rounded-tl-lg font-fontPoppins bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                      First Name
                    </th>
                    <th className="font-fontPoppins bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                      Last Name
                    </th>
                    <th className="font-fontPoppins bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                      Email
                    </th>
                    <th className="font-fontPoppins bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                      Profile Photo
                    </th>
                    <th className="rounded-tr-lg font-fontPoppins bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {userDetails.map((details, index) => (
                    <tr key={index}>
                      <td className="font-fontPoppins border-b border-slate-100 p-3 pl-8">
                        {details.f_name}
                      </td>
                      <td className="font-fontPoppins border-b border-slate-100 p-3 pl-8">
                        {details.l_name}
                      </td>
                      <td className="font-fontPoppins border-b border-slate-100 p-3 pl-8">
                        {details.email}
                      </td>
                      <td className="font-fontPoppins border-b border-slate-100 p-3 pl-8">
                        <img
                          src={
                            details.assistant_profile_photo ||
                            "/placeholder.png"
                          }
                          alt=""
                          className="w-10 h-10 rounded-full"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/placeholder.png";
                          }}
                        />
                      </td>
                      <td className="font-fontPoppins border-b border-slate-100 p-3 pl-8">
                        <span
                          className="text-gray-700 mr-2 cursor-pointer hover:text-blueColor"
                          onClick={() => handleEdit(index)}
                        >
                          <i className="fa-solid fa-pencil"></i> Edit
                        </span>
                        <span
                          onClick={() => handleDelete(index)}
                          className="text-gray-700 mr-2 cursor-pointer hover:text-red-500"
                        >
                          <i className="fa-solid fa-trash"></i> Delete
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex mt-4 items-center gap-2">
                <input
                  type="checkbox"
                  checked={assistanceisChecked}
                  onChange={(e) => setAssistanceisChecked(e.target.checked)}
                />

                <span className="font-fontPoppins text-xs font-semibold relative">
                  I have verified all the information and provide consent to
                  Samara Well to use and display this information accordingly.
                </span>
              </div>
              {errorMessage && (
                <p className="text-red-500  text-xs absolute">{errorMessage}</p>
              )}
            </div>
          </section>
          <section className="w-full mt-10 xss:mt-5 sm:mt-5 lg:min-h-50">
            <div className="container mx-auto px-4 text-center">
              <button
                onClick={handleNavigate}
                className="w-72 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
              >
                <span className="me-2">Add Insurance Info</span>
                <i className="fa-solid fa-arrow-right-long"></i>
              </button>
              {<p className="text-red-500 ml-2">{navError}</p>}
            </div>
          </section>

          <Footer />
        </div>
      </>
    </>
  );
}

export default FrontDeskAssistantDetails;
