// import CryptoJS from "crypto-js";

// const secretKey =
//   "5f39ce6c2ab55e30a2dd4e9f17145fba1774e6c16e72d060cc744e40c5739e67";

// if (!secretKey) {
//   console.error(
//     "Secret key is undefined. Check your .env file and restart the server."
//   );
// }

// export const encrypt = (text) => {
//   if (!secretKey) {
//     throw new Error("Secret key is undefined. Encryption cannot proceed.");
//   }
//   return CryptoJS.AES.encrypt(text, secretKey).toString();
// };

// export const decrypt = (cipherText) => {
//   if (!secretKey) {
//     throw new Error("Secret key is undefined. Decryption cannot proceed.");
//   }
//   const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
//   return bytes.toString(CryptoJS.enc.Utf8);
// };
import CryptoJS from "crypto-js";

const secretKey =
  "5f39ce6c2ab55e30a2dd4e9f17145fba1774e6c16e72d060cc744e40c5739e67";

if (!secretKey) {
  console.error(
    "Secret key is undefined. Check your .env file and restart the server."
  );
}

export const encrypt = (text) => {
  if (!secretKey) {
    throw new Error("Secret key is undefined. Encryption cannot proceed.");
  }

  // Encrypt the text
  const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();

  // Encode to Base64 URL-safe format using window.btoa
  const base64Encoded = window.btoa(encrypted);
  const urlSafeBase64 = base64Encoded
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  return urlSafeBase64;
};

export const decrypt = (urlSafeBase64) => {
  if (!secretKey) {
    throw new Error("Secret key is undefined. Decryption cannot proceed.");
  }

  // Decode from Base64 URL-safe format using window.atob
  const base64Encoded = urlSafeBase64.replace(/-/g, "+").replace(/_/g, "/");
  const encrypted = window.atob(base64Encoded);

  // Decrypt the value
  const bytes = CryptoJS.AES.decrypt(encrypted, secretKey);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);

  return decrypted;
};
