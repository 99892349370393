import Footer from "./Footer";
import Header from "./Header";

function OutpatientPracticeDetails() {
  return (
    <>
      <Header />

      <section className="w-full py-2">
        <div className="container mx-auto px-4 flex items-center gap-16 xss:gap-2 sm:gap-5">
          <div>
            <p className="font-fontPoppins text-xs text-gray-400">
              Steps for registration
            </p>
            <nav
              className="bg-repeat-x bg-center flex items-center text-center gap-3 xss:gap-2 mt-1"
              style={{ backgroundImage: "url(./d-line.png)" }}
            >
              <span
                className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins border-2 border-solid border-greenColorDark bg-greenColorLight rounded-lg cursor-pointer"
                title="CLINIC DETAILS"
              >
                1
              </span>
              <span className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins bg-grayColor rounded-lg cursor-pointer hover:border-2 hover:border-solid hover:border-greenColorDark hover:bg-greenColorLight">
                2
              </span>
              <span className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins bg-grayColor rounded-lg cursor-pointer hover:border-2 hover:border-solid hover:border-greenColorDark hover:bg-greenColorLight">
                3
              </span>
              <span className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins bg-grayColor rounded-lg cursor-pointer hover:border-2 hover:border-solid hover:border-greenColorDark hover:bg-greenColorLight">
                4
              </span>
              <span className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins bg-grayColor rounded-lg cursor-pointer hover:border-2 hover:border-solid hover:border-greenColorDark hover:bg-greenColorLight">
                5
              </span>
              <span className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins bg-grayColor rounded-lg cursor-pointer hover:border-2 hover:border-solid hover:border-greenColorDark hover:bg-greenColorLight">
                6
              </span>
              <span className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins bg-grayColor rounded-lg cursor-pointer hover:border-2 hover:border-solid hover:border-greenColorDark hover:bg-greenColorLight">
                7
              </span>
            </nav>
          </div>

          <h1 className="font-fontPoppins text-2xl font-semibold xss:text-xs sm:text-lg leading-normal">
            Outpatient Practice Details
          </h1>
        </div>
      </section>

      <section className="w-full">
        <div className="container mx-auto px-4">
          <div className="bg-grayColorLight rounded-lg p-3">
            <h2 className="font-fontPoppins text-xs font-semibold text-center text-gray-400">
              Please enter the details for your clinic
            </h2>

            <div className="w-full">
              <label className="font-fontPoppins text-xs font-semibold">
                Outpatient Practice Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                placeholder="Enter clinic name"
              />
            </div>

            <div className="w-full mt-3">
              <label className="font-fontPoppins text-xs font-semibold">
                Add Providers<span className="text-red-500">*</span>
              </label>

              <div className="w-full bg-grayColor rounded-lg p-3 mt-1">
                <div className="w-full md:flex justify-between gap-3">
                  <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      NPI Number<span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="Enter NPI"
                    />
                  </div>
                  <div className="w-full xss:mt-3 sm:mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Healthcare Provider Name
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="Enter full name"
                    />
                  </div>
                  <div className="w-full xss:mt-3 sm:mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Provider Type<span className="text-red-500">*</span>
                    </label>
                    <select className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow">
                      <option selected>Select</option>
                    </select>
                  </div>
                  <div className="w-full xss:mt-3 sm:mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Email<span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="Enter email"
                    />
                  </div>
                </div>

                <div className="w-full md:flex justify-between gap-3 mt-3">
                  <div className="md:w-80">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Profile Photo<span className="text-red-500">*</span>
                    </label>
                    <div className="w-full relative">
                      <span className="z-10 absolute left-3 top-2">
                        <i className="fa-solid fa-camera"></i>
                      </span>
                      <input
                        className="w-full h-9 indent-5 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Upload"
                      />
                    </div>
                  </div>
                  <div className="w-full xss:mt-3 sm:mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Calendar<span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="Calendar link"
                    />
                  </div>
                  <div className="w-52">
                    <label className="font-fontPoppins text-xs font-semibold text-transparent">
                      .
                    </label>
                    <button className="w-full h-10 bg-blueColor text-white font-fontPoppins text-xs rounded-full">
                      Add
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-full mt-3">
                <div className="w-full md:flex items-center gap-3">
                  <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Email<span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="Enter your email"
                    />
                  </div>

                  <div className="w-full xss:mt-3 sm:mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Website<span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="Website URL"
                    />
                  </div>
                </div>

                <div className="w-full md:flex items-center gap-3 mt-3">
                  <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Phone<span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="(xxx)-xxx-xxxx"
                    />
                  </div>

                  <div className="w-full xss:mt-3 sm:mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Fax Number<span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="Enter your fax number"
                    />
                  </div>
                </div>

                <div className="w-full md:flex items-center gap-3 mt-3">
                  <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Street Address<span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="Enter your address"
                    />
                  </div>
                </div>

                <div className="w-full md:flex items-center gap-3 mt-3">
                  <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      City<span className="text-red-500">*</span>
                    </label>
                    <select className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow">
                      <option selected>Select</option>
                    </select>
                  </div>

                  <div className="w-full xss:mt-3 sm:mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      State<span className="text-red-500">*</span>
                    </label>
                    <select className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow">
                      <option selected>Select</option>
                    </select>
                  </div>
                </div>

                <div className="w-full md:flex items-center gap-3 mt-3">
                  <div className="md:w-1/2">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Zip Code<span className="text-red-500">*</span>
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="xxxxx"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-4">
            <div className="flex items-center gap-2">
              <input type="checkbox" />
              <span className="font-fontPoppins text-xs font-medium">
                I have verified and/or corrected the auto-filled information,
                and provide my consent to Samara Well for using and displaying
                this information.
              </span>
            </div>

            <div className="w-full mt-4 text-center">
              <button className="w-56 h-9 font-fontPoppins text-xs bg-grayColorBtn rounded-full text-gray-400 font-semibold">
                <span className="me-2">Add Front Desk Team</span>
                <i className="fa-solid fa-arrow-right-long"></i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default OutpatientPracticeDetails;
