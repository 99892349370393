// const Loader = () => {
//   return (
//     <div className="flex h-screen items-center justify-center bg-gradient-to-r from-blue-500 to-teal-500">
//       <div className="relative">
//         <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
//         <div className="absolute top-0 left-0 h-16 w-16 animate-ping rounded-full border-4 border-solid border-t-transparent border-primary opacity-75"></div>
//       </div>
//     </div>
//   );
// };

// export default Loader;


// const Loader = () => {
//   return (
//     <div className="flex h-screen items-center justify-center bg-white">
//       <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
//     </div>
//   );
// };

// export default Loader;



const Loader = () => {
  return (
    <div className="loader-container">
    <div className="loader">
      <div className="loader--dot"></div>
      <div className="loader--dot"></div>
      <div className="loader--dot"></div>
      <div className="loader--dot"></div>
      <div className="loader--dot"></div>
      <div className="loader--dot"></div>
      <div className="loader--text"></div>
    </div>
  </div>
  );
};

export default Loader;