// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Footer from "../Footer";
// import Header from "../Header";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { useEffect, useState } from "react";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import StepsForRegistration from "../StepsForRegistration";
//  import { apiUrl, postData } from "../../Networking/Api";
// import Swal from "sweetalert2";

// const ItemTypes = {
//   PROVIDER: "provider",
// };
// console.log('apiUrl',apiUrl);

// const Provider = ({ name, src,practitioner_id }) => {
//   const [{ isDragging }, drag] = useDrag(() => ({
//     type: ItemTypes.PROVIDER,
//     item: { name, src,practitioner_id },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging(),
//     }),
//   }));

//   return (
//     <div
//       ref={drag}
//       style={{ opacity: isDragging ? 0.5 : 1 }}
//       className="w-auto mb-2 py-2 cursor-pointer"
//     >
//       <img src={src} className="rounded-full mx-auto w-10 h-10"  alt={name} />
//       <div className="wi-full font-fontPoppins text-xs font-semibold text-center">
//         {name}
//       </div>
//     </div>
//   );
// };

// const ServiceRow = ({ service, onDropProvider, onRemoveProvider }) => {
//   const [{ canDrop, isOver }, drop] = useDrop(
//     () => ({
//       accept: ItemTypes.PROVIDER,
//       drop: (item) => onDropProvider(service, item),
//       canDrop: (item) =>
//         !service.providers.some((provider) => provider.name === item.name),
//       collect: (monitor) => ({
//         isOver: monitor.isOver(),
//         canDrop: monitor.canDrop(),
//       }),
//     }),
//     [service.providers]
//   );

//   const removeItem = (provider) => {
//     onRemoveProvider(service, provider);
//   };

//   return (
//     <div
//       ref={drop}
//       className={`w-full border border-l-0 border-r-0 border-t-0 border-solid border-gray-100 py-3 flex items-center gap-3 xss:overflow-auto`}
//     >
//       <button className="font-fontPoppins text-xs font-medium bg-white shadow-sm rounded-full w-52 py-2">
//         {service?.f_name+ " " + service?.l_name}
//       </button>
//       <span className="text-gray-400">
//         <i className="fa-solid fa-play fa-lg"></i>
//       </span>
//       {service && service.providers && Array.isArray(service.providers) && service.providers.length > 0 ? (
//         service.providers.map((provider, index) => (
//           <div key={index} className="w-auto p-0 m-0 flex flex-col items-center justify-center">
//             <img src={provider?.src} className="rounded-full mx-auto w-10 h-10"  alt={provider?.name} />
//             <div className="font-fontPoppins text-xs font-semibold text-center">
//               {provider?.name}
//             </div>
//             <span onClick={() => removeItem(provider)} className="cursor-pointer">
//               <i className="  fa-regular fa-circle-xmark"></i>
//             </span>
//           </div>
//         ))
//       ) : (
//         <div className="text-gray-400 text-sm">No providers added</div>
//       )}
//     </div>
//   );
// };

// function AssistProviderMapping() {
//   const [services, setServices] = useState([]);
// //   const [clinicServicesData, setClinicServicesData] = useState([]);
//   const [clinicServicesMapping, setClinicServicesMapping] = useState([]);
//   const [providerList,setProviderList] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [addNewService, setAddnewService] = useState("");
//   const [isAddNewService, setIsAddnewService] = useState(false);
//   const [error, setError] = useState("");
//   const location = useLocation();
//   const {organizationData,providerData} = location.state || {};
//   const [clinicId,setClinicId] = useState("")
// console.log(services);
// const navigate = useNavigate()

//   useEffect(() => {
//     const storedClinicId = localStorage.getItem("clinicId");
//     setClinicId(storedClinicId);
//     // console.log("clinicId", storedClinicId);
//     getProviderList(storedClinicId);
//     fetchServices(storedClinicId);
//     // fetchClinicServicesMapping(storedClinicId)
// }, []);

// useEffect(() => {
//   if (services.length > 0) {
//     const storedClinicId = localStorage.getItem("clinicId");
//     fetchClinicServicesMapping(storedClinicId);
//   }
// }, [providerList]);

// // console.log('abcd',services);
// console.log('clinicServicesMapping',clinicServicesMapping);
// // console.log('clinicServicesData',clinicServicesData);

//   const fetchServices = async (clinicId) => {
//     try {
//       const payload = {
//         modelName: "assistances",
//         whereCondition: {
//           clinic_id: clinicId,
//           is_active: 1,
//         },
//       };
//       const response = await postData("masters/getMasterList", payload);
//       console.log('eifjfjeowfopee',response.data);
//       const servicesWithProviders = response.data.map(assistant => ({
//         ...assistant,

//         providers: []
//       }));

//       console.log("Fetched services:", servicesWithProviders);
//       // fetchClinicServicesMapping(clinicId)
//         setServices(servicesWithProviders);

//       //

//       console.log('servicesWithProviders',services);

//     } catch (error) {
//       console.error("Error fetching services:", error);
//     }
//   };

//  const getProviderList = async (clinicId) => {
//   try {
//     const payload = {
//       modelName: "practitioners",
//       whereCondition: {
//         clinic_id: clinicId,
//       },
//     };
//     // console.log("Clinic data payload:", payload);
//     const response = await postData("masters/getMasterList", payload);
//     console.log("responseeeeeeeeee", response.data);
//     setProviderList(response.data);

//   } catch (error) {
//     console.error("Error fetching clinic details:", error);
//   }
// };

// const fetchClinicServicesMapping = async (clinicId) => {
//   try {
//     // const payload = {
//     //   modelName: "clinic_services_mapping",
//     //   whereCondition: {
//     //     clinic_id: clinicId,
//     //     is_active: 1,
//     //   },
//     // };
//     const payload = {
//         modelName: "assistance_practitioners_mapping",
//         whereCondition: {
//             clinic_id: clinicId,
//             is_active:1
//         },
//         relations: [
//             {
//                 module: "assistances"
//             },
//             {
//                 module: "practitioners"
//             }
//         ]
//     };

//     // console.log('',payload);
//     const response = await postData("masters/getMasterList", payload);
//     console.log("Fetched mapping:", response.data);

//     const mappingByServiceId = response.data.reduce((acc, curr) => {
//       if (!acc[curr. assistance_id]) {
//         acc[curr. assistance_id] = [];
//       }
//       acc[curr. assistance_id].push(curr);
//       return acc;
//     }, {});

//     const updatedServices = services.map((service) => {
//       const mappings = mappingByServiceId[service.id] || [];
//       console.log('worjqqewd',mappings);
//       const providers = mappings.map((mapping) => {

//         const provider = providerList.find((provider) => provider.id === mapping.practitioner_id);
//         return provider ? { name: `${provider.f_name} ${provider.l_name}`, src: `${apiUrl}/${provider.photo}`, practitioner_id: provider.id } : null;
//       });
//       return { ...service, providers: providers.filter((provider) => provider !== null) };
//     });

//     setServices(updatedServices);

//     const updatedClinicServicesMapping = response.data.map((mapping) => {
//       const provider = providerList.find((provider) => provider.id === mapping.practitioner_id);
//       const providerName = provider ? `${provider.f_name} ${provider.l_name}` : '';

//       return {
//         services_id: mapping.assistance_id,
//         // services: services.find((service) => service.id === mapping. assistance_id)?.services || '',
//         services: `${services.find((service) => service.id === mapping.assistance_id)?.f_name} ${services.find((service) => service.id === mapping. assistance_id)?.l_name}` || '',
//         name: providerName,
//         provider_practitioner_id: mapping.practitioner_id,
//         is_active: mapping.is_active,
//       };
//     }).filter(mapping => mapping.name !== ''); // Filter out mappings with empty names

//     setClinicServicesMapping(updatedClinicServicesMapping.length > 0 ? updatedClinicServicesMapping : []);

//     // // setClinicServicesMapping(updatedClinicServicesMapping);

//   } catch (error) {
//     console.error("Error fetching services:", error);
//   }
// };

//   const handleDropProvider = (service, item) => {
//     setServices((prevServices) => {
//       const updatedServices = prevServices.map((s) =>
//         `${s.f_name} ${s.l_name}` ===  `${service.f_name} ${service.l_name}`
//           ? {
//               ...s,
//               providers: [...s.providers, { name: item.name,services: `${service.f_name} ${service.l_name}`,src: item.src,practitioner_id: item.practitioner_id, is_active: 1}],
//             }
//           : s
//       );

//       console.log('updatedServices',updatedServices)
//       return updatedServices;
//     });
//     setClinicServicesMapping((prevMappings) => {
//       const updatedMappings = [
//         ...prevMappings,
//         {
//             services_id:service.id,
//           services: `${service.f_name} ${service.l_name}`,
//           name:item.name,
//           provider_practitioner_id: item.practitioner_id,
//           is_active: 1,
//         },
//       ];

//       return updatedMappings;
//     });
//   };

//   const handleRemoveProvider = (service, provider) => {
//     setServices((prevServices) => {
//       const updatedServices = prevServices.map((s) =>
//         `${s.f_name} ${s.l_name}` === `${service.f_name} ${service.l_name}`
//           ? { ...s, providers: s.providers.filter((p) => p.name !== provider.name) }
//           : s
//       );

//       return updatedServices;
//     });
//     // console.log(provider.practitioner_id,service.services, provider.name );
//     setClinicServicesMapping((prevMappings) => {
//       const updatedMappings = prevMappings.filter(
//           (mapping) =>

//               !(mapping.services === `${service.f_name} ${service.l_name}` && mapping.name === provider.name && mapping.provider_practitioner_id === provider.practitioner_id)

//             );

//       return updatedMappings;
//   });
//   };

//   const handleModal = () => {
//     if (isAddNewService) {
//       if (addNewService === "") {
//         setIsModalOpen(true);
//         setError("Please add a service");
//         setTimeout(() => setError(""), 2000);

//         return;
//       }
//       setError("please Add Service");
//       setTimeout(() => setError(""), 2000);

//       return;
//     }
//     setIsModalOpen(false);
//     setAddnewService("");
//     setIsAddnewService(false);
//     setError("");
//   };

// const handleSubmit = async ()=> {
// if(clinicServicesMapping.length > 0){
//   setError('')

//     const servicesMapping = clinicServicesMapping.map(mapping => {

//         return {
//             assistance_id: mapping.services_id,
//           practitioner_id: mapping.provider_practitioner_id
//         };

//     });
//     console.log(clinicId);

//     const payload = {
//       clinic_id: clinicId,
//       assistancesMapping: servicesMapping
//     };
//     console.log('payload',payload);

//     try {
//       const response = await postData("practitioners/assistancePractitionersMapping", payload);
//       console.log("Response:", response);
//       if(response.success){
//         Swal.fire("Success", "Mapping updated successfully", "success");
//       }

//     } catch (error) {
//       console.error("Error posting data:", error);

//     }

// }else {
//   setError('Please add providers to appointment services');
//   setTimeout(() => setError(""), 3000);
// }
// }

// // console.log('Payload:', payload());

//   return (
//     <>

// <Header />
//       <DndProvider backend={HTML5Backend}>
//         <section className="w-full">
//           <div className="container mx-auto px-4">
//           <div>
//         <h1 className="font-fontPoppins text-2xl text-center font-semibold xss:text-xs sm:text-lg leading-normal">
//           Assistance Mapping To Provider
//              </h1>
//             <h2 className="font-fontPoppins text-center text-gray-400 text-xs font-semibold  xss:text-center sm:text-center">
//               Drag the provider icon to the assistance-row to map
//            </h2>
//            </div>

//             <div className="w-full mt-4 md:flex md:justify-center md:gap-4">
//             <div className="w-1/5 xss:w-full sm:w-full xss:mt-4 sm:mt-4">
//                 <div className="font-fontPoppins text-xs font-semibold px-3 text-center">
//                   Providers
//                 </div>
//                 {/* <div className="w-full min-h-full bg-grayColorLight rounded-lg p-3 mt-1"> */}
//                 <div className="w-full min-h-96 bg-grayColorLight rounded-lg p-3 mt-1 max-h-96 overflow-y-auto">
//                   {providerList && providerList.map((provider, index) => (
//                     <Provider
//                       key={index}
//                       name={`${provider.f_name} ${provider.l_name}`}
//                       src={`${apiUrl}/${provider.photo}`}
//                        practitioner_id={provider.id}

//                     />
//                   ))}
//                 </div>
//               </div>
//               <div className="w-4/5 xss:w-full sm:w-full">
//                 {/* <div className="font-fontPoppins text-sm font-semibold px-3  pl-17">
//                   Add  Appointment Services{" "}

//                   <span class="rounded-full p-1 bg-gray-300 cursor-pointer shadow-md transition duration-100">
//                   <i
//                     onClick={() => setIsModalOpen(true)}
//                       class="fa-solid fa-plus fa-lg text-gray-900 hover:text-gray-100"
//                   title="Click here to add Appointment services"
//                      ></i>
//                        </span>

//                 </div> */}
//                 {/* <div className="w-full min-h-full bg-grayColorLight rounded-lg mt-1"> */}
//                 <div className="w-full mt-6 min-h-96 bg-grayColorLight rounded-lg mt-1 max-h-96 overflow-y-auto ">
//                   {services &&
//                     services.map((service, index) => (
//                       service && (
//                       <div className="w-full border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 p-3 flex items-center gap-3 xss:overflow-auto">
//                         <ServiceRow
//                           key={index}
//                           service={service}
//                           onDropProvider={handleDropProvider}
//                           onRemoveProvider={handleRemoveProvider}
//                         />
//                       </div>
//                       )
//                     ))}
//                 </div>
//               </div>

//             </div>
//           </div>
//         </section>

//       </DndProvider>
//       <section className="w-full mt-10 xss:mt-5 sm:mt-5 lg:min-h-400">
//         <div className="container mx-auto px-4 text-center">
//           <button onClick={handleSubmit} className="w-40 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85">

//             <span className="me-2">Submit</span>
//             {/* <i className="fa-solid fa-arrow-right-long"></i> */}

//           </button>
//           {<p className="text-red-500 ml-2">{error}</p>}
//         </div>
//       </section>

//       <Footer />
//     </>
//   );
// }

// export default AssistProviderMapping;

import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { useEffect, useRef, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import StepsForRegistration from "../StepsForRegistration";
import { apiUrl, postData } from "../../Networking/Api";
import Swal from "sweetalert2";
import Loader2 from "../Loader2";

// const ItemTypes = {
//   PROVIDER: "provider",
// };
export const ItemTypes = {
  SERVICE: "service",
};
// console.log('apiUrl',apiUrl);

const Service = ({ name, src, id }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.SERVICE,
    item: { name, src, id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="w-auto mb-2 py-2 cursor-pointer"
    >
      <img
        src={src || "/placeholder.png"}
        className="rounded-full mx-auto w-10 h-10"
        alt={name}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/placeholder.png";
          console.error("Error loading image:", e.target.src);
        }}
      />
      <div className="w-full font-fontPoppins text-xs font-semibold text-center">
        {name}
      </div>
    </div>
  );
};

const ProviderRow = ({
  provider,
  onDropService,
  onRemoveService,
  assistantMappingisChecked,
}) => {
  const isCheckedRef = useRef(assistantMappingisChecked);

  useEffect(() => {
    isCheckedRef.current = assistantMappingisChecked;
  }, [assistantMappingisChecked]);
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.SERVICE,
      // drop: (item) => onDropService(provider, item),
      drop: (item) => {
        console.log(
          "Dropping provider with mapping:",
          assistantMappingisChecked
        );
        onDropService(provider, item, isCheckedRef.current);
      },

      canDrop: (item) =>
        !provider.services.some((service) => service.id === item.id),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [provider.services, assistantMappingisChecked]
  );

  const removeService = (service) => {
    onRemoveService(provider, service);
  };

  return (
    <div
      ref={drop}
      className={`w-full border border-l-0 border-r-0 border-t-0 border-solid border-gray-100 py-3 flex items-center gap-3 xss:overflow-auto`}
    >
      {/* <img src={`${apiUrl}/${provider.photo}`} className="rounded-full mx-auto w-10 h-10" alt={provider.name} /> */}

      <button className="font-fontPoppins text-xs font-medium bg-white shadow-sm rounded-full w-52 py-2">
        {`${provider?.f_name} ${provider?.l_name}`}
      </button>

      <span className="text-gray-400">
        <i className="fa-solid fa-play fa-lg"></i>
      </span>
      {provider.services.length > 0 ? (
        provider.services.map((service, index) => (
          <div
            key={index}
            className="w-auto p-0 m-0 flex flex-col items-center justify-center"
          >
            <img
              className="rounded-full mx-auto w-10 h-10"
              alt={service?.name}
              src={service.src || "/placeholder.png"}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/placeholder.png";
              }}
            />
            <div className="font-fontPoppins text-xs font-semibold text-center">
              {service.name}
            </div>
            <span
              onClick={() => removeService(service)}
              className="cursor-pointer"
            >
              <i className="fa-regular fa-circle-xmark"></i>
            </span>
          </div>
        ))
      ) : (
        <div className="text-gray-400 text-sm">No Front Office Team Added</div>
      )}
    </div>
  );
};

const AssistProviderMapping = () => {
  const [services, setServices] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addNewService, setAddnewService] = useState("");
  const [isAddNewService, setIsAddnewService] = useState(false);
  const [error, setError] = useState("");
  const location = useLocation();
  const { organizationData, providerData } = location.state || {};
  const [clinicId, setClinicId] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const [assistantMappingisChecked, setAssistantMappingisChecked] =
    useState(true);

  // useEffect(() => {
  //   const storedClinicId = localStorage.getItem("clinicId");
  //   setClinicId(storedClinicId);
  //   fetchServices(storedClinicId);
  //   getProviderList(storedClinicId);
  // }, []);

  useEffect(() => {
    if (providerList.length > 0) {
      const storedClinicId = localStorage.getItem("clinicId");
      fetchClinicServicesMapping(storedClinicId, services);
    }
  }, [services]);

  useEffect(() => {
    const storedClinicId = localStorage.getItem("clinicId");
    setClinicId(storedClinicId);
    getProviderList(storedClinicId).then(() => {
      fetchServices(storedClinicId);
      console.log("abcdpqr", providerList);
    });
  }, []);

  const fetchServices = async (clinicId) => {
    try {
      setLoading(true);
      const storedReferenceId = localStorage.getItem("refernceId");
      const storedAccountType = localStorage.getItem("accountType");
      const whereCondition = {
        clinic_id: clinicId,
        is_active: 1,
      };

      // Add reference_id to whereCondition if accountType is Practitioners
      if (storedAccountType === "Assistances") {
        whereCondition.id = storedReferenceId;
      }

      const payload = {
        modelName: "assistances",
        // whereCondition: {
        //   clinic_id: clinicId,
        //   is_active: 1,
        // },
        whereCondition,
      };
      const response = await postData("masters/getMasterList", payload);
      setLoading(false);
      setServices(response.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching services:", error);
    }
  };

  const getProviderList = async (clinicId) => {
    try {
      setLoading(true);
      const storedReferenceId = localStorage.getItem("refernceId");
      const storedAccountType = localStorage.getItem("accountType");
      const whereCondition = {
        clinic_id: clinicId,
        is_active: 1,
      };

      // Add reference_id to whereCondition if accountType is Practitioners
      if (storedAccountType === "Practitioners") {
        whereCondition.id = storedReferenceId;
      }

      const payload = {
        modelName: "practitioners",
        // whereCondition: {
        //   clinic_id: clinicId,
        //   is_active: 1,
        // },
        whereCondition,
      };
      const response = await postData("masters/getMasterList", payload);
      setLoading(false);
      setProviderList(
        await response.data.map((provider) => ({
          ...provider,
          services: [],
        }))
      );
    } catch (error) {
      console.error("Error fetching clinic details:", error);
      setLoading(false);
    }
  };

  const fetchClinicServicesMapping = async (clinicId, services) => {
    try {
      setLoading(true);
      const storedReferenceId = localStorage.getItem("refernceId");
      const storedAccountType = localStorage.getItem("accountType");
      console.log("accountType....", storedAccountType);
      console.log("practitionerID", storedReferenceId);
      const whereCondition = {
        clinic_id: clinicId,
        is_active: 1,
      };

      // Add practitioner_id to whereCondition if accountType is Practitioners
      if (storedAccountType === "Practitioners") {
        whereCondition.practitioner_id = storedReferenceId;
      }
      if (storedAccountType === "Assistances") {
        whereCondition.assistance_id = storedReferenceId;
      }

      const payload = {
        modelName: "assistance_practitioners_mapping",
        pagination: {
          page: 1,
          pageSize: 500,
        },
        // whereCondition: {
        //   clinic_id: clinicId,
        //   is_active: 1,
        // },
        whereCondition,
        relations: [
          {
            module: "assistances",
          },
          {
            module: "practitioners",
          },
        ],
      };
      const response = await postData("masters/getMasterList", payload);
      setLoading(false);
      console.log("response==========", response.data);
      const mappingByProviderId = await response.data.reduce((acc, curr) => {
        console.log(curr.practitioner_id);
        if (!acc[curr.practitioner_id]) {
          acc[curr.practitioner_id] = [];
        }
        acc[curr.practitioner_id].push(curr);
        return acc;
      }, {});

      const updatedProviders = providerList.map((provider) => {
        const mappings = mappingByProviderId[provider.id] || [];

        const mappedServices = mappings.map((mapping) => {
          const service = services.find(
            (service) => service.id === mapping.assistance_id
          );
          return service
            ? {
                name: `${service.f_name} ${service.l_name}`,
                src: `${apiUrl}/${service.assistant_profile_photo}`,
                id: service.id,
              }
            : null;
        });
        return {
          ...provider,
          services: mappedServices.filter((service) => service !== null),
        };
      });

      setProviderList(updatedProviders);
      console.log(providerList, "providerList");
    } catch (error) {
      console.error("Error fetching services:", error);
      setLoading(false);
    }
  };

  // const handleDropService = (provider, item) => {
  //   setProviderList((prevProviders) => {
  //     const updatedProviders = prevProviders.map((p) =>
  //       p.id === provider.id
  //         ? {
  //             ...p,
  //             services: [
  //               ...p.services,
  //               { name: item.name, id: item.id, src: item.src },
  //             ],
  //           }
  //         : p
  //     );
  //     return updatedProviders;
  //   });
  // };
  const handleDropService = async (
    provider,
    item,
    assistantMappingisChecked
  ) => {
    console.log("xyz");
    if (assistantMappingisChecked === false) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
    } else {
      setProviderList((prevProviders) => {
        const updatedProviders = prevProviders.map((p) =>
          p.id === provider.id
            ? {
                ...p,
                services: [
                  ...p.services,
                  { name: item.name, id: item.id, src: item.src },
                ],
              }
            : p
        );

        const servicesMapping = updatedProviders.flatMap((provider) =>
          provider.services.map((service) => ({
            practitioner_id: provider.id,
            assistance_id: service.id,
          }))
        );
        setTimeout(async () => {
          // const servicesMapping = providerList.flatMap((provider) =>
          //   provider.services.map((service) => ({
          //     practitioner_id: provider.id,
          //     assistance_id: service.id,
          //   }))
          // );

          // const servicesMapping = [
          //   {
          //     practitioner_id: provider.id,
          //     assistance_id: item.id,
          //   },
          // ];

          console.log("servicesMapping", servicesMapping);

          const payload = {
            clinic_id: clinicId,
            assistancesMapping: servicesMapping,
          };

          console.log("payload==>", payload);

          try {
            // setLoading(true);
            const response = await postData(
              "practitioners/assistancePractitionersMapping",
              payload
            );
            // const response = await postData(
            //   "masters/createAndUpdateMaster",
            //   payload
            // );
            // setLoading(false);
            if (response.success) {
              console.log("Success", "Mapping Updated Successfully", "success");
            } else {
              Swal.fire("Error", "Error Updating Mapping ", "error");
            }
          } catch (error) {
            console.error("Error posting data:", error);
            // setLoading(false);
            // }
            // } else {
            //   setError("Please Add Assistances To Provider");

            // setTimeout(() => setError(""), 3000);
            // setLoading(false);
          }
        }, 0);
        console.log("updatedProviders", updatedProviders);
        console.log("servicesMapping", servicesMapping);

        return updatedProviders;
      });
    }
  };

  const handleRemoveService = async (provider, service) => {
    if (!assistantMappingisChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
    } else {
      setProviderList((prevProviders) => {
        const updatedProviders = prevProviders.map((p) =>
          p.id === provider.id
            ? { ...p, services: p.services.filter((s) => s.id !== service.id) }
            : p
        );
        const servicesMapping = updatedProviders.flatMap((provider) =>
          provider.services.map((service) => ({
            practitioner_id: provider.id,
            assistance_id: service.id,
          }))
        );
        setTimeout(async () => {
          // const servicesMapping = providerList.flatMap((provider) =>
          //   provider.services.map((service) => ({
          //     practitioner_id: provider.id,
          //     assistance_id: service.id,
          //   }))
          // );

          // const servicesMapping = [
          //   {
          //     practitioner_id: provider.id,
          //     assistance_id: item.id,
          //   },
          // ];
          if (!assistantMappingisChecked) {
            setErrorMessage(
              "You Must Verify The Information And Provide Your Consent."
            );
            setTimeout(() => setErrorMessage(""), 2000);
            return;
          }
          console.log("servicesMapping", servicesMapping);

          const payload = {
            clinic_id: clinicId,
            assistancesMapping: servicesMapping,
          };

          console.log("payload==>", payload);

          try {
            // setLoading(true);
            const response = await postData(
              "practitioners/assistancePractitionersMapping",
              payload
            );
            // const response = await postData(
            //   "masters/createAndUpdateMaster",
            //   payload
            // );
            // setLoading(false);
            if (response.success) {
              console.log("Success", "Mapping Updated Successfully", "success");
            } else {
              Swal.fire("Error", "Error Updating Mapping ", "error");
            }
          } catch (error) {
            console.error("Error posting data:", error);
            // setLoading(false);
            // }
            // } else {
            //   setError("Please Add Assistances To Provider");

            // setTimeout(() => setError(""), 3000);
            // setLoading(false);
          }
        }, 0);
        console.log("updatedProviders", updatedProviders);
        console.log("servicesMapping", servicesMapping);
        return updatedProviders;
      });
    }
  };

  const handleModal = () => {
    if (isAddNewService) {
      if (addNewService === "") {
        setIsModalOpen(true);
        setError("Please Add A Service");
        setTimeout(() => setError(""), 2000);
        return;
      }
      setError("please Add Service");
      setTimeout(() => setError(""), 2000);
      return;
    }
    setIsModalOpen(false);
    setAddnewService("");
    setIsAddnewService(false);
    setError("");
  };

  const handleSubmit = async () => {
    if (!assistantMappingisChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }
    const servicesMapping = providerList.flatMap((provider) =>
      provider.services.map((service) => ({
        practitioner_id: provider.id,
        assistance_id: service.id,
      }))
    );

    if (servicesMapping.length > 0) {
      setError("");
      const payload = {
        clinic_id: clinicId,
        assistancesMapping: servicesMapping,
      };
      console.log("payload=====>", payload);

      try {
        setLoading(true);
        const response = await postData(
          "practitioners/assistancePractitionersMapping",
          payload
        );
        setLoading(false);
        if (response.success) {
          Swal.fire("Success", "Mapping Updated Successfully", "success");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setLoading(false);
      }
    } else {
      setError("Please Add Assistances To Provider");

      setTimeout(() => setError(""), 3000);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <DndProvider backend={HTML5Backend}>
            <section className="w-full mt-5">
              <div className="container mx-auto px-4">
                <div className="text-center">
                  <h1 className="font-fontPoppins md:text-2xl text-center font-semibold xss:text-xs sm:text-lg leading-normal">
                    Front Office Team to Provider Mapping
                  </h1>
                  <h2 className="font-fontPoppins text-center text-gray-700 mt-2 text-l font-bold  xss:text-center sm:text-center">
                    Drag The Front Office Team Icon To The Provider-Row To Map
                  </h2>
                </div>

                <div className="w-full mt-5 md:flex md:justify-center md:gap-4">
                  <div className="md:w-1/2 xss:w-full sm:w-full xss:mt-4 sm:mt-5 mt-5">
                    <div className="font-fontPoppins text-xs font-semibold px-3 text-center">
                      Front Office Team
                    </div>
                    <div className=" min-h-96 bg-grayColorLight rounded-lg p-3 mt-1 max-h-96 overflow-y-auto">
                      {services &&
                        services.map((service, index) => (
                          <Service
                            key={index}
                            name={`${service.f_name} ${service.l_name}`}
                            src={`${apiUrl}/${service.assistant_profile_photo}`}
                            id={service.id}
                          />
                        ))}
                    </div>
                  </div>
                  <div className="w-4/5 xss:w-full sm:w-full mt-5">
                    <div className="font-fontPoppins text-xs font-semibold px-3 text-center">
                      Providers
                    </div>
                    <div className="w-full  min-h-96 bg-grayColorLight rounded-lg mt-1 p-3  max-h-96 overflow-y-auto ">
                      {providerList &&
                        providerList.map((provider, index) => (
                          <ProviderRow
                            key={index}
                            provider={provider}
                            onDropService={handleDropService}
                            onRemoveService={handleRemoveService}
                            assistantMappingisChecked={
                              assistantMappingisChecked
                            }
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <div className="flex mt-4 items-center gap-2">
                  <input
                    type="checkbox"
                    checked={assistantMappingisChecked}
                    onChange={(e) =>
                      setAssistantMappingisChecked(e.target.checked)
                    }
                  />

                  <span className="font-fontPoppins text-xs font-semibold relative">
                    I have verified all the information and provide consent to
                    Samara Well to use and display this information accordingly.
                  </span>
                </div>
                {errorMessage && (
                  <p className="text-red-500  text-xs absolute">
                    {errorMessage}
                  </p>
                )}
              </div>
            </section>
          </DndProvider>
          <section className="w-full mt-10 xss:mt-5 sm:mt-5 lg:min-h-400">
            {/* <div className="container mx-auto px-4 text-center">
              <button
                onClick={handleSubmit}
                className="w-40 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
              >
                <span className="me-2">Submit</span>
              </button>
              {<p className="text-red-500 ml-2">{error}</p>}
            </div> */}
          </section>
          <Footer />
        </div>
      </>
    </>
  );
};

export default AssistProviderMapping;
