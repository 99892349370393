import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import {
  postData,
  apiUrl,
  getData,
  getApiData,
  baseUrl,
} from "../Networking/Api";
import Swal from "sweetalert2";
import { formatDate } from "@fullcalendar/core/index.js";
import Loader2 from "./Loader2";
import { Switch } from "@headlessui/react";

function ProviderProfileSection() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    photo: "",
    taxonomy_description: "",
    npi_number: "",
    is_active_today: null,
    is_calendar_sync: null,
    locationId: null,
    location: "",
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const [photoFile, setPhotoFile] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [originalNpiNumber, setOriginalNpiNumber] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const [loginCalenderData, setLoginCalenderData] = useState();

  const handleToggle = () => {
    setFormData((prevData) => ({
      ...prevData,
      is_active_today: !prevData.is_active_today,
    }));
  };
  const ehrLogos = {
    Athena: "/Athenahealthlogo.png",
    DrChrono: "/Drchrono_logo.png.webp",
    ModMed: "/ModMed_Logo.jpg.webp",
    GoogleCalendar: "/google-logo-transparent.png",
  };
   const [syncOption, setSyncOption] = useState('');

  useEffect(() => {
    const { is_calendar_sync, athenahealth_id, drchrono_id, modmed_id } =
      formData;
   console.log("FormData:", formData);
    if (is_calendar_sync == 1) {
      setSyncOption("GoogleCalendar");
    } else if (athenahealth_id) {
      console.log("Athena....");
      setSyncOption("Athena");
    } else if (drchrono_id) {
      setSyncOption("DrChrono");
    } else if (modmed_id) {
      setSyncOption("ModMed");
    } else {
      setSyncOption(null); // Show Sign in with Google
    }
  }, [formData]);

   const handleSyncChange = (e) => {
     const selectedOption = e.target.value; // Ensure this is a string
     setSyncOption(selectedOption);
     // Update the backend or perform other actions here if needed
   };

   const ehrNames = ["DrChrono", "Athena", "ModMed"];
  const getProviderDetails = async () => {
    try {
      setLoading(true);
      const clinicId = localStorage.getItem("clinicId");
      const refernceId = localStorage.getItem("refernceId");
      const payload = {
        modelName: "practitioners",
        whereCondition: {
          clinic_id: clinicId,
          id: refernceId,
          is_active: 1,
        },
      };

      const response = await postData("masters/getMasterList", payload);

      console.log("Res", response);

      if (response && response.data && response.data.length > 0) {
         setLoading(false);
        const practioner = response.data[0];
        console.log(
          "is_active_today:",
          practioner.is_active_today,
          practioner.f_name
        );
        setFormData({
          firstName: practioner.f_name,
          lastName: practioner.l_name,
          email: practioner.email,
          photo: practioner.photo,
          taxonomy_description: practioner.taxonomy_description,
          npi_number: practioner.npi_number,
          is_active_today: practioner.is_active_today === 1 ? true : false,
          is_calendar_sync: practioner.is_calendar_sync,
          locationId: practioner.practitioner_location_id,
          athenahealth_id: practioner.athenahealth_id,
          drchrono_id: practioner.drchrono_id,
          modmed_id: practioner.modmed_id,
        });
        setOriginalNpiNumber(practioner.npi_number);
        console.log("abc", practioner.photo);
        localStorage.setItem("profilePic", practioner.photo);
        const UserName = practioner.f_name + " " + practioner.l_name;
        localStorage.setItem("userName", UserName);

        console.log(formData);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching clinic details:", error);
    }
  };
  useEffect(() => {
    const getProviderLocation = async () => {
      try {
        if (!formData.locationId) return;

        // setLoading(true);
        const payload = {
          modelName: "clinic_locations",
          whereCondition: {
            id: formData.locationId,
          },
        };

        const response = await postData("masters/getMasterList", payload);

        console.log("Response", response);

        if (response && response.data && response.data.length > 0) {
          const location = response.data[0];
          const { street, city, state, country, zipcode } = location;

          const fullAddress = `${street}, ${city}, ${state}, ${country}, ${zipcode}`;
          setFormData((prevFormData) => ({
            ...prevFormData,
            location: fullAddress,
          }));
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching clinic location details:", error);
      }
    };

    getProviderLocation();
  }, [formData.locationId]); // Dependency array includes formData.locationId

  useEffect(() => {
    getProviderDetails();
  }, []);

  useEffect(() => {
    const { npi_number } = formData;

    if (npi_number) {
      const timeoutId = setTimeout(() => {
        handleFetchProviderData(npi_number);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [formData.npi_number]);
  const handleFetchProviderData = async () => {
    const { npi_number } = formData;
    await getNPINumberData(npi_number);
  };
  const getNPINumberData = async (npi_number) => {
    try {
      const npiRegistered = await checkNpiRegistered(npi_number);
      if (npi_number && !npiRegistered) {
        // setformErrors("This NPI number is not registered on NPPES.");
        setFormData((prevData) => ({
          ...prevData,
          firstName: "",
          lastName: "",
          taxonomy_description: "",
          email: "",
          calendar: "",
        }));
        setFormErrors({
          firstName: "",
          lastName: "",
          taxonomy_description: "",
          email: "",
          calendar: "",
        });
        return;
      }

      //   setNpiError("");
      const payload = {
        number: formData.npi_number,
      };

      const response = await postData("practitioners/providerSearch", payload);

      const provNpiData = response.data;

      //   setProvNpiData(provNpiData);

      //   setProvAddress(response?.data?.addresses[0]?.addressLine1);
      const number = response?.data?.number || "";
      const firstName = response?.data?.basic?.firstName || "";
      const lastName = response?.data?.basic?.lastName || "";
      const specialization =
        response?.data?.taxonomies?.length > 0
          ? response?.data?.taxonomies[0]?.specialization
          : "";
      const desc =
        response?.data?.taxonomies?.length > 0
          ? response?.data?.taxonomies[0]?.desc
          : "";
      const taxonomyDescription = `${desc}  ${specialization}`;

      setFormData((prevData) => ({
        ...prevData,
        npi_number: number || prevData.npi_number,
        firstName: firstName || prevData.firstName,
        lastName: lastName || prevData.lastName,
        taxonomy_description:
          taxonomyDescription || prevData.taxonomy_description,
        NPI_details: provNpiData,
        is_active: 1,
      }));
      setFormErrors({
        firstName: "",
        lastName: "",
        taxonomy_description: "",
        email: "",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      //   setNpiError("An error occurred while fetching NPI data.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    let newValue = value; // Create a new variable to store the modified value

    if (name === "npi_number") {
      newValue = newValue.replace(/\D/g, ""); // Modify the new variable
    } else if (name === "firstName" || name === "lastName") {
      newValue = newValue.replace(/[^a-zA-Z]/g, "");
    }

    if (name === "photo" && files && files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData({
          ...formData,
          photo: reader.result,
        });
        setPhotoFile(files[0]);
      };
      reader.readAsDataURL(files[0]);
    } else {
      setFormData({
        ...formData,
        [name]: newValue, // Use the modified value if applicable
      });
    }
  };

  const checkNpiRegistered = async (npi_number) => {
    try {
      const response = await postData("practitioners/providerSearch", {
        number: npi_number,
      });
      return response.data && Object.keys(response.data).length > 0;
    } catch (error) {
      console.error("Error checking NPI number:", error);
      return false;
    }
  };
  const checkNpiNumExists = async (npi_number) => {
    if (!npi_number) {
      return false;
    }
    try {
      const payload = {
        modelName: "practitioners",
        whereCondition: {
          npi_number: npi_number,
        },
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };
  const validateForm = async () => {
    let isValid = true;
    const errors = {};
    const npiRegistered = await checkNpiRegistered(formData.npi_number);
    const npiExists = await checkNpiNumExists(formData.npi_number);
    if (formData.npi_number && formData.npi_number !== originalNpiNumber) {
      if (!/^\d{10}$/.test(formData.npi_number)) {
        isValid = false;
        errors.npi_number = "Provider NPI Must Be A 10-Digit Number.";
      } else if (!npiRegistered) {
        isValid = false;
        errors.npi_number = "This NPI number is not registered on NPPES.";
      } else {
        if (npiExists) {
          isValid = false;
          errors.npi_number =
            "The Provider Is Already Registered With Samara-Well.";
        }
      }
    }
    if (!formData.lastName.trim()) {
      isValid = false;
      errors.lastName = "Last Name is required";
    } else if (formData.lastName.length < 3) {
      isValid = false;
      errors.lastName = "Last Name Must Be At Least 3 Characters Long";
    }
    if (!formData.firstName.trim()) {
      isValid = false;
      errors.firstName = "First Name is required";
    } else if (formData.firstName.length < 3) {
      isValid = false;
      errors.firstName = "First Name Must Be At Least 3 Characters Long";
    }

    if (!formData.taxonomy_description.trim()) {
      isValid = false;
      errors.taxonomy_description = "Provider Taxonomy Description Is Required";
    }

    if (!formData.email.trim()) {
      isValid = false;
      errors.email = "Email Is Required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Enter Valid Email";
    }
    setFormErrors(errors);

    return isValid;
  };

  const updateProviderDetails = async () => {
    const isValid = await validateForm();
    if (isValid) {
      setIsUpdating(true);
      try {
        setLoading(true);
        const clinicId = localStorage.getItem("clinicId");
        const refernceId = localStorage.getItem("refernceId");
        const payload = {
          modelName: "practitioners",
          id: refernceId,
          inputData: {
            is_active_today: formData.is_active_today == false ? 0 : 1,
            npi_number: formData.npi_number,
            taxonomy_description: formData.taxonomy_description,
            f_name: formData.firstName,
            l_name: formData.lastName,
            email: formData.email,

            photo: photoFile
              ? formData.photo
              : formData.photo.replace(apiUrl, ""),
            clinicId: clinicId,
          },
        };

        const response = await postData(
          "masters/createAndUpdateMaster",
          payload
        );
        setLoading(false);
        console.log("Update Res", response);
        if (response && response.data) {
          Swal.fire({
            title: "Success",
            text: "Practioner Info Updated Successfully",
            icon: "success",
            showConfirmButton: true,
          });
          // .then(() => {
          //   window.location.reload();
          // });

          getProviderDetails();
        }
      } catch (error) {
        console.error("Error updating Practioner details:", error);
        setLoading(false);
      } finally {
        setLoading(false);
        setIsUpdating(false);
      }
    }
  };

  const SignInWithGoogle = async () => {
    console.log("getApi");

    try {
      const practionerId = localStorage.getItem("refernceId");
      const redirect_uri = "ProviderProfile";
      const redirectUrl = `${baseUrl}googlecalendarauth/googleCalendarAuthetication?practionerId=${practionerId}&redirect_uri=${redirect_uri}`;
      console.log("Redirect URL:", redirectUrl);

      if (redirectUrl && typeof redirectUrl === "string") {
        window.open(redirectUrl, "_self");
      } else {
        console.log("Invalid redirect URL or URL is not a string");
      }
    } catch (error) {
      console.log("Error in SignInWithGoogle:", error);
    }
  };

  // Handle the callback on the redirected page
  const handleOAuthCallback = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const message = queryParams.get("message");

    if (code) {
      if (code === "1") {
        Swal.fire({
          icon: "success",
          title: "Sync Successful",
          text: message || "The synchronization was successful.",
        }).then(() => {
          window.location.href = "/ProviderProfile";
        });
      } else if (code === "0") {
        Swal.fire({
          icon: "error",
          title: "Error Occurred",
          text: message || "An error occurred during synchronization.",
        });
      }
    }
  };

  useEffect(() => {
    handleOAuthCallback();
  }, []);

  return (
    <>
      <>
        {loading && <Loader2 />}
        <Header />
        <div className="pt-16">
          <div>
            <div>
              <div className="container mx-auto px-4  mt-8 ">
                <h1 className="font-fontPoppins text-2xl text-center font-semibold  leading-normal mt-3 mb-3">
                  Practitioner Info
                </h1>
                <div className="w-full bg-gray-100 rounded-lg p-3 pb-9 mt-1">
                  <div className="w-full md:flex justify-between gap-3">
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        NPI Number
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="npi_number"
                        value={formData.npi_number}
                        maxLength={10}
                        placeholder="Enter Provider NPI Number"
                        onChange={handleInputChange}
                      />
                      {formErrors.npi_number && (
                        <p className="text-red-500 text-xs">
                          {formErrors.npi_number}
                        </p>
                      )}
                      {/* {npiError && (
                      <p className="text-red-500 text-xs">{npiError}</p>
                    )} */}
                    </div>
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        First Name
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        placeholder="Enter First Name"
                        // readOnly
                        onChange={handleInputChange}
                        //   onKeyPress={handleKeyPress}
                      />
                      {formErrors.firstName && (
                        <p className="text-red-500 text-xs">
                          {formErrors.firstName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="w-full md:flex justify-between gap-3">
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Last Name
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        placeholder="Enter Last Name"
                        // readOnly
                        onChange={handleInputChange}
                        //   onKeyPress={handleKeyPress}
                      />
                      {formErrors.lastName && (
                        <p className="text-red-500 text-xs">
                          {formErrors.lastName}
                        </p>
                      )}
                    </div>
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Email
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="email"
                        value={formData.email}
                        placeholder="Enter Email"
                        onChange={handleInputChange}
                      />
                      {formErrors.email && (
                        <p className="text-red-500 text-xs">
                          {formErrors.email}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="w-full md:flex justify-between gap-3 mt-3">
                    <div className="  w-full xss:mt-3 sm:mt-3 relative">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Taxonomy Description
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="taxonomy_description"
                        value={formData.taxonomy_description}
                        placeholder="Enter Provider Taxonomy"
                        onChange={handleInputChange}
                      />
                      {formErrors.taxonomy_description && (
                        <p className="text-red-500 text-xs">
                          {formErrors.taxonomy_description}
                        </p>
                      )}
                    </div>
                    <div className="w-full xss:mt-3 sm:mt-3 relative">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Profile Photo
                      </label>
                      <div className="w-full relative">
                        <span className=" z-10 absolute top-2 left-2">
                          <i className="fa-solid fa-camera"></i>
                        </span>
                        <div className=" flex items-center">
                          <input
                            className="rounded-full w-11/12 h-9 px-3 text-sm indent-5 py-1 font-fontPoppins text-gray-400  focus:outline-none focus:ring ring-gray-200 shadow"
                            style={{
                              paddingLeft: "10px",
                              paddingTop: "7px",
                            }}
                            type="file"
                            placeholder="Upload"
                            name="photo"
                            accept="image/jpeg, image/png"
                            onChange={handleInputChange}

                            // ref={fileInputRef}
                          />
                          {formData.photo && (
                            <img
                              src={
                                formData.photo.startsWith("data:")
                                  ? formData.photo
                                  : `${apiUrl}/${formData.photo}`
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/placeholder.png";
                              }}
                              alt=""
                              className="w-9 h-9 ml-1 cursor-pointer"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:flex md:justify-between gap-3 mt-3">
                    <div className="w-full mt-3">
                      <label className="font-poppins text-sm font-semibold text-gray-700">
                        Location
                        {/* <span className="text-red-500">*</span> */}
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="location"
                        value={formData.location}
                        placeholder="Enter Location"
                        readOnly
                        // onChange={handleInputChange}
                      />
                      {/* {formErrors.lastName && (
                        <p className="text-red-500 text-xs mt-1">
                          {formErrors.lastName}
                        </p>
                      )} */}
                    </div>
                    <div className="w-full mt-3">
                      <label className="font-poppins text-sm font-semibold text-gray-700">
                        Status
                      </label>
                      <div className="mt-1 flex items-center">
                        <Switch
                          checked={formData.is_active_today}
                          onChange={handleToggle}
                          className={`${
                            formData.is_active_today
                              ? "bg-green-500"
                              : "bg-red-500"
                          } relative inline-flex items-center h-9 rounded-full w-20 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500`}
                        >
                          {formData.is_active_today ? (
                            <>
                              <span className="absolute left- text-white text-sm sm:left-1">
                                Active
                              </span>
                              <span className="translate-x-12 inline-block w-6 h-6 transform bg-white rounded-full transition-transform" />
                            </>
                          ) : (
                            <>
                              <span className="absolute right-2 pl-10 text-sm text-white ">
                                Inactive
                              </span>
                              <span className="translate-x-0.5 inline-block w-6 h-6 transform bg-white rounded-full transition-transform" />
                            </>
                          )}
                          {/* <span className="sr-only">Enable Integration</span>
                        <span
                          className={`translate-x-1 inline-block w-7 h-7 transform bg-white rounded-full transition-transform ${
                            formData.is_active_today ? "translate-x-12" : "" */}
                          {/* }` */}
                          {/* } */}
                          {/* /> */}
                        </Switch>
                      </div>
                      {/* <div className="mt-1 flex items-center">
                        <Switch
                          checked={formData.is_active_today}
                          onChange={handleToggle}
                          className={`${
                            formData.is_active_today
                              ? "bg-green-600"
                              : "bg-red-500"
                          } relative inline-flex items-center h-8 rounded-full w-20 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                        >
                          {formData.is_active_today ? (
                            <>
                              <span className="absolute left- text-white text-sm sm:left-1">
                                Active
                              </span>
                              <span className="translate-x-12 inline-block w-6 h-6 transform bg-white rounded-full transition-transform" />
                            </>
                          ) : (
                            <>
                              <span className="absolute right-2 pl-10 text-sm text-white ">
                                Inactive
                              </span>
                              <span className="translate-x-0.5 inline-block w-6 h-6 transform bg-white rounded-full transition-transform" />
                            </>
                          )}
                        </Switch>
                      </div> */}
                    </div>
                  </div>

                  {/* <div className="p-4 max-w-4xl mx-auto">
                    <h2 class="text-xl font-bold mb-4">Integration</h2>
                    <div className="bg-white shadow-md rounded-md p-6">
                      <div className="flex items-center justify-between mb-4">
                        <h2 className="text-lg font-semibold">
                          Google Calendar Integration
                        </h2>
                      </div>

                      {formData.is_calendar_sync !== 1 && (
                        <div className="flex items-center justify-center mt-6 space-x-4">
                        
                          <button
                            onClick={SignInWithGoogle}
                            className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md shadow-sm bg-white hover:bg-gray-100"
                          >
                            <img
                              src="/icons8-google-48.png"
                              alt="Google"
                              className="w-5 h-5 mr-2"
                            />
                            Sign in With Google
                          </button>
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className="p-4 max-w-4xl mx-auto">
                    <h2 className="text-xl font-bold mb-4">Integration</h2>

                    {/* Display Sync Option as Logo */}
                    <div className="flex flex-col items-center justify-center bg-white shadow-md rounded-md p-6">
                      {syncOption ? (
                        <div className="flex flex-col items-center">
                          <img
                            src={ehrLogos[syncOption]}
                            alt={syncOption}
                            className="w-22 h-16 mb-2"
                          />
                          <div className="text-lg font-semibold">
                            Currently Synced With{" "}
                            {syncOption === "GoogleCalendar"
                              ? "Google Calendar"
                              : syncOption}
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center">
                          <div className="flex items-center justify-between mb-4">
                            <h2 className="text-lg font-semibold">
                              Google Calendar Integration
                            </h2>
                          </div>
                          <button
                            onClick={SignInWithGoogle}
                            className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md shadow-sm bg-white hover:bg-gray-100"
                          >
                            <img
                              src="/icons8-google-48.png"
                              alt="Google"
                              className="w-5 h-5 mr-2"
                            />
                            Sign in With Google
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-3  xss:mt-3 sm:mt-3 relative">
                  <label className="font-fontPoppins text-xs font-semibold text-transparent">
                    .
                  </label>
                  <button
                    className=" w-52 h-10 bg-blueColor text-white font-fontPoppins text-xs rounded-full"
                    onClick={updateProviderDetails}
                    disabled={isUpdating}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </>
    </>
  );
}

export default ProviderProfileSection;
