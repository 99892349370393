const Loader2 = () => {
  return (
    // <div className="loader-container">
    //   <div className="loader">
    //     <div className="loader--dot"></div>
    //     <div className="loader--dot"></div>
    //     <div className="loader--dot"></div>
    //     <div className="loader--dot"></div>
    //     <div className="loader--dot"></div>
    //     <div className="loader--dot"></div>
    //     <div className="loader--text"></div>
    //   </div>
    // </div>
    <div id="cover-spin">
      <div className="loader-container">
        <div className="loader">
          <div className="loader--dot"></div>
          <div className="loader--dot"></div>
          <div className="loader--dot"></div>
          <div className="loader--dot"></div>
          <div className="loader--dot"></div>
          <div className="loader--dot"></div>
          <div className="loader--text"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader2;
