import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { useState, useEffect } from "react";
import { postData, apiUrl } from "../Networking/Api";
import Swal from "sweetalert2";
import { MdCopyAll } from "react-icons/md";
import Loader2 from "./Loader2";

function BrandIdentityAndMedia() {
  const location = useLocation();
  const {
    userDetails,
    organizationData,
    providerData,
    serviceProviders,
    clinicServicesData,
    records,
  } = location.state || {};

  const [clinicId, setClinicId] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [facilityImages, setFacilityImages] = useState([]);
  const [locations, setLocations] = useState([]);
  const [copySuccess, setCopySuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orgName, setorgName] = useState("");
  let page = 1;
  const pageSize = 15;
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // const handleLogoImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       if (e.target && e.target.result) {
  //         const logoImageBase64 = e.target.result.toString();

  //         setLogoImage(logoImageBase64);
  //         localStorage.setItem("logoImage", logoImageBase64);
  //         const payload = {
  //           modelName: "clinics",
  //           id: clinicId,
  //           whereCondition: {
  //             id: clinicId,
  //           },
  //           inputData: {
  //             logo_image: logoImageBase64,
  //           },
  //         };
  //         try {
  //           const response = await postData(
  //             "masters/createAndUpdateMaster",
  //             payload
  //           );
  //           if (response.code !== 1) {
  //             console.error("Error from API:", response.message);
  //             Swal.fire("Error", "Failed To Update Logo Image", "error");
  //           } else {
  //             setLogoImage(response.data.logo_image);

  //             Swal.fire("success", "Logo Image Updated");
  //           }
  //         } catch (error) {
  //           console.error("API call error:", error);
  //           Swal.fire("Error", "Failed To Update Logo Image", "error");
  //         }
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // const removeImage = async (index) => {
  //   const imageToRemove = facilityImages[index];
  //   if (imageToRemove.new) {
  //     setFacilityImages((prevFacilityImages) => {
  //       const updatedImages = [...prevFacilityImages];
  //       updatedImages.splice(index, 1);
  //       localStorage.setItem("facilityImages", JSON.stringify(updatedImages));
  //       return updatedImages;
  //     });
  //   } else {
  //     const confirmationResult = await Swal.fire({
  //       title: "Are You Sure?",
  //       text: "You Won't Be Able To Revert This!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, Delete It!",
  //     });

  //     if (confirmationResult.isConfirmed) {
  //       const payload = {
  //         modelName: "clinic_photos",
  //         id: imageToRemove.id,
  //         inputData: {
  //           is_active: 0,
  //         },
  //       };
  //       try {
  //         const response = await postData(
  //           "masters/createAndUpdateMaster",
  //           payload
  //         );
  //         if (response.code === 1) {
  //           setFacilityImages((prevFacilityImages) => {
  //             const updatedImages = [...prevFacilityImages];
  //             updatedImages.splice(index, 1);
  //             localStorage.setItem(
  //               "facilityImages",
  //               JSON.stringify(updatedImages)
  //             );
  //             return updatedImages;
  //           });
  //           Swal.fire("Deleted!", "Your Image Has Been Deleted.", "success");
  //         } else {
  //           console.error("Error from API:", response.message);
  //           Swal.fire("Error", "Failed To Delete Image", "error");
  //         }
  //       } catch (error) {
  //         console.error("API call error:", error);
  //         Swal.fire("Error", "Failed To Delete Image", "error");
  //       }
  //     }
  //   }
  // };

  const getClinicName = async (clinicId) => {
    try {
      const payload = {
        modelName: "clinics",
        whereCondition: {
          id: clinicId,
        },
      };
      const response = await postData("masters/getMasterList", payload);
      if (response.data && response.data.length > 0) {
        // setLogoImage(response.data[0].logo_image);
        setorgName(response.data[0].name.replace(/\s+/g, "").toLowerCase());
      }
    } catch (error) {
      console.error("Error fetching clinic details:", error);
    }
  };

  // const getClinicPhotos = async (clinicId) => {
  //   try {
  //     setLoading(true);
  //     const clinicId = localStorage.getItem("clinicId");
  //     const payload = {
  //       modelName: "clinic_photos",
  //       pagination: {
  //         page,
  //         pageSize,
  //       },
  //       whereCondition: {
  //         clinic_id: clinicId,
  //         is_active: 1,
  //       },
  //     };

  //     const response = await postData("masters/getMasterList", payload);
  //     setLoading(false);
  //     if (response.code === 1) {
  //       const fetchedFacilityImages = response.data.map((item) => ({
  //         id: item.id,
  //         photo: item.file,
  //         is_active: item.is_active,
  //       }));
  //       setFacilityImages(fetchedFacilityImages);
  //     } else {
  //       console.error("Error from API:", response.message);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error fetching clinic photos:", error);
  //   }
  // };

  useEffect(() => {
    const clinicId = localStorage.getItem("clinicId");
    if (clinicId) {
      setClinicId(clinicId);
    }
    // getClinicPhotos(clinicId);
    getClinicName(clinicId);
  }, []);

  // const handleFacilityImageUpload = (e) => {
  //   const files = e.target.files;
  //   if (files) {
  //     const imageFiles = Array.from(files);
  //     const promises = imageFiles.map((file) => {
  //       return new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.onload = (e) => {
  //           if (e.target && e.target.result) {
  //             resolve(e.target.result.toString());
  //           } else {
  //             reject("Failed to read file");
  //           }
  //         };
  //         reader.onerror = () => {
  //           reject("File reading failed");
  //         };
  //         reader.readAsDataURL(file);
  //       });
  //     });

  //     Promise.all(promises)
  //       .then((base64Images) => {
  //         setFacilityImages((prevImages) => [
  //           ...prevImages,
  //           ...base64Images.map((base64Image) => ({
  //             photo: base64Image,
  //             new: true,
  //           })),
  //         ]);
  //       })
  //       .catch((error) => {
  //         console.error("Error reading files:", error);
  //       });
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   let errorOccurred = false;
  //   try {
  //     for (const image of facilityImages) {
  //       if (image.new) {
  //         const payload = {
  //           modelName: "clinic_photos",
  //           inputData: {
  //             clinic_id: clinicId,
  //             is_active: 1,
  //             file: image.photo,
  //           },
  //         };
  //         const response = await postData(
  //           "masters/createAndUpdateMaster",
  //           payload
  //         );

  //         if (response.code !== 1) {
  //           console.error("Error from API:", response.message);
  //           errorOccurred = true;
  //         }
  //       }
  //     }
  //     if (errorOccurred) {
  //       Swal.fire("Error", "Failed To Submit Facility Images", "error");
  //     } else {
  //       // getClinicPhotos();
  //       setTimeout(() => {
  //         Swal.fire("Success", "Images Submitted Successfully", "success");
  //       }, 3000);
  //     }
  //   } catch (error) {
  //     console.error("API call error:", error);
  //     Swal.fire("Error", "Failed To Submit Images", "error");
  //   }
  // };

  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const formatZipCode = (zipCode) => {
    // Remove all non-digit characters
    const cleaned = ("" + zipCode).replace(/\D/g, "");
    if (cleaned.length === 5) {
      return cleaned;
    } else if (cleaned.length === 9) {
      return `${cleaned.slice(0, 5)}-${cleaned.slice(5)}`;
    }
    return zipCode;
  };

  const getClinicLocations = async (clinicId) => {
    try {
      setLoading(true);
      const payload = {
        modelName: "clinic_locations",
        whereCondition: {
          clinic_id: clinicId,
          is_deleted: 0,
          is_active: 1,
        },
      };

      const response = await postData("masters/getMasterList", payload);
      setLoading(false);
      if (response.data && Array.isArray(response.data)) {
        setLocations(response.data);
      } else {
        console.error("Invalid response format:", response);
        setLoading(false);
        setLocations([]);
      }
    } catch (error) {
      console.error("Error fetching clinic details:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedClinicId = localStorage.getItem("clinicId");
    if (storedClinicId) {
      setClinicId(storedClinicId);
      getClinicLocations(storedClinicId);
    }
  }, []);

  const handleWebsiteCopyClick = (city, street, index) => {
    const baseUrl = window.location.origin;
    const formattedStreet = encodeURIComponent(
      street.trim().replace(/\s+/g, "").toLowerCase()
    );
    const formattedCity = encodeURIComponent(
      city.trim().replace(/\s+/g, "").toLowerCase()
    );
    const url = `${baseUrl}/bookAppoinment/${orgName}/${formattedCity}-${formattedStreet}`;
    const buttonHTML = `
    <a
      href="${url}"
      className="inline-block text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
      style="
        background-color: #516b5b;
        border-radius: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        color: #fff;
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        padding: 15px 30px;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.3s ease;
      "
    >
      Schedule Now
    </a>
  `;

    navigator.clipboard.writeText(buttonHTML).then(
      () => {
        setCopySuccess({ index, tableKey: "website" });
        setTimeout(() => setCopySuccess(null), 2000);
      },
      (err) => {
        console.error("Failed to copy content: ", err);
      }
    );
  };

  const handleSocialCopyClick = (city, street, index) => {
    const baseUrl = window.location.origin;
    const formattedStreet = encodeURIComponent(
      street.trim().replace(/\s+/g, "").toLowerCase()
    );
    const formattedCity = encodeURIComponent(
      city.trim().replace(/\s+/g, "").toLowerCase()
    );
    const url = `${baseUrl}/bookAppoinment/${orgName}/${formattedCity}-${formattedStreet}`;
    // const url =
    //   city && street && orgName
    //     ? `${baseUrl}/bookAppoinment/${orgName}/${formattedCity}/${formattedStreet}`
    //     : `${baseUrl}/bookAppoinment/${orgName}`;
    navigator.clipboard.writeText(url).then(
      () => {
        setCopySuccess({ index, tableKey: "social" });
        setTimeout(() => setCopySuccess(null), 2000);
      },
      (err) => {
        console.error("Failed to copy URL: ", err);
      }
    );
  };
  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          {/* <section className="w-full py-2">
            <div className="container mx-auto px-4 flex items-center gap-16 xss:gap-2 sm:gap-5">
              <h1 className="font-fontPoppins text-2xl font-semibold xss:text-xs sm:text-lg leading-normal text-center">
                Clinic Photos
              </h1>
            </div>
          </section>

          <section className="w-full">
            <div className="container mx-auto px-4">
              <div className="bg-grayColorLight rounded-lg p-3">
                <div className="w-full">
                  <div className="w-full mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Add Media Showcasing Your Facility
                    </label>
                    <div className="md:w-80">
                      <div className="w-full relative">
                        <span className="z-10 absolute left-3 top-2">
                          <i className="fa-solid fa-camera"></i>
                        </span>
                        <input
                          onChange={handleFacilityImageUpload}
                          className="w-full h-9 indent-5 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="file"
                          accept="image/*"
                          placeholder="Upload"
                          multiple
                        />
                      </div>
                    </div>
                    <div className="mt-3 flex flex-wrap w-full gap-4">
                      {facilityImages.map((image, index) => (
                        <div key={index} className="relative">
                          <img
                            src={
                              image.photo.startsWith("data:image")
                                ? image.photo
                                : `${apiUrl}/${image.photo}`
                            }
                            alt={`Selected Image ${index + 1}`}
                            className="object-cover w-20 h-20"
                          />
                          <button
                            className="absolute top-0 right-0 bg-white rounded-full p-1"
                            onClick={() => removeImage(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-5 h-5 text-gray-600"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 01-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section className="w-full py-2">
            <div className="container mx-auto">
              <h1 className="font-fontPoppins md:text-2xl text-center font-semibold xss:text-xs sm:text-lg leading-normal">
                Scheduling Widget & Hyperlink
              </h1>
            </div>
          </section>

          <section className="w-full mt-8">
            <div className="container mx-auto px-4 flex items-center gap-16 xss:gap-2 sm:gap-5">
              <h1 className="font-fontPoppins text-2xl font-semibold xss:text-xs sm:text-lg leading-normal text-center">
                Your Website
              </h1>
            </div>
          </section>
          <div className="container mx-auto px-4 overflow-auto mt-6 mb-16">
            <table className="border-collapse table-auto w-full text-sm">
              <thead>
                <tr>
                  <th className="rounded-tl-lg font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    Contact Number
                  </th>
                  <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    Street Address
                  </th>
                  <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    City
                  </th>
                  <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    State
                  </th>
                  <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    Zipcode
                  </th>
                  <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    Copy-Paste
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {locations.map((location, index) => (
                  <tr key={`website-${index}`}>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      {formatPhoneNumber(location.phone)}{" "}
                    </td>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      {location.street}
                    </td>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      {location.city}
                    </td>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      {location.state}
                    </td>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      {formatZipCode(location.zipcode)}{" "}
                    </td>

                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      <MdCopyAll
                        className="text-black text-xl"
                        title="Copy Scheduling Widget"
                        onClick={() =>
                          handleWebsiteCopyClick(
                            location.city,
                            location.street,
                            index
                          )
                        }
                      />
                      {copySuccess &&
                        copySuccess.index === index &&
                        copySuccess.tableKey === "website" && (
                          <span className="absolute transform -translate-x-1/2 bottom-full mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded">
                            Scheduling Widget Copied To Clipboard
                          </span>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <section className="w-full mt-8">
            <div className="container mx-auto px-4 flex items-center gap-16 xss:gap-2 sm:gap-5">
              <h1 className="font-fontPoppins text-2xl font-semibold xss:text-xs sm:text-lg leading-normal text-center">
                Your Social Media
              </h1>
            </div>
          </section>
          <div className="container mx-auto px-4 overflow-auto mt-6">
            <table className="border-collapse table-auto w-full text-sm">
              <thead>
                <tr>
                  <th className="rounded-tl-lg font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    Contact Number
                  </th>
                  <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    Street Address
                  </th>
                  <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    City
                  </th>
                  <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    State
                  </th>
                  <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    Zipcode
                  </th>
                  <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                    Copy-Paste
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {locations.map((location, index) => (
                  <tr key={`social-${index}`}>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      {formatPhoneNumber(location.phone)}{" "}
                    </td>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      {location.street}
                    </td>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      {location.city}
                    </td>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      {location.state}
                    </td>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      {formatZipCode(location.zipcode)}{" "}
                    </td>
                    <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                      <MdCopyAll
                        className="text-black text-xl"
                        title="Copy Hyperlink"
                        onClick={() =>
                          handleSocialCopyClick(
                            location.city,
                            location.street,
                            index
                          )
                        }
                      />
                      {copySuccess &&
                        copySuccess.index === index &&
                        copySuccess.tableKey === "social" && (
                          <span className="absolute transform -translate-x-1/2 bottom-full mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded">
                            URL copied to clipboard
                          </span>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* <section className="w-full mt-10 xss:mt-5 sm:mt-5 lg:min-h-190">
            <div className="container mx-auto px-4 text-center">
              <a
                href="#"
                className="inline-block text-white font-medium text-xs leading-tight  rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                style={{
                  backgroundColor: "#516b5b",
                  borderRadius: "20px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  color: "#fff",
                  display: "inline-block",
                  fontSize: "25px",
                  fontWeight: "bold",
                  padding: "15px 30px",
                  textAlign: "center",
                  textDecoration: "none",
                  transition: "background-color 0.3s ease",
                }}
              >
                Schedule Now
              </a>
            </div>
          </section> */}

          <Footer />
        </div>
      </>
    </>
  );
}

export default BrandIdentityAndMedia;
