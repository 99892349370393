function PatientsFooter() {
  return (
    <section className="w-full mt-4">
      <div className="mx-auto px-4 py-2 md:flex md:items-center md:justify-between">
        <div className="w-full flex items-center justify-start gap-2 xss:justify-center">
          <span className="font-fontPoppins text-xs font-normal">
            Secure scheduling by
          </span>
          <img src="/samara-logo.png" alt="samara-logo" />
        </div>

        <div className="w-full">
          <ul className="list-none flex items-center justify-end xss:justify-center gap-2">
            <li>
              <a
                href={" "}
                className="font-fontPoppins text-xs font-normal text-gray-400 no-underline hover:text-blueColor"
              >
                Terms of Use
              </a>
            </li>
            <li>
              <span className="font-fontPoppins text-xs font-normal text-gray-400 no-underline">
                |
              </span>
            </li>
            <li>
              <a
                href={" "}
                className="font-fontPoppins text-xs font-normal text-gray-400 no-underline hover:text-blueColor"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <span className="font-fontPoppins text-xs font-normal text-gray-400 no-underline">
                |
              </span>
            </li>
            <li>
              <a
                href={" "}
                className="font-fontPoppins text-xs font-normal text-gray-400 no-underline hover:text-blueColor"
              >
                English
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default PatientsFooter;
