import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="523532400000-hofcffke0hllelv7e98gvp4q41d9vi9q.apps.googleusercontent.com">
    {/* <React.StrictMode> */}
    {/* strictMode commented By Darshna do not use */}
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);
