import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { postData } from "../../Networking/Api";
import Swal from "sweetalert2";
import CustomSelect from "../CustomSelect";
import CustomSelectHealth from "../CustomSelectHealth";
import Loader2 from "../Loader2";

const InsuranceInfo = () => {
  const [formData, setFormData] = useState({
    insuranceCarrierName: "",
    selectedPlan: "",
  });
  const [errors, setErrors] = useState({});
  const [insuranceOptions, setInsuranceOptions] = useState([]);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [selectedCarrierId, setSelectedCarrierId] = useState(null);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [records, setRecords] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [insuranceisChecked, setInsuranceisChecked] = useState(true);
  const storedAccountType = localStorage.getItem("accountType");
  console.log("accountType....", storedAccountType);
  useEffect(() => {
    const getHealthCarrierOptions = async () => {
      try {
        setLoading(true);
        const payload = {
          modelName: "health_insurance_carrier_master",
          pagination: {
            page: 1,
            pageSize: 100,
          },
          whereCondition: {
            is_active: 1,
          },
        };
        const response = await postData("/masters/getMasterList", payload);
        setLoading(false);
        setCarrierOptions(response.data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching insurance options:", error);
      }
    };

    getHealthCarrierOptions();
  }, []);

  const getInsurancePlans = async (carrierId) => {
    let allPlans = [];
    let page = 1;
    const pageSize = 10;

    try {
      const response = await postData("/masters/getMasterList", {
        modelName: "insurance_masters",
        pagination: {
          page: 1,
          pageSize: 100,
        },
        whereCondition: {
          health_insurance_carrier_master_id: carrierId,
          is_active: 1,
        },
      });

      const plans = response.data;

      setInsuranceOptions(plans);
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };

  useEffect(() => {
    const storedClinicId = localStorage.getItem("clinicId");
    if (storedClinicId) {
      getInsuranceDetails(storedClinicId);
    }
  }, []);

  const getInsuranceDetails = async (clinicId) => {
    try {
      setLoading(true);
      const payload = {
        modelName: "clinic_insurances",
        whereCondition: {
          clinic_id: clinicId,
          is_active: 1,
        },
        relations: [
          {
            module: "insurance_masters",
          },
          {
            module: "health_insurance_carrier_master",
          },
        ],
      };
      const response = await postData("/masters/getMasterList", payload);
      setLoading(false);
      setRecords(response.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching insurance details:", error);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};
    if (!formData.insuranceCarrierName) {
      isValid = false;
      errors.insuranceCarrierName = "Insurance Carrier Name Is Required";
    }
    if (!formData.selectedPlan) {
      isValid = false;
      errors.selectedPlan = "Health Insurance Plans Is Required";
    } else if (formData.selectedPlan === "Select Health Insurance Plans") {
      errors.selectedPlan = "Health Insurance Plans Is Required";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    console.log(`Handling change for ${name}: ${value}`);

    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });

    if (name === "insuranceCarrierName") {
      const selectedCarrier = carrierOptions.find(
        (option) => option.health_insurance_carrier_name === value
      );
      const carrierId = selectedCarrier ? selectedCarrier.id : null;

      console.log(`Selected Carrier ID: ${carrierId}`);

      setSelectedCarrierId(carrierId);

      if (carrierId) {
        await getInsurancePlans(carrierId);
      } else {
        setInsuranceOptions([]);
      }
    }

    if (name === "selectedPlan") {
      const selectedProvider = insuranceOptions.find(
        (option) => option.insurance_name === value
      );
      const providerId = selectedProvider ? selectedProvider.id : null;
      setSelectedProviderId(providerId);

      console.log(`Selected Provider ID: ${providerId}`);
    }
  };

  // useEffect(() => {
  //   if (insuranceOptions.length > 0) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       selectedPlan: "Select Health Insurance Plans",
  //     }));
  //   } else {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       selectedPlan: "",
  //     }));
  //   }
  // }, [insuranceOptions]);

  // const handleEdit = async (index) => {
  //   const record = records[index];
  //   const selectedCarrier = carrierOptions.find(
  //     (option) => option.id === record.health_insurance_carrier_id
  //   );
  //   const carrierId = selectedCarrier ? selectedCarrier.id : null;

  //   if (carrierId) {
  //     await getInsurancePlans(carrierId);
  //   }
  //   console.log( insuranceOptions);
  //   const selectedProvider = insuranceOptions.find(
  //     (option) => option.id === record.insurance_provider_id
  //   );

  //   const providerId = selectedProvider ? selectedProvider.id : null;
  //   // console.log(insuranceCarrierName record.health_insurance_carrier_master.health_insurance_carrier_name);

  //   setFormData({
  //     insuranceCarrierName:
  //       record.health_insurance_carrier_master.health_insurance_carrier_name,
  //     selectedPlan: record.insurance_masters.insurance_name,
  //   });
  //   console.log("formData", formData);
  //   setSelectedCarrierId(carrierId);
  //   setSelectedProviderId(providerId);
  //   setEditingIndex(index);
  // };

  const handleEdit = async (index) => {
    try {
      window.scrollTo({ top: 0, behavior: "smooth" });
      const record = records[index];
      console.log("Editing record:", record);

      const selectedCarrier = carrierOptions.find(
        (option) => option.id === record.health_insurance_carrier_id
      );
      const carrierId = selectedCarrier ? selectedCarrier.id : null;

      if (carrierId) {
        // Fetch insurance plans and wait until the insuranceOptions state is updated
        await getInsurancePlans(carrierId);
      }

      // We will now rely on a useEffect to handle setting the formData after insuranceOptions is updated
      setEditingIndex(index);
      setSelectedCarrierId(carrierId);
    } catch (error) {
      console.error("Error in handleEdit:", error);
    }
  };

  // Adding a new useEffect to update formData once insuranceOptions changes
  useEffect(() => {
    if (editingIndex !== null && insuranceOptions.length > 0) {
      const record = records[editingIndex];
      const selectedProvider = insuranceOptions.find(
        (option) => option.id === record.insurance_provider_id
      );

      const providerId = selectedProvider ? selectedProvider.id : null;

      const insuranceCarrierName =
        record.health_insurance_carrier_master.health_insurance_carrier_name ||
        "Unknown Carrier";
      const selectedPlan =
        record.insurance_masters.insurance_name || "Unknown Plan";

      console.log("Setting form data with:", {
        insuranceCarrierName,
        selectedPlan,
      });

      setFormData({
        insuranceCarrierName,
        selectedPlan,
      });

      console.log("Updated formData:", {
        insuranceCarrierName,
        selectedPlan,
      });

      setSelectedProviderId(providerId);
      console.log("Selected Provider ID:", providerId);
    }
  }, [insuranceOptions, editingIndex]);

  const handleDelete = async (index) => {
    try {
      if (index === editingIndex) {
        Swal.fire({
          title: "Cannot Delete!",
          text: "You Cannot Delete A Record While It Is Being Edited.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
      const insuranceDetailsId = records[index];
      const payload = {
        modelName: "clinic_insurances",
        id: insuranceDetailsId.id,
        inputData: { is_active: 0 },
      };

      const confirmationResult = await Swal.fire({
        title: "Are You Sure?",
        text: "You Won't Be Able To Revert This!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete It!",
      });

      if (confirmationResult.isConfirmed) {
        const response = await postData(
          "masters/createAndUpdateMaster",
          payload
        );

        if (response.code === 1) {
          Swal.fire({
            title: "Deleted!",
            text: "Your Record Has Been Deleted.",
            icon: "success",
          });

          const updatedInsuranceDetails = [...records];
          updatedInsuranceDetails.splice(index, 1);
          setRecords(updatedInsuranceDetails);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const storedClinicId = localStorage.getItem("clinicId");

  //   const isValid = validateForm();
  //   const isDuplicate = records.some(
  //     (record) =>
  //       record.health_insurance_carrier_master.health_insurance_carrier_name ===
  //         formData.insuranceCarrierName &&
  //       record.insurance_masters.insurance_name === formData.selectedPlan
  //   );

  //   if (isDuplicate) {
  //     Swal.fire({
  //       title: "Duplicate Entry",
  //       text: "This Record Is Already Present.",
  //       icon: "warning",
  //       timer: 2000,
  //       button: "OK",
  //     });
  //     return;
  //   }
  //   if (isValid) {
  //     if (editingIndex !== null) {
  //       try {
  //         setLoading(true);
  //         const recordId = records[editingIndex].id;
  //         const payload = {
  //           modelName: "clinic_insurances",
  //           id: recordId,
  //           inputData: {
  //             clinic_id: storedClinicId,
  //             insurance_name: formData.selectedPlan,
  //             insuranceCarrierName: formData.insuranceCarrierName,
  //             insurance_provider_id: selectedProviderId,
  //             health_insurance_carrier_id: selectedCarrierId,
  //             is_active: 1,
  //           },
  //         };

  //         const response = await postData(
  //           "/masters/createAndUpdateMaster",
  //           payload
  //         );
  //         getInsuranceDetails(storedClinicId);
  //         setLoading(false);
  //         if (response.code === 1) {
  //           {
  //             Swal.fire({
  //               title: "Success",
  //               text: "Insurance Info Updated Successfully",
  //               icon: "success",
  //               timer: 2000,
  //               showConfirmButton: true,
  //             });

  //             resetForm();
  //             window.location.reload();
  //           }
  //         }
  //       } catch (error) {
  //         setLoading(false);
  //         console.error("Error updating record:", error);
  //       }
  //     } else {
  //       try {
  //         setLoading(true);
  //         const payload = {
  //           modelName: "clinic_insurances",
  //           inputData: {
  //             clinic_id: storedClinicId,
  //             insurance_name: formData.selectedPlan,
  //             insuranceCarrierName: formData.insuranceCarrierName,
  //             insurance_provider_id: selectedProviderId,
  //             health_insurance_carrier_id: selectedCarrierId,
  //             is_active: 1,
  //           },
  //         };

  //         const response = await postData(
  //           "/masters/createAndUpdateMaster",
  //           payload
  //         );
  //         getInsuranceDetails(storedClinicId);
  //         setLoading(false);
  //         if (response.code === 1) {
  //           Swal.fire({
  //             title: "Success",
  //             text: "Insurance Info Added Successfully",
  //             icon: "success",
  //             timer: 2000,
  //             showConfirmButton: true,
  //           });

  //           resetForm();
  //           window.location.reload();
  //         }
  //       } catch (error) {
  //         console.error("Error adding record:", error);
  //         setLoading(false);
  //       }
  //     }
  //   } else {
  //     console.log("Form validation failed. Cannot submit.");
  //   }
  // };
  const handleSubmit = async (e) => {
    if (!insuranceisChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }
    e.preventDefault();
    const storedClinicId = localStorage.getItem("clinicId");

    const isValid = validateForm();

    // Determine if there are any changes compared to the existing record
    const currentRecord = editingIndex !== null ? records[editingIndex] : null;
    const hasChanges =
      currentRecord &&
      (currentRecord.health_insurance_carrier_master
        .health_insurance_carrier_name !== formData.insuranceCarrierName ||
        currentRecord.insurance_masters.insurance_name !==
          formData.selectedPlan);
    const isDuplicate =
      hasChanges &&
      records.some(
        (record) =>
          record.health_insurance_carrier_master
            .health_insurance_carrier_name === formData.insuranceCarrierName &&
          record.insurance_masters.insurance_name === formData.selectedPlan
      );

    if (isDuplicate) {
      Swal.fire({
        title: "Duplicate Entry",
        text: "This Record Is Already Present.",
        icon: "warning",
        timer: 2000,
        button: "OK",
      });
      return;
    }

    if (isValid) {
      if (editingIndex !== null) {
        try {
          setLoading(true);
          const recordId = records[editingIndex].id;
          const payload = {
            modelName: "clinic_insurances",
            id: recordId,
            inputData: {
              clinic_id: storedClinicId,
              insurance_name: formData.selectedPlan,
              insuranceCarrierName: formData.insuranceCarrierName,
              insurance_provider_id: selectedProviderId,
              health_insurance_carrier_id: selectedCarrierId,
              is_active: 1,
            },
          };

          const response = await postData(
            "/masters/createAndUpdateMaster",
            payload
          );
          getInsuranceDetails(storedClinicId);
          setLoading(false);
          if (response.code === 1) {
            Swal.fire({
              title: "Success",
              text: "Insurance Info Updated Successfully",
              icon: "success",
              timer: 2000,
              showConfirmButton: true,
            });

            resetForm();
            window.location.reload();
          }
        } catch (error) {
          setLoading(false);
          console.error("Error updating record:", error);
        }
      } else {
        try {
          setLoading(true);
          const payload = {
            modelName: "clinic_insurances",
            inputData: {
              clinic_id: storedClinicId,
              insurance_name: formData.selectedPlan,
              insuranceCarrierName: formData.insuranceCarrierName,
              insurance_provider_id: selectedProviderId,
              health_insurance_carrier_id: selectedCarrierId,
              is_active: 1,
            },
          };

          const response = await postData(
            "/masters/createAndUpdateMaster",
            payload
          );
          getInsuranceDetails(storedClinicId);
          setLoading(false);
          if (response.code === 1) {
            Swal.fire({
              title: "Success",
              text: "Insurance Info Added Successfully",
              icon: "success",
              timer: 2000,
              showConfirmButton: true,
            });

            resetForm();
            window.location.reload();
          }
        } catch (error) {
          console.error("Error adding record:", error);
          setLoading(false);
        }
      }
    } else {
      console.log("Form validation failed. Cannot submit.");
    }
  };

  const resetForm = () => {
    setFormData({
      insuranceCarrierName: "",
      selectedPlan: "",
    });
    setSelectedCarrierId(null);
    setSelectedProviderId(null);
    setEditingIndex(null);
    setErrors({});
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <section className="w-full py-2">
            <div className="container mx-auto px-4 ">
              <h1 className="font-fontPoppins md:text-2xl pb-7 text-center font-semibold xss:text-xs sm:text-lg leading-normal">
                Insurance Info
              </h1>
              <div className="bg-grayColorLight rounded-lg p-3 mt-3">
                <h2 className="font-fontPoppins text-xs font-semibold text-center text-gray-400"></h2>

                <div className="w-full">
                  <div className="w-full md:flex items-center gap-3">
                    <div className="w-full relative">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Insurance Carriers Accepted
                        <span className="text-red-500">*</span>
                      </label>

                      <CustomSelect
                        name="insuranceCarrierName"
                        options={carrierOptions.map((option) => ({
                          value: option.health_insurance_carrier_name,
                          label: option.health_insurance_carrier_name,
                        }))}
                        value={formData.insuranceCarrierName}
                        onChange={handleChange}
                        placeholder={"Select All Insurance Carriers Accepted"}
                      />
                      {errors.insuranceCarrierName && (
                        <p className="text-red-500 text-xs absolute">
                          {errors.insuranceCarrierName}
                        </p>
                      )}
                    </div>

                    <div className="w-full xss:mt-3 sm:mt-3 relative">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Insurance Plans Accepted
                        <span className="text-red-500">*</span>
                      </label>

                      {/* <CustomSelectHealth
                        name="selectedPlan"
                        options={insuranceOptions.map((option) => ({
                          value: option.insurance_name,
                          label: option.insurance_name,
                        }))}
                        value={formData.selectedPlan}
                        onChange={handleChange}
                        placeholder={"Select Health/Dental Insurance Plan"}
                      /> */}
                      <CustomSelectHealth
                        name="selectedPlan"
                        options={insuranceOptions.map((option) => ({
                          value: option.insurance_name,
                          label: option.insurance_name,
                        }))}
                        value={formData.selectedPlan}
                        onChange={handleChange}
                        placeholder={
                          "Select Health/Dental Insurance Plan Accepted"
                        }
                      />
                      {errors.selectedPlan && (
                        <p className="text-red-500 text-xs absolute">
                          {errors.selectedPlan}
                        </p>
                      )}
                    </div>

                    <div className="w-32 xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold text-transparent xss:hidden">
                        .
                      </label>
                      <div className="w-full">
                        <button
                          onClick={handleSubmit}
                          className="w-full h-9 bg-blueColor rounded-full font-fontPoppins text-xs font-normal text-white hover:opacity-85"
                        >
                          {editingIndex !== null ? "Update" : "Add"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full mt-3 overflow-auto">
                <table className="border-collapse table-auto w-full text-sm">
                  <thead>
                    <tr>
                      <th className="rounded-tl-lg font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                        Insurance Carriers Accepted
                      </th>
                      <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                        Insurance Plans Accepted
                      </th>
                      <th className="rounded-tr-lg font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {records.map((record, index) => (
                      <tr key={index}>
                        <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                          {record.health_insurance_carrier_master
                            ?.health_insurance_carrier_name || "N/A"}
                        </td>
                        <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                          {record.insurance_masters?.insurance_name || "N/A"}
                        </td>
                        <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                          <span
                            onClick={() => handleEdit(index)}
                            className="text-gray-400 mr-2 cursor-pointer hover:text-blueColor"
                          >
                            <i className="fa-solid fa-pencil"></i> Edit
                          </span>
                          {storedAccountType !== "Assistances" && (
                            <span
                              onClick={() => handleDelete(index)}
                              className="text-gray-400 mr-2 cursor-pointer hover:text-red-500"
                            >
                              <i className="fa-solid fa-trash"></i> Delete
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex mt-4 items-center gap-2">
                  <input
                    type="checkbox"
                    checked={insuranceisChecked}
                    onChange={(e) => setInsuranceisChecked(e.target.checked)}
                  />

                  <span className="font-fontPoppins text-xs font-semibold relative">
                    I have verified all the information and provide consent to
                    Samara Well to use and display this information accordingly.
                  </span>
                </div>
                {errorMessage && (
                  <p className="text-red-500  text-xs absolute">
                    {errorMessage}
                  </p>
                )}
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    </>
  );
};

export default InsuranceInfo;
