import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postData, putData } from "../../Networking/Api";
import Footer from "../Footer";
import Swal from "sweetalert2";
import Loader from "../Loader";
import CustomSelect from "../CustomSelect";
import ReCAPTCHA from "react-google-recaptcha";

//import ThirdPartyHeader from "./ThirdPartyHeader";
import { useDispatch, useSelector } from "react-redux";
import { loginPatient, selectPatient } from "../../redux/actions/userSlice";
//import ThirdPartyFooter from "./ThirdPartyFooter";
import ClinicBookingHeader from "./ClinicBookingHeader";
import ClinicBookingFooter from "./ClinicBookingFooter";
import { decrypt, encrypt } from "../CryptoUtil";
import Loader2 from "../Loader2";
import { logEvent } from "../../ga";

function ClinicPersonalAppointmentDetails() {
  const [loading, setLoading] = useState(false);
  const { clinicName, locations } = useParams();
  return (
    <>
      {loading && <Loader2 />}

      <>
        <ClinicBookingHeader
          clinicNameParam={clinicName}
          cityStreetNameParam={locations}
        />
        <PersonalDetails loading={loading} setLoading={setLoading} />

        <ClinicBookingFooter />
      </>
    </>
  );
}

function PersonalDetails({ loading, setLoading }) {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    zipcode: "",
    date_of_birth: "",
    reasonForVisit: "",
  });
  const [verified, setVerified] = useState(false);
  const [errors, setErrors] = useState({});
  const location = useLocation();

  const { selectedAppointment } = location.state || {};

  const [patientId, setPatientId] = useState("");
  const [practitionerId, setPractitionerId] = useState("");
  const [doctorDetail, setDoctorDetail] = useState("");
  const [viewpatientId, setViewPatientId] = useState("");

  const [doctorName, setDoctorName] = useState([]);
  const [appoinmentName, setAppoinmentName] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  const [Clinicname, setClinicName] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [address, setAddress] = useState("");
  const [manualDetailsEntered, setManualDetailsEntered] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [user, setUser] = useState();

  const [patientTypeName, setPatientTypeName] = useState("");
  const [captchaDisabled, setcaptchaDisabled] = useState(true);
  const patientData = useSelector(selectPatient);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);

  const {
    clinicName,
    patientType,
    insuranceType,
    appointmentType,
    type,
    locations,
    id,
  } = useParams();
  const appointmentTypeId = decrypt(decodeURIComponent(appointmentType));

  const [appointmentData, setAppointmentData] = useState("");
  const [notLogin, setNotLogin] = useState(false);
  const [captchError, setCaptchaError] = useState("");

  const Dispatch = useDispatch();

  const recaptchaRef = useRef(null);

  useEffect(() => {
    const isLoggedIn = patientData?.isLoggedIn || false;
    setIsFieldDisabled(isLoggedIn);
  }, [patientData]);
  useEffect(() => {
    const doctorsData = JSON.parse(localStorage.getItem("doctorsData")) || [];

    setDoctorName(doctorsData[0]);
  }, []);

  useEffect(() => {
    const savedUserData =
      JSON.parse(localStorage.getItem("userCreateInfo")) || {};

    if (!savedUserData.email) {
      setManualDetailsEntered(true);
    }

    if (Array.isArray(savedUserData)) {
      setManualDetailsEntered(false);

      setPatientId(savedUserData[0]?.id || null);
      setUserData(savedUserData[0] || {});
    } else {
      setPatientId(savedUserData.id || null);
      setUserData(savedUserData || {});
    }
    const appointDetails =
      JSON.parse(localStorage.getItem("selectedAppointment")) || {};

    setAppointmentData(appointDetails);
    setPractitionerId(appointDetails.practitioner_id);
  }, []);

  const today = new Date();

  useEffect(() => {
    if (practitionerId) {
      getPractitionerList(practitionerId);
    }
  }, [practitionerId]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // setLoading(true);
        const response = await postData("clinics/getClinicInfo", {
          clinicName: clinicName,
          locations: locations,
        });

        const data = response;
        console.log("data.clinicData", data.clinics.logo_image);
        setClinicName(data.clinics.name);
        setStreet(data.clinic_locations_data.street);
        setPhone(data.clinic_locations_data.phone);
        const { street, city, state, country, zipcode } =
          data.clinic_locations_data;
        const fullAddress = `${street}, ${city}, ${state}, ${country}, ${zipcode}`;
        setAddress(fullAddress);
      } catch (error) {
        // setLoading(false);
        console.log("Error fetching data:", error);
      }
    };

    fetchOptions();
  }, [clinicName]);

  const validateForm = () => {
    let isValid = true;
    const errors = {};
    if (!userData.first_name) {
      isValid = false;
      errors.first_name = "First Name Is Required";
    }
    if (!userData.last_name) errors.last_name = "Last Name Is Required";
    if (!userData.phone) {
      isValid = false;
      errors.phone = "Phone Number Is Required";
    }
    if (!userData.email) {
      isValid = false;
      errors.email = "Email Is Required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.email)) {
      isValid = false;
      errors.email = "Invalid Email Format.";
    }
    if (!userData.gender) {
      isValid = false;
      errors.gender = "Sex Is Required";
    }
    if (!userData.zipcode) {
      isValid = false;
      errors.zipcode = "Zipcode Is Required";
    } else if (!/^\d{5}(-\d{4})?$/.test(userData.zipcode)) {
      isValid = false;
      errors.zipcode = "Invalid Zip Code Format";
    }

    if (!userData.date_of_birth) {
      isValid = false;
      errors.date_of_birth = "Date Of Birth Is Required";
    }
    if (!userData.reasonForVisit) {
      isValid = false;
      errors.reasonForVisit = "Reason For Visit Is Required";
    }
    setErrors(errors);

    return isValid;
  };

  function formatphoneNumber(value) {
    const numbersOnly = value.replace(/[^\d+]/g, "");
    if (numbersOnly.length <= 3) {
      return numbersOnly;
    } else if (numbersOnly.length <= 6) {
      return `(${numbersOnly.slice(0, 3)})-${numbersOnly.slice(3)}`;
    } else {
      return `(${numbersOnly.slice(0, 3)}) ${numbersOnly.slice(
        3,
        6
      )}-${numbersOnly.slice(6)}`;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    if (name === "first_name" || name === "last_name") {
      let alphabeticValue = value.replace(/[^A-Za-z\s]/g, "");
      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: alphabeticValue,
      }));
      return;
    }

    if (name === "phone") {
      const formattedValue = formatphoneNumber(value);

      if (formattedValue !== value) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          [name]: formattedValue,
        }));
        return;
      }
    }
    if (name === "zipcode") {
      let numericValue = value.replace(/[^\d]/g, "");
      if (numericValue.length > 5) {
        numericValue = numericValue.slice(0, 5) + "-" + numericValue.slice(5);
      }

      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: numericValue,
      }));
      return;
    }

    if (name === "gender") {
      const characterValue = value.replace(/[^a-zA-Z]/g, "");
      if (characterValue !== value) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          [name]: characterValue,
        }));
        return;
      }
    }
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const getPractitionerList = async (practitionerId) => {
    try {
      // setLoading(true);
      const payload = {
        modelName: "practitioners",
        whereCondition: {
          id: practitionerId,
        },
      };
      const response = await postData("masters/getMasterList", payload);

      setDoctorDetail(response.data[0]);
      localStorage.setItem("doctorsData", JSON.stringify(response.data));
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error("Error fetching insurance options:", error);
    }
  };
  useEffect(() => {
    // setLoading(true);
    if (patientType === "newPatient" || patientType === "existingPatient") {
      setPatientTypeName(
        patientType === "newPatient" ? "New Patient" : "Existing Patient"
      );
    }

    const getAppointmentType = async () => {
      try {
        // setLoading(true);
        const payload = {
          modelName: "clinic_services",
          whereCondition: {
            id: appointmentTypeId, //appointmentType,
          },
        };
        const response = await postData("masters/getMasterList", payload);

        setAppoinmentName(response.data[0]);
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        console.error("Error fetching appointments:", error);
      }
    };
    getAppointmentType();
  }, [appointmentTypeId, patientType]);

  useEffect(() => {
    if (insuranceType === "other" || insuranceType === "noInsurance") {
      setInsuranceName(insuranceType === "other" ? "Other" : "No Insurance");
    } else {
      const getInsuranceType = async () => {
        try {
          // setLoading(true);
          const payload = {
            modelName: "clinic_insurances",
            whereCondition: {
              id: insuranceType,
            },
            relations: [
              {
                module: "insurance_masters",
              },
              {
                module: "health_insurance_carrier_master",
              },
            ],
          };
          const response = await postData("masters/getMasterList", payload);

          setInsuranceName(response.data[0]);
          // setLoading(false);
        } catch (error) {
          // setLoading(false);
          console.error("Error fetching insurance options:", error);
        }
      };
      getInsuranceType();
    }
  }, [insuranceType]);

  // useEffect(() => {
  //   const getClinicDetails = async (clinicId) => {
  //     try {
  //       setLoading(true);
  //       const payload = {
  //         modelName: "clinics",
  //         whereCondition: {
  //           id: doctorDetail.clinic_id,
  //         },
  //       };

  //       const response = await postData("masters/getMasterList", payload);

  //       const clinicData = response.data[0];

  //       const addressParts = [
  //         clinicData.street,
  //         clinicData.address,
  //         clinicData.city,
  //         clinicData.state,

  //         clinicData.zipcode,
  //       ];

  //       const formattedAddress = addressParts.filter(Boolean).join(", ");
  //       setAddress(formattedAddress);
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.error("Error fetching clinic details:", error);
  //     }
  //   };
  //   getClinicDetails();
  // }, [doctorDetail]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      if (patientId == null || patientId == "null") {
        Swal.fire({
          title: "Error",
          text: "Please Send OTP and verify email.",
          icon: "error",
          showConfirmButton: true,
        }).then(() => {
          return false;
        });
      } else {
        const dobFormatted = formatDate(userData.date_of_birth);

        try {
          setLoading(true);
          const payload2 = {
            modelName: "patients",
            id: patientId,
            inputData: {
              first_name: userData.first_name,
              last_name: userData.last_name,
              zipcode: userData.zipcode,
              gender: userData.gender,
              latitude: "00",
              longitude: "00",
              phone: userData.phone,
              date_of_birth: userData.date_of_birth,
            },
          };
          const response2 = await postData(
            "masters/createAndUpdateMaster",
            payload2
          );
          var patientInfo = {};
          patientInfo = response2.data[0];
          localStorage.setItem("userCreateInfo", JSON.stringify(patientInfo));
          Dispatch(
            loginPatient({
              isLoggedIn: true,
            })
          );

          const appointmentPayload = {
            patient_id: patientId,
            dob: dobFormatted,
            firstname: userData.first_name,
            lastname: userData.last_name,
            email: userData.email,
            zipcode: userData.zipcode,
            gender: userData.gender,
            phone: userData.phone,
            reasonForVisit: userData.reasonForVisit,
            patientType: patientType,
            insuranceType: insuranceType,
            appointmentType: appointmentTypeId,
            appointmentid: appointmentData.athena_appointment_id,
            inputData: {
              phone: userData.phone,
              is_patient_family: 0,
              patient_id: patientId,
              patient_family_id: 0,
              clinic_id: 1,
              practitioner_id: appointmentData.practitioner_id,
              price: 0,
              appointment_date_time: appointmentData.available_date_time,
              payment_details: "NA",
              status: "Booked",
              practitioner_calendars_id: appointmentData.id,
              insurance:
                insuranceType === "other"
                  ? "Other"
                  : insuranceType === "noInsurance"
                  ? "No Insurance"
                  : null,
              patient_type_id: patientType === "newPatient" ? 1 : 2,
              clinic_insurance_id:
                insuranceType === "other" || insuranceType === "noInsurance"
                  ? null
                  : insuranceType,
              clinic_service_id: appointmentTypeId,
              appointments_from: appointmentData.appointment_from,
            },
            practitionerCalendarsData: appointmentData,
          };
          console.log("payload", appointmentPayload);
          const response = await putData(
            "athenaapi/bookAppointments",
            appointmentPayload
          );

          if (response.code === 1) {
            setLoading(false);
            Swal.fire({
              title: "Success",
              text: "Appointment Booked Successfully",
              icon: "success",

              showConfirmButton: true,
            }).then(() => {
              setViewPatientId(response.data.id);
              const viewId = response.data.id;
              logEvent("Appointment", "Book", "Patient booked an appointment");
              navigate(`/clinicbookedappointmentDetails/${viewId}`);

              localStorage.setItem("patientId", patientId);
            });
          } else if (response.code === 0) {
            setLoading(false);
            Swal.fire({
              title: "Error",
              text: response.message,
              icon: "error",
              showConfirmButton: true,
            }).then(() => {});
            console.error("Failed to create appointment:", response.message);
          } else {
            setLoading(false);
            Swal.fire({
              title: "Error",
              text: response.message,
              icon: "error",
              showConfirmButton: true,
            }).then(() => {});
            console.error("Failed to create appointment:", response.message);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error creating appointment:", error);
        }
      }
    }
  };

  const onChange = (value) => {
    if (patientId == null || patientId === "null") {
      Swal.fire({
        title: "Error",
        text: "Please Send OTP and verify email Before Verifying Captcha.",
        icon: "error",
        showConfirmButton: true,
      }).then(() => {
        try {
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            console.log("ReCAPTCHA reset successfully.");
          } else {
            console.error("ReCAPTCHA reference is null");
          }
        } catch (error) {
          console.error("An error occurred while resetting ReCAPTCHA:", error);
        }
      }, 500);
      return;
    }

    if (validateForm()) {
      setVerified(true);
      setCaptchaError("");
    } else {
      setTimeout(() => {
        Swal.fire({
          title: "Error",
          text: "Please Fill Out All Required Fields Before Verifying.",
          icon: "error",
        }).then(() => {
          try {
            if (recaptchaRef.current) {
              recaptchaRef.current.reset();
              console.log("ReCAPTCHA reset successfully.");
            } else {
              console.error("ReCAPTCHA reference is null");
            }
          } catch (error) {
            console.error(
              "An error occurred while resetting ReCAPTCHA:",
              error
            );
          }
        });
      }, 500);
    }
  };

  const handleVerifyEmail = async (value) => {
    if (!value) {
      Swal.fire({
        title: "Error",
        text: "Email Is Required",
        icon: "error",
      });
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      Swal.fire({
        title: "Error",
        text: "Please Enter A Valid Email",
        icon: "error",
      });
      return;
    }
    try {
      const payload = {
        email: value,
      };
      const response = await postData("patients/sentOtpRequest", payload);

      if (response.code === 1) {
        Swal.fire({
          title: "Success",
          text: "OTP Sent Successfully",
          icon: "success",
        });
        setSendOtp(true);
        setIsEmailDisabled(true);
      }
      if (response.code === 0) {
        Swal.fire({
          title: "Error",
          text: "Email Already Exist",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };
  const handleVerifyOtp = async (otp, value) => {
    try {
      const payload1 = {
        email: value,
        otp: otp,
      };

      const response1 = await postData("patients/verifyOtp", payload1);

      setPatientId(response1.data.patientId);
      if (response1.code === 1) {
        Swal.fire({
          title: "Success",
          text: "OTP Verified Successfully",
          icon: "success",
        });
        setSendOtp(false);
        setManualDetailsEntered(false);
        setNotLogin(true);
      } else if (response1.code === 0) {
        Swal.fire({
          title: "Error",
          text: "Please Enter A Valid OTP",
          icon: "error",
        });
        return;
      } else {
        // Handle unexpected responses
        Swal.fire({
          title: "Error",
          text:
            response1.message || "Unexpected error occurred. Please try again.",
          icon: "error",
        });
        return;
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  const handleOtpChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setOtp(value);
    }
  };
  return (
    <>
      <section className="w-full mt-4">
        <div className="container mx-auto px-4">
          <span
            onClick={() => {
              const encryptedappointmentTypeId = encrypt(
                appointmentTypeId.toString()
              );
              const urlSafeAppointmentTypeId = encodeURIComponent(
                encryptedappointmentTypeId
              );
              navigate(
                `/clinicaddbookingdetails/${clinicName}/${locations}/${patientType}/${insuranceType}/${urlSafeAppointmentTypeId}`
              );
              localStorage.removeItem("selectedSlot");
              localStorage.removeItem("selectedAppointment");
            }}
            className="font-fontPoppins text-sm font-semibold cursor-pointer"
          >
            <i className="fa-solid fa-long-arrow-left text-gray-300"></i>{" "}
            Personal & Appointment Details
          </span>

          <div className="w-full my-4 md:flex md:justify-between md:gap-4">
            <div className="w-full bg-gray-100 px-4 py-5 rounded-lg">
              <h1 className="font-fontPoppins text-base font-semibold text-center">
                Please Enter Your Exact Details
              </h1>

              <div className="w-full mt-3">
                <div className="w-full mt-2 md:flex md:justify-between md:gap-3">
                  <div className="w-full">
                    <label className="w-full font-fontPoppins text-base font-medium">
                      First Name<span className="text-red-500">*</span>
                    </label>

                    <input
                      type="text"
                      placeholder="Enter First Name"
                      name="first_name"
                      value={userData.first_name}
                      onChange={handleChange}
                      className={`w-full h-11 xss:mt-3 sm:mt-3 md:mt-0 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200  `}
                    />
                    {errors.first_name && (
                      <p className="text-red-500 text-xs">
                        {errors.first_name}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <label className="w-full font-fontPoppins text-base font-medium">
                      Last Name<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      name="last_name"
                      value={userData.last_name}
                      onChange={handleChange}
                      className={`w-full h-11 xss:mt-3 sm:mt-3 md:mt-0 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200 `}
                    />
                    {errors.last_name && (
                      <p className="text-red-500 text-xs">{errors.last_name}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-full mt-3 md:flex md:justify-between md:gap-3">
                <div className="w-full">
                  <label className="w-full font-fontPoppins text-base font-medium">
                    Email<span className="text-red-500">*</span>
                  </label>
                  <input
                    disabled={isEmailDisabled || isFieldDisabled}
                    type="text"
                    placeholder="Enter Your Email"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                    className={`w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200 ${
                      isEmailDisabled || isFieldDisabled
                        ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                        : ""
                    }`}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs">{errors.email}</p>
                  )}
                  {manualDetailsEntered &&
                    (sendOtp ? (
                      <div className="mt-3">
                        <input
                          type="text"
                          placeholder="Enter Your OTP"
                          name="otp"
                          value={otp}
                          onChange={handleOtpChange}
                          className="w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                        />
                        <button
                          className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-fontPoppins py-1 px-3 rounded-full focus:outline-none"
                          onClick={() =>
                            handleVerifyOtp(
                              otp,
                              userData.email,
                              userData.first_name,
                              userData.last_name
                            )
                          }
                        >
                          Verify OTP
                        </button>
                      </div>
                    ) : (
                      <button
                        className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-fontPoppins py-2 px-4 rounded-full focus:outline-none"
                        onClick={() => handleVerifyEmail(userData.email)}
                      >
                        Verify
                      </button>
                    ))}
                </div>
                <div className="w-full xss:mt-3 sm:mt-3 md:mt-0">
                  <label className="w-full font-fontPoppins text-base font-medium">
                    Contact<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Contact Number"
                    name="phone"
                    value={userData.phone}
                    maxLength={14}
                    onChange={handleChange}
                    className="w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-xs">{errors.phone}</p>
                  )}
                </div>
              </div>

              <div className="w-full mt-3 md:flex md:justify-between md:gap-3">
                <div className="w-full">
                  <label className="w-full font-fontPoppins text-base font-medium">
                    Sex<span className="text-red-500">*</span>
                  </label>
                  <CustomSelect
                    options={[
                      { value: "male", label: "Male" },
                      { value: "female", label: "Female" },
                    ]}
                    name="gender"
                    onChange={(e) => handleChange(e)}
                    value={userData.gender}
                    placeholder="Select Your Sex"
                  />

                  {errors.gender && (
                    <p className="text-red-500 text-xs">{errors.gender}</p>
                  )}
                </div>
                <div className="w-full xss:mt-3 sm:mt-3 md:mt-0">
                  <label className="w-full font-fontPoppins text-base font-medium">
                    Zip code<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Zip Code"
                    name="zipcode"
                    value={userData.zipcode}
                    maxLength={10}
                    onChange={handleChange}
                    className="w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                  />
                  {errors.zipcode && (
                    <p className="text-red-500 text-xs">{errors.zipcode}</p>
                  )}
                </div>
              </div>

              <div className="w-full mt-3">
                <label className="w-full font-fontPoppins text-base font-medium">
                  Date of Birth<span className="text-red-500">*</span>
                </label>
                <div className="w-full relative">
                  <span className="z-10 absolute left-4 top-1/2 -translate-y-2/4">
                    <i className="fa-solid fa-calendar-alt text-blueColor"></i>
                  </span>
                  <ReactDatePicker
                    selected={
                      userData.date_of_birth
                        ? new Date(userData.date_of_birth)
                        : null
                    }
                    onChange={(date) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        date_of_birth: date,
                      }))
                    }
                    placeholderText="Enter DOB"
                    className="w-full h-11 indent-8 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={today}
                  />

                  {errors.date_of_birth && (
                    <p className="text-red-500 text-xs">
                      {errors.date_of_birth}
                    </p>
                  )}
                </div>
              </div>

              <div className="w-full mt-3">
                <label className="w-full font-fontPoppins text-base font-medium">
                  Reason For Visit<span className="text-red-500">*</span>
                  <span className="text-f11 text-gray-300">
                    {" "}
                    (In 140 Characters)
                  </span>
                </label>
                <div className="w-full">
                  <textarea
                    placeholder="Enter Reason"
                    name="reasonForVisit"
                    value={userData.reasonForVisit}
                    onChange={handleChange}
                    maxLength={140}
                    className="w-full h-24 bg-white rounded-md font-fontPoppins text-sm font-medium text-black shadow-sm px-3 py-2 focus:outline-none focus:ring focus:ring-gray-200"
                  ></textarea>
                </div>
                {errors.reasonForVisit && (
                  <p className="text-red-500 text-xs">
                    {errors.reasonForVisit}
                  </p>
                )}
              </div>

              <div className="w-full m-3  ">
                <ReCAPTCHA
                  sitekey="6LeQUPYpAAAAAB_WZcNRTsEhk-zC4ktXWLLO16Rb"
                  onChange={onChange}
                  ref={recaptchaRef}
                />
              </div>
              {captchError && (
                <p className="text-red-500 text-xs">{captchError}</p>
              )}
            </div>

            <div className="w-full bg-gray-100 px-4 py-5 rounded-xl xss:mt-3 sm:mt-3 md:mt-0">
              <h1 className="font-fontPoppins text-base font-semibold text-center">
                Appointment Details:
              </h1>

              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                  Appointment
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {patientTypeName} | {appoinmentName.services} <br />
                  {appointmentData.available_date_time_new}
                </p>
              </div>
              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                  Appointment Duration
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {appoinmentName.duration} minutes.
                </p>
              </div>

              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                  Address
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {address && address}
                  <br />
                  <p>{phone && phone}</p>
                </p>
              </div>

              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                  Consulting Practitioner
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {doctorDetail?.f_name
                    ? `${doctorDetail?.f_name} ${doctorDetail?.l_name}`
                    : `${doctorName?.f_name} ${doctorName?.l_name}`}
                  <span className="text-gray-700">
                    {` (${
                      doctorDetail?.taxonomy_description?.trim() ||
                      doctorName?.taxonomy_description?.trim()
                    })`}
                  </span>
                </p>
              </div>

              {(insuranceType === "other" ||
                insuranceType === "noInsurance") && (
                <div className="w-full mt-4">
                  <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                    Insurance Type
                  </h2>
                  <p className="font-fontPoppins text-sm font-semibold text-black">
                    {insuranceName}
                  </p>
                </div>
              )}
              {insuranceType !== "other" && insuranceType !== "noInsurance" && (
                <div className="w-full mt-4">
                  <h2 className="font-fontPoppins text-sm font-medium text-black-100">
                    Insurance Carrier
                  </h2>
                  <p className="font-fontPoppins text-sm font-semibold text-black">
                    {
                      insuranceName?.health_insurance_carrier_master
                        ?.health_insurance_carrier_name
                    }
                  </p>
                </div>
              )}
              {insuranceType !== "other" && insuranceType !== "noInsurance" && (
                <div className="w-full mt-4">
                  <h2 className="font-fontPoppins text-xs font-semibold text-gray-800">
                    Insurance Plan
                  </h2>
                  <p className="font-fontPoppins text-sm font-semibold text-black">
                    {insuranceName?.insurance_masters?.insurance_name}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="w-full mt-4 text-center">
            <button
              onClick={handleSubmit}
              className={`w-56 h-10 font-fontPoppins bg-blueColor text-xs rounded-full text-white font-semibold hover:opacity-85 ${
                !verified ? "cursor-not-allowed" : ""
              }`}
              disabled={!verified}
            >
              Book Your Appointment{" "}
              <i className="fa-solid fa-arrow-right-long"></i>
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default ClinicPersonalAppointmentDetails;
