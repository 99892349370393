import React, { useEffect, useState } from "react";
import PatientsLandingPageHeader from "./PatientsLandingPageHeader";
import PatientsLandingPageFooter from "./PatientsLandingPageFooter";
import { postData, apiUrl } from "../Networking/Api";
import { useParams } from "react-router-dom";
import ProviderLandingPageFooter from "./ProviderLandingPageFooter/ProviderLandingPageFooter";
import Loader2 from "./Loader2";

const Services = () => {
  const { serviceId, tab } = useParams();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const payload = {
          modelName: "services_details",
          whereCondition: {
            is_active: 1,
            service_id: serviceId,
          },
          pagination: {
            page: 1,
            pageSize: 100,
          },
        };
        console.log("sections", sections);

        const response = await postData("masters/getMasterList", payload);
        console.log("API response:", response);
        setLoading(false);
        if (response.status === 200 && response.data) {
          const serviceIdNumber = parseInt(serviceId, 10);

          const filteredSections = response.data.filter(
            (section) => section.service_id === serviceIdNumber
          );

          const sortedSections = filteredSections.sort(
            (a, b) => a.sequences - b.sequences
          );
          setSections(sortedSections);
        } else {
          throw new Error(response.message || "Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [serviceId]);

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  return (
    <>
      {loading && <Loader2 />}
      <>
        <div className="fixed top-0 left-0 w-full z-50 bg-white shadow-md">
          <PatientsLandingPageHeader />
        </div>
        <div className="pt-20 px-4  md:mt-0 md:px-8 lg:px-16">
          <div className="text-center">
            <h2 className="text-black font-bold text-4xl ">
              {tab
                ? tab.charAt(0).toUpperCase() + tab.slice(1)
                : "Default Title"}
            </h2>
          </div>

          {sections.map((section, index) => (
            <section
              key={section.id}
              className="m-6 mb-6 sm:m-0 sm:mb-0 flex flex-wrap md:flex-nowrap"
            >
              {index % 2 === 0 ? (
                <>
                  {section.image && (
                    <div
                      className="w-full md:w-2/5 p-4 md:mr-8 mb-8"
                      style={{
                        backgroundImage: `url(${apiUrl}${section.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "50vh",
                        marginBottom: "0.5rem",
                      }}
                    ></div>
                  )}

                  <div
                    className={`w-full md:w-3/5 m-8 p-8 relative ${
                      !section.image ? "ml-auto" : ""
                    }`}
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <span className="absolute top-0 left-8 text-xl font-semibold">
                      {String(index + 1).padStart(2, "0")}
                    </span>
                    <h2
                      className="text-4xl mt-4 font-normal mb-4"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      {section.title}
                    </h2>
                    <div
                      className="text-xl  mb-8 text-left"
                      style={{
                        fontFamily: "sans-serif",
                      }}
                      dangerouslySetInnerHTML={{ __html: section.description }}
                    ></div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="w-full md:w-3/5 m-8 p-8  relative "
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <span className="absolute top-0 left-8 text-xl font-semibold">
                      {String(index + 1).padStart(2, "0")}
                    </span>
                    <h2
                      className="text-4xl mt-4 font-normal mb-4 text-left"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      {section.title}
                    </h2>
                    <div
                      className="text-xl mb-8  text-left"
                      style={{ fontFamily: "sans-serif" }}
                      dangerouslySetInnerHTML={{ __html: section.description }}
                    ></div>
                  </div>
                  {section.image && (
                    <div
                      className="w-full md:w-2/5 p-4 md:mr-8"
                      style={{
                        backgroundImage: `url(${apiUrl}${section.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "50vh",
                        marginBottom: "0.5rem",
                      }}
                    ></div>
                  )}
                </>
              )}
            </section>
          ))}
          {/* {sections.map((section, index) => (
            <section
              key={section.id}
              className="py-10 m-6 flex flex-wrap md:flex-nowrap items-center"
            >
              {index % 2 === 0 ? (
                <div className="flex flex-wrap w-full">
                  {section.image && (
                    <div
                      className="w-full md:w-2/5 p-4 md:mr-8"
                      style={{
                        backgroundImage: `url(${apiUrl}${section.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "50vh",
                        marginBottom: "0.5rem", // Ensure consistent bottom spacing
                      }}
                    ></div>
                  )}
                  <div
                    className={`w-full md:w-3/5 p-8 ${
                      !section.image ? "ml-auto" : ""
                    }`}
                    style={{ marginBottom: "0.5rem" }} // Ensure consistent bottom spacing
                  >
                    <span className="absolute top-0 left-8 text-xl font-semibold">
                      {String(index + 1).padStart(2, "0")}
                    </span>
                    <h2
                      className="text-4xl mt-4 font-normal mb-4"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      {section.title}
                    </h2>
                    <div
                      className="text-xl mb-8 text-left"
                      style={{ fontFamily: "sans-serif" }}
                      dangerouslySetInnerHTML={{ __html: section.description }}
                    ></div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap w-full">
                  <div
                    className="w-full md:w-3/5 p-8"
                    style={{ marginBottom: "0.5rem" }} // Ensure consistent bottom spacing
                  >
                    <span className="absolute top-0 left-8 text-xl font-semibold">
                      {String(index + 1).padStart(2, "0")}
                    </span>
                    <h2
                      className="text-4xl mt-4 font-normal mb-4 text-left"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      {section.title}
                    </h2>
                    <div
                      className="text-xl mb-8 text-left"
                      style={{ fontFamily: "sans-serif" }}
                      dangerouslySetInnerHTML={{ __html: section.description }}
                    ></div>
                  </div>
                  {section.image && (
                    <div
                      className="w-full md:w-2/5 p-4 md:mr-8"
                      style={{
                        backgroundImage: `url(${apiUrl}${section.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "50vh",
                        marginBottom: "0.5rem", // Ensure consistent bottom spacing
                      }}
                    ></div>
                  )}
                </div>
              )}
            </section>
          ))} */}
        </div>

        <ProviderLandingPageFooter />
      </>
    </>
  );
};

export default Services;
