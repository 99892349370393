import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { postData } from "../../Networking/Api";
import Header from "../Header";
import ThirdPartyHeader from "./ThirdPartyHeader";
import Swal from "sweetalert2";
import ThirdPartyFooter from "./ThirdPartyFooter";
import Loader from "../Loader";

function BookAppointmentDetails() {
  const pathlocation = useLocation();
  const showFirstHeader =
    pathlocation.pathname === "/bookappointmentDetails/:viewId";
  const [logoImage, setLogoImage] = useState("");

  useEffect(() => {
    const getRescheduleAppointmentData = async (viewId) => {
      try {
        const payload = {
          modelName: "patient_appointments",
          whereCondition: {
            id: viewId,
          },
          relations: [
            {
              module: "practitioners",
            },
            {
              module: "appointment_type",
            },
            {
              module: "practitioner_calendars",
            },
            {
              module: "clinics",
            },
          ],
        };
        const response = await postData("/masters/getMasterList", payload);

        const logoUrl = response.data[0].clinic.logo_image;

        setLogoImage(logoUrl);
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };
    getRescheduleAppointmentData();
  }, []);
  return (
    <>
      <ThirdPartyHeader logoImage={logoImage} />
      <YourBookingDetails />

      <ThirdPartyFooter />
    </>
  );
}

function YourBookingDetails() {
  const location = useLocation();
  const { patientType, insuranceType, appointmentType, type, id, viewId } =
    useParams();

  const navigate = useNavigate();
  const [viewDetails, setViewDetails] = useState("");
  const [appoinmentName, setAppoinmentName] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  const [address, setAddress] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState([]);
  const [patientTypeName, setPatientTypeName] = useState("");
  const [patientId, setPatientId] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [patientTypeId, setPatientTypeId] = useState("");
  const [clinicinsuranceId, setClinicInsuranceId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [carrierName, setCarrierName] = useState("");
  const [insurancePlanName, setInsurancePlanName] = useState("");
  const [patientAppointmentId, setPatientAppointmentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [insurance, setInsurance] = useState("");
  useEffect(() => {
    getAppointmentDetails();
  }, [viewId]);
  const getAppointmentDetails = async () => {
    try {
      setLoading(true);
      const payload = {
        modelName: "patient_appointments",
        whereCondition: {
          id: viewId,
        },
        relations: [
          {
            module: "clinics",
          },
          {
            module: "patients",
          },
          {
            module: "practitioners",
          },
          {
            module: "appointment_type",
          },
        ],
      };

      const apiResponse = await postData("masters/getMasterList", payload);
      setLoading(false);
      if (apiResponse.code == 1) {
        setLoading(false);
        setViewDetails(apiResponse.data[0]);

        setPatientId(apiResponse.data[0].patient_id);
        setPatientAppointmentId(apiResponse.data[0].id);
        setPatientTypeId(apiResponse.data[0].patient_type_id);
        setClinicInsuranceId(apiResponse.data[0].clinic_insurance_id);
        setServiceId(apiResponse.data[0].clinic_service_id);
        setInsurance(apiResponse.data[0].insurance);
      } else {
        setLoading(false);
        return null;
      }
    } catch (error) {
      console.error("Error searching provider:", error);
      return null;
    }
  };
  useEffect(() => {
    if (clinicinsuranceId) {
      getClinicInsuranceData(clinicinsuranceId);
    }
  }, [clinicinsuranceId]);
  useEffect(() => {
    if (serviceId) {
      getClinicServiceData(serviceId);
    }
  }, [serviceId]);

  const getClinicInsuranceData = async (clinicinsuranceId) => {
    try {
      const payload = {
        modelName: "clinic_insurances",
        whereCondition: {
          id: clinicinsuranceId,
        },
        relations: [
          {
            module: "insurance_masters",
          },
          {
            module: "health_insurance_carrier_master",
          },
        ],
      };
      const response = await postData("/masters/getMasterList", payload);

      setCarrierName(
        response.data[0].health_insurance_carrier_master
          .health_insurance_carrier_name
      );
      setInsurancePlanName(response.data[0].insurance_master.insurance_name);
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };
  const getClinicServiceData = async (serviceId) => {
    try {
      const payload = {
        modelName: "clinic_services",
        whereCondition: {
          id: serviceId,
        },
      };
      const response = await postData("/masters/getMasterList", payload);

      setAppoinmentName(response.data[0]);
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };

  useEffect(() => {
    const selectedAppointmentData = JSON.parse(
      localStorage.getItem("selectedAppointment")
    );
    if (selectedAppointmentData) {
      setSelectedAppointment(selectedAppointmentData);
    }
  }, []);

  useEffect(() => {
    const getClinicDetails = async () => {
      try {
        const payload = {
          modelName: "clinics",
          whereCondition: {
            id: viewDetails.clinic_id,
          },
        };
        const response = await postData("masters/getMasterList", payload);

        const clinicData = response.data[0];
        const addressParts = [
          clinicData.street,
          clinicData.address,
          clinicData.city,
          clinicData.state,

          clinicData.zipcode,
        ];

        const formattedAddress = addressParts.filter(Boolean).join(", ");
        setAddress(formattedAddress);
      } catch (error) {
        console.error("Error fetching clinic details:", error);
      }
    };
    getClinicDetails();
  }, [viewDetails]);

  function formatAppointmentDate(dateString) {
    const date = new Date(dateString);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayName = days[date.getUTCDay()];
    const day = date.getUTCDate();
    const monthName = months[date.getUTCMonth()];
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    return `${dayName}, ${day} ${monthName} ${formattedHours}:${formattedMinutes}${ampm}`;
  }
  const handleBookAnotherAppointment = () => {
    localStorage.removeItem("selectedSlot");
    localStorage.removeItem("selectedOptions");
    localStorage.removeItem("selectedDate");
    localStorage.removeItem("selectedDoctor");
    localStorage.removeItem("options");
    localStorage.removeItem("doctorsData");
    localStorage.removeItem("optionsId");

    navigate(`/patientLanding`);
  };

  const hanldeAddToCalender = async (patientId) => {
    try {
      const payload = {
        appointmentId: viewId,
        patientId: patientId,
      };
      const apiResponse = await postData(
        "googlecalendar/createEventPatient",
        payload
      );

      if (apiResponse.code === 1) {
        Swal.fire({
          title: "Success",
          text: apiResponse.message,
          icon: "success",
        });
        setIsButtonDisabled(true);
      } else {
        Swal.fire({
          title: "Error",
          text: apiResponse.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error :", error);
      return null;
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section>
          <div className="container mx-auto px-4 py-4">
            <h1 className="font-fontPoppins text-3xl xss:text-xl sm:text-xl font-bold text-center mb-7">
              Your Appointment Is Booked!
            </h1>

            <div className="w-3/5 xss:w-full sm:w-full mx-auto relative bg-purple-100 px-4 py-5 shadow-md rounded-xl xss:mt-3 sm:mt-3 md:mt-0">
              <div className="w-full">
                <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                  Patient's Name
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {viewDetails &&
                    viewDetails.patient &&
                    viewDetails.patient.first_name}{" "}
                  {viewDetails &&
                    viewDetails.patient &&
                    viewDetails.patient.last_name}
                </p>
              </div>

              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                  Appointment
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {patientTypeId === 2 ? "Existing Patient" : "New Patient"} |{" "}
                  {appoinmentName.services} <br />
                  {formatAppointmentDate(viewDetails.appointment_date_time)}
                </p>
              </div>
              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                  Appointment Duration
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {appoinmentName.duration} minutes.
                </p>
              </div>

              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                  Address
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {address}
                </p>
              </div>

              <div className="w-full mt-4">
                <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                  Consulting Doctor
                </h2>
                <p className="font-fontPoppins text-sm font-semibold text-black">
                  {viewDetails &&
                    viewDetails.practitioner &&
                    viewDetails.practitioner.f_name}{" "}
                  {viewDetails &&
                    viewDetails.practitioner &&
                    viewDetails.practitioner.l_name}
                </p>
              </div>

              {clinicinsuranceId === null ? (
                <div className="w-full mt-4">
                  <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                    Insurance Type
                  </h2>
                  <p className="font-fontPoppins text-sm font-semibold text-black">
                    {insurance}
                  </p>
                </div>
              ) : (
                <>
                  <div className="w-full mt-4">
                    <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                      Insurance Carrier
                    </h2>
                    <p className="font-fontPoppins text-sm font-semibold text-black">
                      {carrierName}
                    </p>
                  </div>
                  <div className="w-full mt-4">
                    <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                      Insurance Plan
                    </h2>
                    <p className="font-fontPoppins text-sm font-semibold text-black">
                      {insurancePlanName}
                    </p>
                  </div>
                </>
              )}

              <div className="w-full mt-4 bg-yellow-100 px-4 py-2 rounded-lg shadow-sm">
                <h4 className="font-fontPoppins text-sm font-medium text-orange-400">
                  Note
                </h4>
                <p className="font-fontPoppins text-xs font-normal">
                  Please arrive 15 minutes prior to your scheduled appointment
                  time to complete any necessary paperwork. Remember to bring
                  your insurance if applied/available card and a valid photo ID.
                </p>
              </div>
            </div>

            <div className="w-3/5 xss:w-full sm:w-full mx-auto mt-4 xss:text-center md:flex md:justify-between">
              <button
                disabled={isButtonDisabled}
                onClick={() => hanldeAddToCalender(patientId)}
                className={`h-12 font-fontPoppins text-sm rounded-full text-white font-semibold px-4 xss:mb-2 ${
                  isButtonDisabled
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
              >
                <i className="fa-solid fa-calendar-plus fa-lg"></i> Add To
                Calendar
              </button>

              <button
                onClick={() => navigate(`/thirdpartyreschedule/${viewId}`)}
                className="h-12 font-fontPoppins hover:bg-gray-300 text-sm rounded-full text-gray-600 font-semibold hover:opacity-85 px-4 xss:mb-2"
              >
                <i className="fa-solid fa-rotate-right fa-lg"></i> Reschedule{" "}
              </button>

              <button
                onClick={handleBookAnotherAppointment}
                className="h-12 font-fontPoppins hover:bg-gray-300 text-sm rounded-full text-gray-600 font-semibold hover:opacity-85 px-4 xss:mb-2"
              >
                Book Another Appointment{" "}
                <i className="fa-solid fa-arrow-right-long fa-lg"></i>
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default BookAppointmentDetails;
