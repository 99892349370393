import Header from "../Header";
import Footer from "../Footer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { loginPatient, selectPatient } from "../../redux/actions/userSlice";
import { postData, apiUrl } from "../../Networking/Api";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import Swal from "sweetalert2";
import CustomSelect from "../CustomSelect";

function BookAnAppointment() {
  const [selectedOptions, setSelectedOptions] = useState({
    patientType: "",
    insuranceType: "",
    appointmentType: "",
  });

  useEffect(() => {
    // Load saved options from localStorage on component mount
    const savedOptions = localStorage.getItem("selectedOptions");
    if (savedOptions) {
      setSelectedOptions(JSON.parse(savedOptions));
    }
  }, []);

  return (
    <>
      <Header />
      <div className="pt-16">
        <AppointmentSection selectedOptions={selectedOptions} />
        <Footer />
      </div>
    </>
  );
}

function CheckIcon() {
  return (
    <span>
      <i className="fa-solid fa-check-circle text-sky-400"></i>
    </span>
  );
}

const activeBtn = {
  color: "#ffffff",
  backgroundColor: "#000000",
};
const today = new Date();
const Modal = ({ isOpen, onClose, options, children }) => {
  if (!isOpen) return null;

  return (
    //     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    //       <div className="bg-white rounded-lg p-6 shadow-md w-full max-w-lg">
    //         {/* <button onClick={onClose} className="text-gray-500 text-lg absolute top-2 right-2">
    //           &times;
    //         </button> */}
    // <div className="absolute top-2 right-2">
    //           <svg
    //             onClick={onClose}
    //             xmlns="http://www.w3.org/2000/svg"
    //             className="h-6 w-6 cursor-pointer text-gray-500"
    //             fill="none"
    //             viewBox="0 0 24 24"
    //             stroke="currentColor"
    //             strokeWidth={2}
    //           >
    //             <path
    //               strokeLinecap="round"
    //               strokeLinejoin="round"
    //               d="M6 18L18 6M6 6l12 12"
    //             />
    //           </svg>
    //         </div>
    //         {children}
    //       </div>
    //     </div>
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center mt-3">
      <div className="relative bg-white rounded-lg p-6 shadow-md w-full max-w-lg">
        <div className="absolute top-2 right-3">
          <svg
            onClick={onClose}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
};
function AppointmentSection() {
  const imgW = "40px";
  const googleIconW = "24px";
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const location = useLocation();
  const { state } = location || {};
  const { symptom } = state || {};
  const [doctors, setDoctors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    patientType: "",
    insuranceType: "",
    appointmentType: "",
    chooseCarrierAndPlan: "",
  });
  const [isSelect, setIsSelect] = useState(
    selectedOptions.insuranceType === "Choose Carrier and Plan"
  );
  const [user, setUser] = useState();
  const patientData = useSelector(selectPatient);
  // console.log("Patient User:", patientData);
  const { type, name } = useParams();

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedDate, setSelectedDate] = useState(null);
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(null);

  useEffect(() => {
    // Load saved appointment and slot from localStorage on component mount
    const savedAppointment = localStorage.getItem("selectedAppointment");
    const savedSlot = localStorage.getItem("selectedSlot");
    const savedDate = localStorage.getItem("selectedDate");
    const savedDoctor = localStorage.getItem("selectedDoctor");
    if (savedAppointment && savedSlot) {
      setSelectedAppointment(JSON.parse(savedAppointment));
      setSelectedSlot(JSON.parse(savedSlot));
    }
    if (savedDate) {
      setSelectedDate(new Date(savedDate));
    }
    if (savedDoctor) {
      setSelectedDoctor(JSON.parse(savedDoctor));
    }
  }, []);

  useEffect(() => {
    setUser(patientData);
  }, [patientData]);

  useEffect(() => {
    getProviderTimeSlot();
  }, [selectedDate, selectedOptions]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getProviderTimeSlot = async () => {
    try {
      const currentTimeZone = localStorage.getItem("currentTimeZone");
      //console.log("currentTimeZone-11---", currentTimeZone);
      const payload = {
        searchData: name,
        type: type,
        date: formatDate(selectedDate),
        patientType: selectedOptions.patientType,
        insuranceType: selectedOptions.insuranceType,
        appointmentType: selectedOptions.appointmentType,
        timeZone: currentTimeZone,
      };

      const apiResponse = await postData("patients/SearchProvider", payload);
      if (apiResponse.code === 1) {
        setDoctors(apiResponse.data);
        return apiResponse.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error searching provider:", error);
      return null;
    }
  };

  useEffect(() => {
    const availableDoctorsList = doctors.filter(
      (doctor) => doctor.practitionerCalendarsData.length > 0
    );
    setAvailableDoctors(availableDoctorsList);
  }, [selectedDate, doctors]);

  const handleDateChange = (date) => {
    localStorage.removeItem("selectedSlot");
    localStorage.removeItem("selectedDoctor"); // Remove the saved doctor
    setSelectedSlot(null);
    setSelectedDoctor(null); // Reset the selected doctor
    setSelectedDate(date);
    localStorage.setItem("selectedDate", date.toISOString());
  };

  const handleSlotClick = (doctor, practitionerCalendarsData, slot) => {
    const clinic_id = doctor.clinic_id;
    // console.log('slot---', slot);
    // console.log("practitionerCalendarsData--------", practitionerCalendarsData);
    const selectedSlotData = practitionerCalendarsData.find(
      (data) => data.available_date_time_new === slot.available_date_time_new
    );
    // console.log("Selected Slot Data:", doctor);
    // console.log("Selected doctorId:", doctorId);
    // console.log("Selected selectedSlotData:", selectedSlotData);

    setSelectedAppointment(selectedSlotData);
    setSelectedSlot(slot);
    setSelectedClinic(clinic_id); // Set the selected clinic_id
    setSelectedDoctor(selectedSlotData.practitioner_id); // Set the selected doctor

    localStorage.setItem(
      "selectedAppointment",
      JSON.stringify(selectedSlotData)
    );
    localStorage.setItem("selectedSlot", JSON.stringify(slot));
    localStorage.setItem("selectedDoctor", selectedSlotData.practitioner_id); // Save the selected doctor
    setIsModalOpen(true);
  };
  const handleClickMaualDetails = () => {
    if (!selectedSlot) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Select A Patient Details.!",
      });
      return;
    }

    const { patientType, insuranceType, appointmentType } = selectedOptions;
    // const { chooseCarrierAndPlanId } = selectedOptionsId;
    const {
      patientTypeId,
      insuranceTypeId,
      appointmentTypeId,
      chooseCarrierAndPlanId,
    } = selectedOptionsId;
    if (
      !patientType ||
      !insuranceType ||
      !appointmentType ||
      !chooseCarrierAndPlanId
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select practitioner Details!",
      });
      return;
    }
    const cleanedPatientType = patientType.replace(/\s/g, "");
    const cleanedInsuranceType = insuranceType.replace(/\s/g, "");
    const cleanedAppointmentType = appointmentType.replace(/\s/g, "");
    // const url = `/personalAppointmentDetails/${type}/${name}/${cleanedPatientType}/${cleanedInsuranceType}/${cleanedAppointmentType}/${chooseCarrierAndPlanId}`;
    const url = `/personalAppointmentDetails/${type}/${name}/${patientTypeId}/${chooseCarrierAndPlanId}/${appointmentTypeId}`;
    navigate(url, { state: selectedAppointment });
  };
  const Login = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      try {
        const userInfoResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
        const userInfo = userInfoResponse.data;
        const email = userInfo.email;
        const l_name = userInfo.family_name;
        const f_name = userInfo.given_name;

        const checkEmailExists = async (email) => {
          const payload = {
            modelName: "patients",
            whereCondition: { email: email },
          };

          try {
            const apiResponse = await postData(
              "masters/getMasterList",
              payload
            );
            // console.log("API Response for email check:", apiResponse.data);
            if (apiResponse.code == 1) {
              localStorage.setItem(
                "userCreateInfo",
                JSON.stringify(apiResponse.data)
              );
              localStorage.setItem("accountType", "Patient");
              Dispatch(
                loginPatient({
                  // user : payload,
                  isLoggedIn: true,
                })
              );
              const { patientType, insuranceType, appointmentType } =
                selectedOptions;
              // const { chooseCarrierAndPlanId } = selectedOptionsId;
              const cleanedPatientType = patientType.replace(/\s/g, "");
              const cleanedInsuranceType = insuranceType.replace(/\s/g, "");
              const cleanedAppointmentType = appointmentType.replace(/\s/g, "");
              const {
                patientTypeId,
                insuranceTypeId,
                appointmentTypeId,
                chooseCarrierAndPlanId,
              } = selectedOptionsId;
              const url = `/personalAppointmentDetails/${type}/${name}/${patientTypeId}/${chooseCarrierAndPlanId}/${appointmentTypeId}`;
              navigate(url, { state: selectedAppointment });

              return true;
            } // Check if the code is 1 meaning email exists
          } catch (error) {
            console.error("Error checking email existence:", error);
            return false; // In case of an error, treat it as if the email doesn't exist
          }
        };

        // Check if email exists
        const emailExists = await checkEmailExists(email);
        // console.log("Does email exist?", emailExists);

        if (emailExists) {
          // Email exists, store response in local storage
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
        } else {
          // Email does not exist, create a new record
          const createPayload = {
            modelName: "patients",
            inputData: {
              email: email,
              first_name: f_name,
              last_name: l_name,
            },
          };

          try {
            const createResponse = await postData(
              "masters/createAndUpdateMaster",
              createPayload
            );
            localStorage.setItem(
              "userCreateInfo",
              JSON.stringify(createResponse.data)
            );
            Dispatch(
              loginPatient({
                // user : payload,
                isLoggedIn: true,
              })
            );
            const { patientType, insuranceType, appointmentType } =
              selectedOptions;
            // const { chooseCarrierAndPlanId } = selectedOptionsId;
            const cleanedPatientType = patientType.replace(/\s/g, "");
            const cleanedInsuranceType = insuranceType.replace(/\s/g, "");
            const cleanedAppointmentType = appointmentType.replace(/\s/g, "");
            const {
              patientTypeId,
              insuranceTypeId,
              appointmentTypeId,
              chooseCarrierAndPlanId,
            } = selectedOptionsId;
            const url = `/personalAppointmentDetails/${type}/${name}/${patientTypeId}/${chooseCarrierAndPlanId}/${appointmentTypeId}`;
            navigate(url, { state: selectedAppointment });
            // console.log("New record created:", createResponse.data);
          } catch (error) {
            console.error("Error creating new record:", error);
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    },
  });

  const [selectedOptionsId, setSelectedOptionsId] = useState({
    patientTypeId: "",
    insuranceTypeId: "",
    appointmentTypeId: "",
    chooseCarrierAndPlanId: "",
  });
  const [options, setOptions] = useState({
    patientType: [],
    insuranceType: [],
    appointmentType: [],
  });
  const [insurancePlans, setInsurancePlans] = useState([]);

  useEffect(() => {
    const currentTimeZone = localStorage.getItem("currentTimeZone");
    console.log("currentTimeZone-11---", currentTimeZone);
    const fetchOptions = async () => {
      try {
        console.log("type---->", type);
        console.log("selectedDoctor---->", selectedDoctor); //154
        console.log("selectedClinic---->", selectedClinic); //154
        var id = 0;
        if (type == "clinicServices") {
          id = selectedClinic;
        } else {
          id = selectedDoctor;
        }
        // if (type == "null") {
        //   return
        // }
        const response = await postData("patients/getAppoinmentTypeDataNew", {
          type: type,
          id: id,
          timeZone: currentTimeZone,
        });
        const data = response.data;
        setOptions({
          patientType: data.patientType.map((pt) => ({
            id: pt.id,
            name: pt.name,
          })),
          insuranceType: data.insuranceType.map((it) => ({
            id: it.id,
            name: it.name,
            clinic_id: it.clinic_id,
          })),
          appointmentType: data.appointmentType.map((at) => ({
            id: at.id,
            name: at.name,
          })),
        });
      } catch (error) {
        // console.log("Error fetching data:", error);
      }
    };

    fetchOptions();
  }, [type, selectedDoctor]);

  const fetchInsurancePlans = async (clinicId, insuranceId) => {
    try {
      const response = await postData("masters/getMasterList", {
        modelName: "clinic_insurances",
        whereCondition: {
          clinic_id: clinicId,
          health_insurance_carrier_id: insuranceId,
        },
        relations: [{ module: "insurance_masters" }],
      });
      // console.log('plans',response.data);
      const plans = response.data.map((plan) => ({
        id: plan.id,
        name: plan.insurance_masters.insurance_name,
      }));
      // console.log('planssssss', plans);
      return plans;
    } catch (error) {
      // console.log("Error fetching insurance plans:", error);
      return [];
    }
  };

  const handleDropdownChange = async (category, key) => {
    const stringifyKey = key.target.value;
    // console.log('category+stringifyKey', stringifyKey, category);
    // console.log('insurancePlans', insurancePlans);
    let selectedOption;
    if (category === "chooseCarrierAndPlan") {
      selectedOption = insurancePlans.find(
        (opt) => opt.id.toString() === stringifyKey.toString()
      );
    } else {
      selectedOption = options[category].find(
        (opt) => opt.id.toString() === stringifyKey.toString()
      );
    }
    if (selectedOption) {
      const updatedOptions = {
        ...selectedOptions,
        [category]: selectedOption.name,
      };
      setSelectedOptions(updatedOptions);
      localStorage.setItem("options", JSON.stringify(updatedOptions));

      const updatedOptionsId = {
        ...selectedOptionsId,
        [`${category}Id`]: selectedOption.id,
      };
      setSelectedOptionsId(updatedOptionsId);
      // console.log("selectedOptionId", updatedOptionsId);
      localStorage.setItem("optionsId", JSON.stringify(updatedOptionsId));

      if (category === "insuranceType") {
        const clinicId = selectedOption.clinic_id;
        const plans = await fetchInsurancePlans(clinicId, selectedOption.id);
        setInsurancePlans(plans);

        // Reset the insurancePlanId since we're changing the insuranceType
        setSelectedOptionsId((prevOptionsId) => ({
          ...prevOptionsId,
          chooseCarrierAndPlanId: "",
        }));
      }
    } else {
      console.error(
        `Selected option with key ${key} not found in category ${category}`
      );
    }
  };

  const handleInsuranceTypeChange = (type) => {
    let updatedOptions = {
      ...selectedOptions,
      insuranceType: type === "Choose Carrier and Plan" ? "" : type,
    };
    setSelectedOptions(updatedOptions);
    localStorage.setItem("options", JSON.stringify(updatedOptions));

    let updatedOptionsId = {
      ...selectedOptionsId,
      insuranceTypeId:
        type === "No Insurance"
          ? "noInsurance"
          : type === "Other"
          ? "other"
          : "",
      chooseCarrierAndPlanId:
        type === "No Insurance"
          ? "noInsurance"
          : type === "Other"
          ? "other"
          : "",
    };
    setSelectedOptionsId(updatedOptionsId);
    localStorage.setItem("optionsId", JSON.stringify(updatedOptionsId));
  };

  return (
    <section className="w-full mt-4">
      <div className="container mx-auto px-4">
        <div className="w-full bg-gray-100 rounded-lg ">
          <div className="w-full flex justify-center items-center gap-2 px-4 py-3 border border-t-0 border-l-0 border-r-0 border-gray-200">
            <div className="w-3/12 font-fontPoppins text-sm font-semibold ">
              Select Date
            </div>
            <div className="w-3/4 flex items-center">
              <i className="fa-solid fa-calendar-alt fa-sm mr-1 mb-1"></i>
              <ReactDatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="EEEE, dd MMMM"
                className="font-fontPoppins text-sm font-medium cursor-pointer p-1 rounded-md"
                minDate={today}
                onClick={(e) => e.preventDefault()}
                placeholderText="Please select a date"
              />
            </div>
          </div>

          {symptom !== null && (
            <>
              {availableDoctors.length > 0 ? (
                availableDoctors.map((doctor, index) => (
                  <div
                    key={index}
                    className="w-full sm:flex sm:items-center sm:gap-2 md:flex md:items-center md:gap-2 px-4 py-3 border border-t-0 border-l-0 border-r-0 border-gray-200"
                  >
                    <div className="w-3/12 xss:w-full flex items-center gap-2">
                      <img
                        src={`${apiUrl}/${doctor.photo}`}
                        width={imgW}
                        alt="Doctor Pic"
                        className="rounded-full"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/placeholder.png";
                        }}
                      />
                      <div className="w-full">
                        <div className="font-fontPoppins text-f10 font-normal">
                          {doctor.taxonomy_description}
                        </div>
                        <div className="font-fontPoppins text-xs font-semibold">
                          <span>
                            Dr.{doctor.f_name} {doctor.l_name}
                          </span>{" "}
                          <span className="px-2 ms-1 font-fontPoppins text-f9 font-normal bg-gray-200 rounded-lg">
                            MD
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="w-3/4 xss:w-full xss:mt-3 flex flex-row overflow-auto items-center justify-between gap-2">
                      <span className="cursor-pointer px-1">
                        <i className="fa-solid fa-angle-left"></i>
                      </span>
                      {doctor.practitionerCalendarsData &&
                        doctor.practitionerCalendarsData.map((slot) => (
                          <div
                            key={slot.id}
                            className="w-32 bg-white hover:bg-blueColor hover:text-white px-2 py-1 shadow-sm rounded-lg font-fontPoppins text-f10 font-medium text-center"
                            style={
                              selectedSlot &&
                              selectedSlot.available_date_time_new ===
                                slot.available_date_time_new &&
                              selectedDoctor === doctor.id
                                ? { backgroundColor: "blue", color: "white" }
                                : {}
                            }
                            onClick={() =>
                              handleSlotClick(
                                doctor,
                                doctor.practitionerCalendarsData,
                                slot
                              )
                            }
                          >
                            {slot.available_date}
                            <br />
                            {slot.available_time}
                          </div>
                        ))}
                      <span className="cursor-pointer px-1">
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center h-full p-10 pb-15">
                  <div className="bg-white shadow-lg rounded-lg p-6 flex items-center space-x-4">
                    <i class="fa fa-exclamation-circle text-red-500 text-2xl"></i>
                    <div>
                      <p className="font-poppins text-lg font-semibold text-gray-700">
                        No doctors available for the selected date.
                      </p>
                      <p className="font-poppins text-sm text-gray-500 mt-2">
                        Please select another date or try searching for doctors
                        in a different location. If you need immediate
                        assistance, consider visiting a nearby clinic or
                        hospital.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="w-full mt-6 lg:min-h-240">
          <div className="w-2/5 bg-gray-100 rounded-lg mx-auto p-4 md:flex md:items-center md:justify-evenly xss:w-full">
            <div className="font-fontPoppins text-f10 font-medium xss:text-center md:text-center xss:mb-1 md:mb-1">
              Not able to find your appointment?
            </div>
            <div className="bg-white rounded-full shadow-sm py-3 px-4 xss:flex xss:items-center xss:justify-between xss:gap-2 sm:flex sm:items-center sm:justify-between sm:gap-3 md:flex md:items-center md:justify-center md:gap-3">
              <span className="font-fontPoppins text-xs font-medium">
                Search on
              </span>{" "}
              <img
                src="/samara-logo.png"
                className="max-w-full h-auto md:w-20 lg:w-28"
                alt="Sammara Well Logo"
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        options={options}
      >
        <div
          className="bg-white rounded-lg p-6 shadow-md mt-3"
          style={{
            maxHeight: "450px",
            overflowY: "auto",
          }}
        >
          {Object.keys(options).map((category) => (
            <div className="w-full mb-4" key={category}>
              <label className="font-fontPoppins text-lg font-semibold text-gray-700 mb-2 block">
                {category === "insuranceType"
                  ? "Choose your insurance"
                  : category
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}{" "}
                {selectedOptions[category] && <CheckIcon />}
              </label>
              {category === "insuranceType" ? (
                <>
                  <div
                    className={`w-full rounded p-2 mt-2 cursor-pointer ${
                      isSelect ? "bg-blue-400" : "bg-grayColor"
                    } hover:bg-blue-200`}
                    onClick={() => setIsSelect(true)}
                  >
                    Choose Carrier and plan
                  </div>
                  {isSelect && (
                    <>
                      <div className="font-bold m-1 mt-3">
                        Health/Dental Insurance Carrier
                      </div>
                      <CustomSelect
                        value={selectedOptionsId.insuranceTypeId}
                        onChange={(key) =>
                          handleDropdownChange("insuranceType", key)
                        }
                        options={options.insuranceType.map((option) => ({
                          value: option.id,
                          label: option.name,
                        }))}
                        placeholder="Select Health/Dental Insurance Carrier"
                      />
                      <div className="font-bold m-1 mt-3">
                        Health/Dental Insurance Plans
                      </div>
                      <CustomSelect
                        value={selectedOptionsId.chooseCarrierAndPlanId}
                        onChange={(key) =>
                          handleDropdownChange("chooseCarrierAndPlan", key)
                        }
                        options={insurancePlans.map((plan) => ({
                          value: plan.id,
                          label: plan.name,
                        }))}
                        placeholder="Select Health/Dental Insurance Plans"
                      />
                    </>
                  )}
                  <div
                    className={`w-full rounded p-2 mt-2 cursor-pointer ${
                      selectedOptions.insuranceType === "No Insurance" &&
                      !isSelect
                        ? "bg-blue-400"
                        : "bg-grayColor"
                    } hover:bg-blue-200`}
                    onClick={() => {
                      handleInsuranceTypeChange("No Insurance");
                      setIsSelect(false);
                    }}
                  >
                    No Insurance
                  </div>
                  <div
                    className={`w-full rounded p-2 mt-2 cursor-pointer ${
                      selectedOptions.insuranceType === "Other" && !isSelect
                        ? "bg-blue-400"
                        : "bg-grayColor"
                    } hover:bg-blue-200`}
                    onClick={() => {
                      handleInsuranceTypeChange("Other");
                      setIsSelect(false);
                    }}
                  >
                    Other
                  </div>
                </>
              ) : (
                <CustomSelect
                  value={selectedOptionsId[category + "Id"]}
                  onChange={(key) => handleDropdownChange(category, key)}
                  options={options[category].map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                  placeholder={`Select ${category}`}
                />
              )}
            </div>
          ))}
          {!user && (
            <div className="w-full sm:flex sm:items-center sm:justify-center sm:gap-3 md:flex md:items-center md:justify-center md:gap-3 mt-4">
              <div className="font-fontPoppins text-sm font-small xss:text-center">
                For a faster booking experience
              </div>
              <span className="px-3 py-1 bg-white shadow-sm cursor-pointer rounded-full flex items-center gap-2 justify-center font-fontPoppins text-xs font-small text-gray-500 xss:mt-2">
                <img
                  src="/icons8-google-48.png"
                  width={googleIconW} // Reduce the size of the Google icon
                  alt="Google Icon"
                />{" "}
                <Link
                  onClick={() => {
                    if (
                      !options.patientType ||
                      !options.insuranceType ||
                      !options.appointmentType
                    ) {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Please Select Patient Details.",
                      });
                      return;
                    }
                    Login();
                  }}
                >
                  Continue With Google
                </Link>
              </span>
            </div>
          )}
          <div className="w-full mt-6 text-center">
            <span
              onClick={handleClickMaualDetails}
              className="font-fontPoppins text-sm font-medium text-blueColor hover:text-blue-500 cursor-pointer"
            >
              Next <i className="fa-solid fa-long-arrow-right"></i>
            </span>
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default BookAnAppointment;
