import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { loginPatient, selectPatient } from "../../redux/actions/userSlice";
import { postData, apiUrl } from "../../Networking/Api";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Header from "../Header";
import Footer from "../Footer";
//import ThirdPartyHeader from "./ThirdPartyHeader";

function PatientReschedule() {
  const [logoImage, setLogoImage] = useState("");
  useEffect(() => {
    const getRescheduleAppointmentData = async (viewId) => {
      try {
        const payload = {
          modelName: "patient_appointments",
          whereCondition: {
            id: viewId,
          },
          relations: [
            {
              module: "practitioners",
            },
            {
              module: "appointment_type",
            },
            {
              module: "practitioner_calendars",
            },
            {
              module: "clinics",
            },
          ],
        };
        const response = await postData("/masters/getMasterList", payload);
        console.log("RescheduleAppointmentdata..", response.data);
        const logoUrl = response.data[0].clinic.logo_image;
        console.log("logourl", logoUrl);
        setLogoImage(logoUrl);
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };
    getRescheduleAppointmentData();
  }, []);
  return (
    <>
      <Header />
      <div className="pt-16">
        <AppointmentSection />
      </div>
      <Footer />
    </>
  );
}

function AppointmentSection() {
  const imgW = "40px";
  const googleIconW = "24px";
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const location = useLocation();
  const { state } = location || {};
  const { symptom } = state || {};
  const [doctors, setDoctors] = useState([]);
  const [user, setUser] = useState();
  const patientData = useSelector(selectPatient);
  const { type, id, appointmentType, insuranceType, patientType, viewId } =
    useParams();
  const today = new Date();

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [details, setDetails] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [appoinmentName, setAppoinmentName] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [patientTypeId, setPatientTypeId] = useState("");
  const [clinicinsuranceId, setClinicInsuranceId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [carrierName, setCarrierName] = useState("");
  const [insurancePlanName, setInsurancePlanName] = useState("");
  const [patientAppointmentId, setPatientAppointmentId] = useState("");

  //1
  useEffect(() => {
    const getRescheduleAppointmentData = async () => {
      try {
        const payload = {
          modelName: "patient_appointments",
          whereCondition: {
            id: viewId,
          },
          relations: [
            {
              module: "practitioners",
            },
            {
              module: "appointment_type",
            },
            {
              module: "practitioner_calendars",
            },
            {
              module: "clinics",
            },
          ],
        };
        const response = await postData("/masters/getMasterList", payload);
        console.log("RescheduleAppointmentdata..", response.data);
        const logoUrl = response.data[0].clinic.logo_image;
        setPatientAppointmentId(response.data[0].id);
        setLogoImage(logoUrl);
        setPatientTypeId(response.data[0].patient_type_id);
        setClinicInsuranceId(response.data[0].clinic_insurance_id);
        setServiceId(response.data[0].clinic_service_id);
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };
    getRescheduleAppointmentData();
  }, []);

  useEffect(() => {
    if (serviceId) {
      getClinicServiceData(serviceId);
    }
  }, [serviceId]);

  useEffect(() => {
    if (clinicinsuranceId) {
      getClinicInsuranceData(clinicinsuranceId);
    }
  }, [clinicinsuranceId]);

  useEffect(() => {
    if (patientAppointmentId) {
      getProviderTimeSlot(patientAppointmentId);
    }
  }, [patientAppointmentId]);

  const getClinicServiceData = async (serviceId) => {
    try {
      const payload = {
        modelName: "clinic_services",
        whereCondition: {
          id: serviceId,
        },
      };
      const response = await postData("/masters/getMasterList", payload);
      console.log("clinicservicedsta", response.data);
      //   localStorage.setItem("doctorsData", JSON.stringify(response.data));
      //address
      console.log("dataappoinmenttye....", response.data[0].services);
      setAppoinmentName(response.data[0].services);
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };
  const getClinicInsuranceData = async (clinicinsuranceId) => {
    try {
      const payload = {
        modelName: "clinic_insurances",
        whereCondition: {
          id: clinicinsuranceId,
        },
        relations: [
          {
            module: "insurance_masters",
          },
          {
            module: "health_insurance_carrier_master",
          },
        ],
      };
      const response = await postData("/masters/getMasterList", payload);
      console.log("clinicInsurancedata", response.data);
      setCarrierName(
        response.data[0].health_insurance_carrier_master
          .health_insurance_carrier_name
      );
      setInsurancePlanName(response.data[0].insurance_master.insurance_name);
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userInfo")) || [];
    setDetails(userDetails);
    const savedAppointment = localStorage.getItem("selectedAppointment");
    const savedSlot = localStorage.getItem("selectedSlot");
    const savedDate = localStorage.getItem("selectedDate");
    const savedDoctor = localStorage.getItem("selectedDoctor");

    if (savedAppointment && savedSlot) {
      setSelectedAppointment(JSON.parse(savedAppointment));
      setSelectedSlot(JSON.parse(savedSlot));
    }
    if (savedDate) {
      setSelectedDate(new Date(savedDate));
    }
    if (savedDoctor) {
      setSelectedDoctor(JSON.parse(savedDoctor));
    }
  }, []);

  useEffect(() => {
    setUser(patientData);
  }, [patientData]);

  useEffect(() => {
    getProviderTimeSlot(patientAppointmentId);
  }, [selectedDate]);

  useEffect(() => {
    const availableDoctorsList = doctors.filter(
      (doctor) => doctor.practitionerCalendarsData.length > 0
    );
    setAvailableDoctors(availableDoctorsList);
  }, [selectedDate, doctors]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getProviderTimeSlot = async (patientAppointmentId) => {
    try {
      const payload = {
        patient_appointment_id: patientAppointmentId,
        date: formatDate(selectedDate),
      };

      const apiResponse = await postData(
        "patients/getPractitionorSlotForReschedule",
        payload
      );

      if (apiResponse.code === 1) {
        setDoctors(apiResponse.data);
        return apiResponse.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error searching provider:", error);
      return null;
    }
  };

  const handleDateChange = (date) => {
    localStorage.removeItem("selectedSlot");
    localStorage.removeItem("selectedDoctor");
    setSelectedSlot(null);
    setSelectedDoctor(null);
    setSelectedDate(date);
    localStorage.setItem("selectedDate", date.toISOString());
  };

  const handleSlotClick = (doctorId, practitionerCalendarsData, slot) => {
    const selectedSlotData = practitionerCalendarsData.find(
      (data) => data.available_date_time_new === slot.available_date_time_new
    );
    //console.log("selectedAppointmentSlot..",selectedSlotData);
    setSelectedAppointment(selectedSlotData);
    setSelectedSlot(slot);
    setSelectedDoctor(doctorId);

    localStorage.setItem(
      "selectedAppointment",
      JSON.stringify(selectedSlotData)
    );
    localStorage.setItem("selectedSlot", JSON.stringify(slot));
    localStorage.setItem("selectedDoctor", JSON.stringify(doctorId));

    // Directly navigate to next page upon slot selection if user is logged in
    if (user) {
      const url = `/thirdPartyPersonalAppoinmentDetails/${type}/${id}/${patientType}/${insuranceType}/${appointmentType}`;
      navigate(url, { state: selectedAppointment });
    }
  };

  const handleClickReschedule = async () => {
    if (!selectedSlot) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Select A Practitioner And Appointment Slot!",
      });
      return;
    }
    try {
      const payload = {
        booked_slot_id: viewId,
        inputData: {
          is_patient_family: 0,
          patient_family_id: 0,
          practitioner_id: selectedAppointment.practitioner_id,
          price: 0,
          appointment_date_time: selectedAppointment.available_date_time,
          payment_details: "NA",
          status: "Pending",
          practitioner_calendars_id: selectedAppointment.id,
        },
        practitionerCalendarsData: selectedAppointment,
      };

      const confirmationResult = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to reschedule this appointment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reschedule it!",
      });

      // Proceed with deletion if user confirms
      if (confirmationResult.isConfirmed) {
        console.log("ReschedulePayload..", payload);
        const response = await postData("common/reschedule", payload);
        console.log("Reschedule appointment:", response);
        if (response.code === 1) {
          //setRescheduleId(response.data.id);

          const viewId = response.data.id;
          console.log("rescheduleId", response.data.id);
          // Show success message if deletion was successful
          Swal.fire({
            title: "Success!",
            text: "Your appointment has been successfully rescheduled.",
            icon: "success",
          }).then(() => {
            // Navigate to the next page after showing the success message
            navigate(`/bookappointmentDetails/${viewId}`);
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "Error",
        text: "There was an error rescheduling your appointment. Please try again later.",
        icon: "error",
      });
    }
  };

  //   const Login = useGoogleLogin({
  //     onSuccess: async ({ access_token }) => {
  //       try {
  //         const userInfoResponse = await axios.get(
  //           "https://www.googleapis.com/oauth2/v3/userinfo",
  //           { headers: { Authorization: `Bearer ${access_token}` } }
  //         );
  //         const userInfo = userInfoResponse.data;
  //         const email = userInfo.email;
  //         const l_name = userInfo.family_name;
  //         const f_name = userInfo.given_name;

  //         const checkEmailExists = async (email) => {
  //           const payload = {
  //             modelName: "patients",
  //             whereCondition: { email: email },
  //           };

  //           try {
  //             const apiResponse = await postData(
  //               "masters/getMasterList",
  //               payload
  //             );
  //             if (apiResponse.code == 1) {
  //               localStorage.setItem(
  //                 "userCreateInfo",
  //                 JSON.stringify(apiResponse.data)
  //               );
  //               localStorage.setItem("accountType", "Patient");
  //               Dispatch(
  //                 loginPatient({
  //                   isLoggedIn: true,
  //                 })
  //               );

  //               localStorage.removeItem("selectedSlot");

  //               if (selectedSlot) {
  //                 const url = `/thirdPartyPersonalAppoinmentDetails/${type}/${id}/${patientType}/${insuranceType}/${appointmentType}`;
  //                 window.location.href = url;
  //               }

  //               return true;
  //             }
  //           } catch (error) {
  //             console.error("Error checking email existence:", error);
  //             return false;
  //           }
  //         };

  //         const emailExists = await checkEmailExists(email);
  //         console.log("Does email exist?", emailExists);

  //         if (!emailExists) {
  //           const createPayload = {
  //             modelName: "patients",
  //             inputData: {
  //               email: email,
  //               first_name: f_name,
  //               last_name: l_name,
  //             },
  //           };

  //           try {
  //             const createResponse = await postData(
  //               "masters/createAndUpdateMaster",
  //               createPayload
  //             );
  //             localStorage.setItem(
  //               "userCreateInfo",
  //               JSON.stringify(createResponse.data)
  //             );
  //             Dispatch(
  //               loginPatient({
  //                 isLoggedIn: true,
  //               })
  //             );

  //             // Clear selectedSlot from local storage
  //             localStorage.removeItem("selectedSlot");

  //             // Directly navigate to next page upon login if slot is selected
  //             if (selectedSlot) {
  //               const url = `/thirdPartyPersonalAppoinmentDetails/${type}/${id}/${patientType}/${insuranceType}/${appointmentType}`;
  //               window.location.href = url;
  //             }

  //             console.log("New record created:", createResponse.data);
  //           } catch (error) {
  //             console.error("Error creating new record:", error);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error fetching user info:", error);
  //       }
  //     },
  //   });

  return (
    <section className="w-full mt-4">
      <div className="container mx-auto p-4">
        {/* <span
          onClick={() => navigate(`/bookAnAppoinmentThirdParty/${type}/${id}`)}
          className="font-fontPoppins text-sm font-semibold cursor-pointer"
        >
          <i className="fa-solid fa-long-arrow-left text-gray-300"></i> Book an
          appointment
        </span> */}
        <h2 className="text-3xl font-bold mb-4">Reschedule Appointment</h2>
      </div>
      <div className="w-full bg-gray-100 rounded-lg py-4 mt-2">
        <div className="w-full px-4 flex items-center gap-2">
          <div className="p-2 bg-white rounded-lg shadow-sm">
            <div className="font-fontPoppins text-f10 font-normal text-gray-400">
              Patient Type
            </div>
            <div className="font-fontPoppins text-xs font-medium">
              {patientTypeId === 2 ? "Existing Patient" : "New Patient"}
            </div>
          </div>
          <div className="p-2 bg-white rounded-lg shadow-sm">
            <div className="font-fontPoppins text-f10 font-normal text-gray-400">
              Appointment Type
            </div>
            <div className="font-fontPoppins text-xs font-medium">
              {appoinmentName}
            </div>
          </div>
          {clinicinsuranceId === null ? (
            <div className="p-2 bg-white rounded-lg shadow-sm">
              <div className="font-fontPoppins text-f10 font-normal text-gray-400">
                Insurance Type
              </div>
              <div className="font-fontPoppins text-xs font-medium">
                {/* {insuranceType} */}
                Other/No insurance
              </div>
            </div>
          ) : (
            <>
              <div className="p-2 bg-white rounded-lg shadow-sm">
                <div className="font-fontPoppins text-f10 font-normal text-gray-400">
                  Insurance Carrier
                </div>
                <div className="font-fontPoppins text-xs font-medium">
                  {/* {insuranceType} */}
                  {carrierName}
                </div>
              </div>
              <div className="p-2 bg-white rounded-lg shadow-sm">
                <div className="font-fontPoppins text-f10 font-normal text-gray-400">
                  Insurance Plan
                </div>
                <div className="font-fontPoppins text-xs font-medium">
                  {/* {insuranceType} */}
                  {insurancePlanName}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="container mx-auto px-4">
          <div className="w-full bg-gray-100 rounded-lg">
            <div className="w-full flex justify-center items-center gap-2 px-4 py-3 border border-t-0 border-l-0 border-r-0 border-gray-200">
              <div className="w-3/12 font-fontPoppins text-sm font-semibold ">
                Select Date
              </div>
              <div className="w-3/4 flex items-center">
                <i className="fa-solid fa-calendar-alt fa-sm mr-1 mb-1"></i>
                <ReactDatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="EEEE, dd MMMM"
                  className="font-fontPoppins text-sm fontmedium cursor-pointer p-1 rounded-md"
                  minDate={today}
                  onClick={(e) => e.preventDefault()}
                  placeholderText="Please select a date"
                />
              </div>
            </div>
            {symptom !== null && (
              <>
                {availableDoctors.length > 0 ? (
                  availableDoctors.map((doctor, index) => (
                    <div
                      key={index}
                      className="w-full sm:flex sm:items-center sm:gap-2 md:flex md:items-center md:gap-2 px-4 py-3 border border-t-0 border-l-0 border-r-0 border-gray-200"
                    >
                      <div className="w-3/12 xss:w-full flex items-center gap-2">
                        <img
                          src={`${apiUrl}/${doctor.photo}`}
                          width={imgW}
                          alt="Doctor Pic"
                          className="rounded-full"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/placeholder.png";
                          }}
                        />
                        <div className="w-full">
                          <div className="font-fontPoppins text-f10 font-normal">
                            {doctor.taxonomy_description}
                          </div>
                          <div className="font-fontPoppins text-xs font-semibold">
                            <span>
                              Dr.{doctor.f_name} {doctor.l_name}
                            </span>{" "}
                            <span className="px-2 ms-1 font-fontPoppins text-f9 font-normal bg-gray-200 rounded-lg">
                              MD
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="w-3/4 xss:w-full xss:mt-3 flex flex-row overflow-auto items-center justify-between gap-2">
                        <span className="cursor-pointer px-1">
                          <i className="fa-solid fa-angle-left"></i>
                        </span>
                        {doctor.practitionerCalendarsData &&
                          doctor.practitionerCalendarsData.map((slot) => (
                            <div
                              key={slot.id}
                              className={`w-32 bg-white hover:bg-blueColor hover:text-white px-2 py-1 shadow-sm rounded-lg font-fontPoppins text-f10 font-medium text-center ${
                                user ? "bg-white text-black" : ""
                              }`}
                              style={
                                selectedSlot &&
                                !user &&
                                selectedSlot.available_date_time_new ===
                                  slot.available_date_time_new &&
                                selectedDoctor === doctor.id
                                  ? { backgroundColor: "blue", color: "white" }
                                  : {}
                              }
                              onClick={() =>
                                handleSlotClick(
                                  doctor.id,
                                  doctor.practitionerCalendarsData,
                                  slot
                                )
                              }
                            >
                              {slot.available_date}
                              <br />
                              {slot.available_time}
                            </div>
                          ))}

                        <span className="cursor-pointer px-1">
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-center items-center h-full p-10 pb-15">
                    <div className="bg-white shadow-lg rounded-lg p-6 flex items-center space-x-4">
                      <i className="fa fa-exclamation-circle text-red-500 text-2xl"></i>
                      <div>
                        <p className="font-poppins text-lg font-semibold text-gray-700">
                          No doctors available for the selected date.
                        </p>
                        <p className="font-poppins text-sm text-gray-500 mt-2">
                          Please select another date or try searching for
                          doctors in a different location. If you need immediate
                          assistance, consider visiting a nearby clinic or
                          hospital.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {/* {!user && (
        <div className="w-full sm:flex sm:items-center sm:justify-center sm:gap-3 md:flex md:items-center md:justify-center md:gap-3 mt-4">
          <div className="font-fontPoppins text-sm font-medium xss:text-center">
            For a faster booking experience
          </div>
          <span className="px-8 py-3 bg-white shadow-sm cursor-pointer rounded-full flex items-center gap-3 justify-center font-fontPoppins text-sm font-medium text-gray-500 xss:mt-2">
            <img
              src="/icons8-google-48.png"
              width={googleIconW}
              alt="Google Icon"
            />{" "}
            <Link
              onClick={() => {
                if (!selectedSlot) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select a practitioner and appointment slot!',
                  });
                  return;
                }
                Login();
              }}
            >
              Continue With Google
            </Link>
          </span>
        </div>
      )}

      <div className="w-full mt-5 text-center">
      <span
  onClick={handleClickManualDetails}
  className={`font-fontPoppins text-sm font-medium text-blueColor hover:text-blue-500 cursor-pointer ${
    user ? 'hidden' : ''
  }`}
  style={{ display: user ? 'none' : 'inline' }}
>
  Or Enter Your Details Manually
  <i className="fa-solid fa-long-arrow-right"></i>
</span>
      </div> */}
          <div className="flex justify-center">
            <button
              onClick={handleClickReschedule}
              className="w-36 h-10 font-fontPoppins bg-blueColor text-xs rounded-full text-white font-semibold hover:opacity-85"
            >
              Reschedule
            </button>
          </div>
          <div className="w-full mt-6 lg:min-h-240">
            <div className="w-2/5 bg-gray-100 rounded-lg mx-auto p-4 md:flex md:items-center md:justify-evenly xss:w-full">
              <div className="font-fontPoppins text-f10 font-medium xss:text-center md:text-center xss:mb-1 md:mb-1">
                Not able to find your appointment?
              </div>
              <div className="bg-white rounded-full shadow-sm py-3 px-4 xss:flex xss:items-center xss:justify-between xss:gap-2 sm:flex sm:items-center sm:justify-between sm:gap-3 md:flex md:items-center md:justify-center md:gap-3">
                <span className="font-fontPoppins text-xs font-medium">
                  Search on
                </span>{" "}
                <img
                  onClick={() => navigate("/patientLanding")}
                  src="/samara-logo.png"
                  className="max-w-full h-auto md:w-20 lg:w-28"
                  alt="Sammara Well Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PatientReschedule;
