import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import ReactDatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { postData, apiUrl } from "../Networking/Api";
import Header from "./Header";
import ReactSelect from "react-select";
import Swal from "sweetalert2";
const shadowBottom = {
  boxShadow: "0 6px 6px -6px #ccc",
};

function ProviderCalender() {
  return (
    <>
      <Header />
      <div className="pt-16">
        <ProviderCalendarHeader />
      </div>
      {/* <DoctorsTeam />
      <CalendarTable /> */}
    </>
  );
}

function ProviderCalendarHeader() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [providers, setProviders] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [athenaId, setAthenaId] = useState();
  const [doctorCronoId, setDoctorCronoId] = useState();
  const [modmedId, setModmedId] = useState();
  const [samaraPractitionerIdModmed, setSamaraPractitionerIdModmed] =
    useState();
  const [clinicId, setClinicId] = useState();
  const [clinicProAssId, setClinicProAssId] = useState();
  const [referenceId, setReferenceId] = useState();
  const [userMasterId, setUserMasteId] = useState();
  const [accountType, setAccountType] = useState();
  const [eventSync, setEventSync] = useState([]);
  useEffect(() => {
    const storedClinicId = localStorage.getItem("clinicId");
    console.log("clinicId", storedClinicId);
    setClinicId(storedClinicId);
    const storedProAssistId = localStorage.getItem("clinicIdProAssist");
    console.log("clinicId", storedProAssistId);
    setClinicProAssId(storedProAssistId);
    const storedReferenceId = localStorage.getItem("refernceId");
    console.log("storedReferenceId", storedReferenceId);
    setReferenceId(storedReferenceId);
    const storedUserMasterId = localStorage.getItem("userMasterId");
    console.log("storedUserMasterId", storedUserMasterId);
    setUserMasteId(storedUserMasterId);
    const storedType = localStorage.getItem("accountType");
    console.log("storedType", storedType);
    setAccountType(storedType);

    const getProvidersOptions = async () => {
      try {
        const payload = {
          clinic_id: storedClinicId,
          reference_id: storedReferenceId,
          userMasterId: storedUserMasterId,
          type: storedType,
        };
        console.log("payload", payload);
        const response = await postData(
          "/practitioners/getPractitionersListForCalender",
          payload
        );
        console.log("providerListing...", response.data);
        if (response.code == 1) {
          console.log("providerData", response.data);
          setProviders(response.data);
          console.log("providerDataid", response.data.id);
        }
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };
    getProvidersOptions();
  }, []);
  useEffect(() => {
    //  getProvidersOptions();
    //getSyncPractAppointment();
  }, []);
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  useEffect(() => {
    if (providers.length > 0) {
      setSelectedProviderId(providers[0].id);
      setAthenaId(providers[0].athenahealth_id);
      setDoctorCronoId(providers[0].drchrono_id);
      setModmedId(providers[0].modmed_id);
      setSamaraPractitionerIdModmed(providers[0].id);
      console.log("selectedProvider1...", providers[0].id);
    }
  }, [providers]);
  console.log("selectedDate", formatDate(selectedDate));
  const handleProviderChange = (selectedOption) => {
    // setSelectedProviderId(event.target.value);
    if (selectedOption) {
      setSelectedProviderId(selectedOption.value);
      const selectedProvider = providers.find(
        (provider) => provider.id === selectedOption.value
      );
      setAthenaId(selectedProvider.athenahealth_id);
      setDoctorCronoId(selectedProvider.drchrono_id);
      setModmedId(selectedProvider.modmed_id);
      setSamaraPractitionerIdModmed(selectedProvider.id);
    } else {
      setSelectedProviderId(null);
      setAthenaId(null);
      setDoctorCronoId(null);
      setModmedId(null);
      setSamaraPractitionerIdModmed(null);
    }
    console.log("selectedProvider", selectedOption.value);
  };
  const getPractitionersCalender = async () => {
    try {
      const payload = {
        practitioner_id: selectedProviderId, //105
        date: formatDate(selectedDate),
      };

      const apiResponse = await postData(
        "practitioners/getPractitionersCalender",
        payload
      );

      if (apiResponse.code === 1) {
        const responsedata = apiResponse.data;
        console.log("responseData", responsedata);
        // API call was successful and data is available
        // const mappedEvents = responsedata.map(event => {
        //   let backgroundColor;
        //   switch(event.color) {
        //     case "green":
        //       backgroundColor ='rgba(0, 128, 0, 0.5)';
        //       break;
        //     case "orange":
        //       backgroundColor ='rgba(255, 165, 0, 0.5)';
        //       break;
        //     case "red":
        //       backgroundColor = 'rgba(255, 0, 0, 0.5)';
        //       break;
        //     default:
        //       backgroundColor = 'rgba(128, 128, 128, 0.5)';
        //   }
        //   const status = event.status || "Open";
        //   return {
        //     title: `${event.patient_first_name} ${event.patient_last_name}`,
        //     status: `${status}`,
        //     start: event.start_date,
        //     end: event.end_date,
        //     backgroundColor: backgroundColor,
        //     extendedProps: {
        //       patient_first_name: event.patient_first_name,
        //       patient_last_name: event.patient_last_name,
        //       status: event.status,
        //     },
        //   };
        // });
        const startTime = new Date(`${formatDate(selectedDate)}T08:00:00`);
        const endTime = new Date(`${formatDate(selectedDate)}T24:00:00`);
        const timeSlots = [];

        for (
          let time = startTime;
          time < endTime;
          time.setMinutes(time.getMinutes() + 30)
        ) {
          timeSlots.push(new Date(time));
        }

        const mappedEvents = [];
        const usedSlots = new Set();

        responsedata.forEach((event) => {
          let backgroundColor;
          switch (event.color.toLowerCase()) {
            case "green":
              backgroundColor = "rgba(0, 128, 0, 0.5)";
              break;
            case "orange":
              backgroundColor = "rgba(255, 165, 0, 0.5)";
              break;
            case "red":
              backgroundColor = "rgba(255, 0, 0, 0.5)";
              break;
            default:
              backgroundColor = "rgba(128, 128, 128, 0.5)";
          }

          const status = event.status || "Open";
          const eventStart = new Date(event.start_date);
          const eventEnd = new Date(event.end_date);

          mappedEvents.push({
            title: "",
            status: `${status}`,
            start: eventStart,
            end: eventEnd,
            backgroundColor: backgroundColor,
            extendedProps: {
              patient_first_name: event.patient_first_name,
              patient_last_name: event.patient_last_name,
              status: event.status,
            },
          });

          for (
            let time = new Date(eventStart);
            time < eventEnd;
            time.setMinutes(time.getMinutes() + 30)
          ) {
            usedSlots.add(time.toISOString());
          }
        });

        timeSlots.forEach((time) => {
          if (!usedSlots.has(time.toISOString())) {
            const nextSlot = new Date(time);
            nextSlot.setMinutes(nextSlot.getMinutes() + 30);

            mappedEvents.push({
              title: "",
              start: new Date(time),
              end: nextSlot,
              backgroundColor: "rgba(128, 128, 128, 0.5)",
              extendedProps: {
                status: "Not Available",
              },
            });
          }
        });

        setEventSync(mappedEvents);
        //setEvents(apiResponse.data);
        //console.log("PractitionerSlot", apiResponse.data);

        return apiResponse.data; // Assuming data is in the 'data' field
      } else {
        // No data found or some issue with the request
        return null;
      }
    } catch (error) {
      console.error("Error searching provider:", error);
      return null; // Error occurred or no data available
    }
  };
  const getSyncPractAppointment = async () => {
    try {
      const payload = {
        athenahealth_ids: athenaId ? [athenaId] : [],
        samara_practitioner_ids: athenaId ? [selectedProviderId] : [],
        reasonid: 1, //static
        departmentid: 1, //static
        drchrono_ids: doctorCronoId ? [doctorCronoId] : [],
        samara_practitioner_ids_drc: doctorCronoId ? [selectedProviderId] : [],

        since: formatDate(selectedDate),
        modmed_ids: modmedId ? [modmedId] : [],
        samara_practitioner_ids_modmed: samaraPractitionerIdModmed
          ? [samaraPractitionerIdModmed]
          : [],
      };
      console.log("syncPayload", payload);
      const response = await postData(
        "athenaapi/syncPractitionersAppointments",
        payload
      );
      console.log("Sync successful:", response.data);
      if (response.code === 1) {
        //getPractitionersCalender
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
          timer: 2000,
          // showConfirmButton: true,
        });
        getPractitionersCalender();
      }
      // Handle response data here
    } catch (error) {
      console.error("Error syncing appointments:", error);
      // Handle error here
    }
  };
  const providerOptions = providers.map((provider) => ({
    value: provider.id,
    label: `${provider.f_name} ${provider.l_name}`,
  }));

  const selectedProvider = providerOptions.find(
    (option) => option.value === selectedProviderId
  );

  return (
    <>
      <header
        className="w-full min-h-16 px-4 py-1 md:flex md:items-center"
        style={shadowBottom}
      >
        <div className="w-full flex items-center gap-2 xss:justify-center">
          {/* <Link to="/">
        <span className="cursor-pointer font-fontPoppins text-sm font-semibold text-gray-500 hover:text-blueColor flex-nowrap">
          <i class="fa-solid fa-arrow-left"></i> Back
        </span>
        </Link> */}

          {/* <select className="bg-blueColor px-2 py-1 font-fontPoppins text-xs font-semibold text-white rounded-full uppercase focus:outline-none">
           <option selected>{isToday(selectedDate) ? 'Today' : formatDate(selectedDate)}</option>
        </select>

        <span>
          <button className="border-none hover:text-blueColor px-1">
            <i className="fa-solid fa-angle-left"></i>
          </button>
          <button className="border-none hover:text-blueColor px-1">
            <i className="fa-solid fa-angle-right"></i>
          </button>
        </span> */}
          <div className="w-full flex items-center justify-start">
            <i className="fa-solid fa-calendar-alt fa-sm mr-1"></i>
            <ReactDatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="EEEE, dd MMMM"
              className="font-fontPoppins text-sm font-medium cursor-pointer"
            />
            <span
              onClick={getSyncPractAppointment}
              title="Sync Practitioner Appointments"
              className="cursor-pointer py-1 px-1 font-fontPoppins text-2xl font-semibold text-black hover:text-blueColor"
            >
              <i class="fa-solid fa-sync"></i>
            </span>
          </div>
        </div>

        <div className="w-full flex items-center justify-end gap-2 xss:justify-center xss:mt-2 sm:mt-2 md:mt-0">
          <label className="font-fontPoppins text-sm xss:text-xs font-medium">
            Select Provider
          </label>

          {/* <select 
        onChange={handleProviderChange}
        value={selectedProviderId}
        className="w-80 xss:w-40 focus:outline-none font-fontPoppins text-sm xss:text-xs font-medium py-1 border rounded-full px-3">
          {providers.map(provider => (
            <option key={provider.id} value={provider.id}>
              {provider.f_name} {provider.l_name}
            </option>
          ))} 
        </select> */}
          <div className="w-80 xss:w-40">
            <ReactSelect
              value={selectedProvider}
              onChange={handleProviderChange}
              options={providerOptions}
              className="w-80 xss:w-40 font-fontPoppins text-sm xss:text-xs font-medium"
              classNamePrefix="react-select"
            />
          </div>
          {/* <span className="cursor-pointer py-1 px-1 font-fontPoppins text-sm font-semibold text-gray-500 hover:text-blueColor">
          <i class="fa-solid fa-filter"></i>
        </span> */}
        </div>
      </header>

      {providers.length > 0 && <DoctorsTeam providers={providers} />}
      <CalendarTable
        selectedProviderId={selectedProviderId}
        selectedDate={selectedDate}
        eventSync={eventSync}
      />
    </>
  );
}

function DoctorsTeam({ providers }) {
  const ImgW = "40px";
  const ImgH = "40px";
  return (
    <section className="w-full px-4 py-4">
      <div className="w-full bg-gray-200 rounded-lg p-2 flex items-center justify-between gap-2 flex-row overflow-x-auto">
        {providers.map((provider) => (
          <div className="w-full text-center" key={provider.id}>
            <img
              src={`${apiUrl}/${provider.photo}`}
              alt={`${provider.f_name} ${provider.l_name}`}
              width={ImgW}
              height={ImgH}
              className="rounded-full mx-auto"
            />
            <p className="font-fontPoppins text-f10 font-semibold mt-1">
              {provider.f_name} {provider.l_name}
            </p>
          </div>
        ))}

        {/* <div className="w-full text-center">
          <img
            src={"./kw.png"}
            alt="Pic"
            width={ImgW}
            height={ImgH}
            className="rounded-full mx-auto"
          />

          <p className="font-fontPoppins text-f10 font-semibold mt-1">
            Kate Winslet
          </p>
        </div>
        <div className="w-full text-center">
          <img
            src={"./kw.png"}
            alt="Pic"
            width={ImgW}
            height={ImgH}
            className="rounded-full mx-auto"
          />

          <p className="font-fontPoppins text-f10 font-semibold mt-1">
            Kate Winslet
          </p>
        </div>
        <div className="w-full text-center">
          <img
            src={"./kw.png"}
            alt="Pic"
            width={ImgW}
            height={ImgH}
            className="rounded-full mx-auto"
          />

          <p className="font-fontPoppins text-f10 font-semibold mt-1">
            Kate Winslet
          </p>
        </div>
        <div className="w-full text-center">
          <img
            src={"./kw.png"}
            alt="Pic"
            width={ImgW}
            height={ImgH}
            className="rounded-full mx-auto"
          />

          <p className="font-fontPoppins text-f10 font-semibold mt-1">
            Kate Winslet
          </p>
        </div> */}
      </div>
    </section>
  );
}

function CalendarTable({ selectedProviderId, selectedDate, eventSync }) {
  const TableWFirstRow = "80px";
  const TableW = "200px";
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    getPractitionersCalender();
  }, [selectedProviderId, selectedDate]);
  const getPractitionersCalender = async () => {
    try {
      const payload = {
        practitioner_id: selectedProviderId, //105
        date: formatDate(selectedDate),
      };

      const apiResponse = await postData(
        "practitioners/getPractitionersCalender",
        payload
      );

      if (apiResponse.code === 1) {
        const responsedata = apiResponse.data;
        console.log("responseData", responsedata);
        // API call was successful and data is available
        // const mappedEvents = responsedata.map(event => {
        //   let backgroundColor;
        //   switch(event.color) {
        //     case "green":
        //       backgroundColor ='rgba(0, 128, 0, 0.5)';
        //       break;
        //     case "orange":
        //       backgroundColor ='rgba(255, 165, 0, 0.5)';
        //       break;
        //     case "red":
        //       backgroundColor = 'rgba(255, 0, 0, 0.5)';
        //       break;
        //     default:
        //       backgroundColor = 'rgba(128, 128, 128, 0.5)';
        //   }
        //   const status = event.status || "Open";
        //   return {
        //     title: `${event.patient_first_name} ${event.patient_last_name}`,
        //     status: `${status}`,
        //     start: event.start_date,
        //     end: event.end_date,
        //     backgroundColor: backgroundColor,
        //     extendedProps: {
        //       patient_first_name: event.patient_first_name,
        //       patient_last_name: event.patient_last_name,
        //       status: event.status,
        //     },
        //   };
        // });
        const startTime = new Date(`${formatDate(selectedDate)}T08:00:00`);
        const endTime = new Date(`${formatDate(selectedDate)}T24:00:00`);
        const timeSlots = [];

        for (
          let time = startTime;
          time < endTime;
          time.setMinutes(time.getMinutes() + 30)
        ) {
          timeSlots.push(new Date(time));
        }

        const mappedEvents = [];
        const usedSlots = new Set();

        responsedata.forEach((event) => {
          let backgroundColor;
          switch (event.color.toLowerCase()) {
            case "green":
              backgroundColor = "rgba(0, 128, 0, 0.5)";
              break;
            case "orange":
              backgroundColor = "rgba(255, 165, 0, 0.5)";
              break;
            case "red":
              backgroundColor = "rgba(255, 0, 0, 0.5)";
              break;
            default:
              backgroundColor = "rgba(128, 128, 128, 0.5)";
          }

          const status = event.status || "Open";
          const eventStart = new Date(event.start_date);
          const eventEnd = new Date(event.end_date);

          mappedEvents.push({
            title: "",
            status: `${status}`,
            start: eventStart,
            end: eventEnd,
            backgroundColor: backgroundColor,
            extendedProps: {
              patient_first_name: event.patient_first_name,
              patient_last_name: event.patient_last_name,
              status: event.status,
            },
          });

          for (
            let time = new Date(eventStart);
            time < eventEnd;
            time.setMinutes(time.getMinutes() + 30)
          ) {
            usedSlots.add(time.toISOString());
          }
        });

        timeSlots.forEach((time) => {
          if (!usedSlots.has(time.toISOString())) {
            const nextSlot = new Date(time);
            nextSlot.setMinutes(nextSlot.getMinutes() + 30);

            mappedEvents.push({
              title: "",
              start: new Date(time),
              end: nextSlot,
              backgroundColor: "rgba(128, 128, 128, 0.5)",
              extendedProps: {
                status: "Not Available",
              },
            });
          }
        });

        setEvents(mappedEvents);
        //setEvents(apiResponse.data);
        //console.log("PractitionerSlot", apiResponse.data);

        return apiResponse.data; // Assuming data is in the 'data' field
      } else {
        // No data found or some issue with the request
        return null;
      }
    } catch (error) {
      console.error("Error searching provider:", error);
      return null; // Error occurred or no data available
    }
  };
  useEffect(() => {
    setEvents(eventSync); // Patch events from props
  }, [eventSync]);

  useEffect(() => {
    // Fetch the data from the API
    // const apiResponse =
    // [
    //   {
    //       "practitioner_calendars_id": 7,
    //       "start_date": "2024-05-28T10:00:00.000Z",
    //       "end_date": "2024-05-28T10:15:00.000Z",
    //       "duration": 15,
    //       "starttime": "15:30",
    //       "status": "Pending",
    //       "patient_appointments_id": 1,
    //       "patient_first_name": "Mike",
    //       "patient_last_name": "Doe",
    //       "color": "red"
    //   },
    //   {
    //       "practitioner_calendars_id": 2,
    //       "start_date": "2024-05-28T07:30:00.000Z",
    //       "end_date": "2024-05-28T07:45:00.000Z",
    //       "duration": 15,
    //       "starttime": "13:00",
    //       "status": null,
    //       "patient_appointments_id": null,
    //       "patient_first_name": "rohit",
    //       "patient_last_name": "bhujbal",
    //       "color": "green"
    //   },
    //   {
    //       "practitioner_calendars_id": 5,
    //       "start_date": "2024-05-28T09:00:00.000Z",
    //       "end_date": "2024-05-28T09:15:00.000Z",
    //       "duration": 15,
    //       "starttime": "14:30",
    //       "status": null,
    //       "patient_appointments_id": null,
    //       "patient_first_name": "rajesh",
    //       "patient_last_name": "ghare",
    //       "color": "green"
    //   },
    //   {
    //       "practitioner_calendars_id": 8,
    //       "start_date": "2024-05-28T10:30:00.000Z",
    //       "end_date": "2024-05-28T10:45:00.000Z",
    //       "duration": 15,
    //       "starttime": "16:00",
    //       "status": null,
    //       "patient_appointments_id": null,
    //       "patient_first_name": "mahesh",
    //       "patient_last_name": "mogal",
    //       "color": "green"
    //   },
    //   {
    //       "practitioner_calendars_id": 6,
    //       "start_date": "2024-05-28T09:30:00.000Z",
    //       "end_date": "2024-05-28T09:45:00.000Z",
    //       "duration": 15,
    //       "starttime": "15:00",
    //       "status": null,
    //       "patient_appointments_id": null,
    //       "patient_first_name": "sakshi",
    //       "patient_last_name": "Bhorade",
    //       "color": "green"
    //   },
    //   {
    //       "practitioner_calendars_id": 4,
    //       "start_date": "2024-05-28T08:30:00.000Z",
    //       "end_date": "2024-05-28T08:45:00.000Z",
    //       "duration": 15,
    //       "starttime": "14:00",
    //       "status": null,
    //       "patient_appointments_id": null,
    //       "patient_first_name": "atul",
    //       "patient_last_name": "Test",
    //       "color": "green"
    //   },
    //   {
    //       "practitioner_calendars_id": 3,
    //       "start_date": "2024-05-28T08:00:00.000Z",
    //       "end_date": "2024-05-28T08:15:00.000Z",
    //       "duration": 15,
    //       "starttime": "13:30",
    //       "status": null,
    //       "patient_appointments_id": null,
    //       "patient_first_name": "saurabh",
    //       "patient_last_name": "shinde",
    //       "color": "green"
    //   },
    //   {
    //       "practitioner_calendars_id": 9,
    //       "start_date": "2024-05-28T10:30:00.000Z",
    //       "end_date": "2024-05-28T10:45:00.000Z",
    //       "duration": 15,
    //       "starttime": "16:00",
    //       "status": null,
    //       "patient_appointments_id": null,
    //       "patient_first_name": "mandar",
    //       "patient_last_name": "jangidar",
    //       "color": "green"
    //   }
    // ];
    // [
    //   {
    //     "patient_name": "John Doe",
    //     "start_date": "2024-05-29T16:00:00",
    //     "end_date": "2024-05-29T17:00:00",
    //     "status": "Open",
    //     "color": "Green"
    //   },
    //   {
    //     "patient_name": "Jane Smith",
    //     "start_date": "2024-05-30T14:00:00",
    //     "end_date": "2024-05-30T15:00:00",
    //     "status": "Open",
    //     "color": "Green"
    //   },
    //   {
    //     "patient_name": "Alice Johnson",
    //     "start_date": "2024-05-31T16:00:00",
    //     "end_date": "2024-05-31T16:00:00",
    //     "status": "Booked",
    //     "color": "Orange"
    //   },
    //   {
    //     "patient_name": "Bob Brown",
    //     "start_date": "2024-05-29T17:00:00",
    //     "end_date": "2024-05-29T19:00:00",
    //     "status": "Cancelled",
    //     "color": "Red"
    //   },
    //   {
    //     "patient_name": "Bob Brown",
    //     "start_date": "2024-05-29T20:00:00",
    //     "end_date": "2024-05-29T22:00:00",
    //     "status": "Not Available",
    //     "color": "Grey"
    //   }
    // ];
    // Map API response to FullCalendar events format
    // const mappedEvents = apiResponse.map(event => {
    //   let backgroundColor;
    //   switch(event.color) {
    //     case "green":
    //       backgroundColor ='rgba(0, 128, 0, 0.5)';
    //       break;
    //     case "orange":
    //       backgroundColor ='rgba(255, 165, 0, 0.5)';
    //       break;
    //     case "red":
    //       backgroundColor = 'rgba(255, 0, 0, 0.5)';
    //       break;
    //     default:
    //       backgroundColor = 'rgba(128, 128, 128, 0.5)';
    //   }
    //   const status = event.status || "Open";
    //   return {
    //     title: `${event.patient_first_name}${event.patient_last_name}`,
    //     status: `${status}`,
    //     start: event.start_date,
    //     end: event.end_date,
    //     backgroundColor: backgroundColor,
    //   };
    // });
    // setEvents(mappedEvents);
  }, []);
  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };
  return (
    <section className="w-full px-4 pb-4">
      <FullCalendar
        headerToolbar={{
          start: "today prev next",
          end: "dayGridMonth timeGridWeek timeGridDay",
        }}
        plugins={[daygridPlugin, timeGridPlugin]}
        initialView="timeGridWeek"
        views={["dayGridMonth", "timeGridWeek", "timeGridDay"]}
        events={events}
        allDaySlot={false}
        dayHeaderFormat={{ weekday: "short", month: "long", day: "numeric" }}
        eventClick={handleEventClick}
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        }}
        eventContent={(eventInfo) => {
          const eventDuration =
            (new Date(eventInfo.event.end) - new Date(eventInfo.event.start)) /
            (1000 * 60);
          const isShortEvent = eventDuration <= 15;

          return (
            <div style={{ fontSize: isShortEvent ? "10px" : "inherit" }}>
              <p>
                {eventInfo.event.status} {eventInfo.event.title}
              </p>
              {/* <i>{eventInfo.event.title}</i> */}
            </div>
          );
        }}
        // Highlighted Change: Customize event rendering
      />
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-1/3">
            <div className="flex justify-between items-center border-b p-4">
              <h3 className="text-lg font-semibold">Event Details</h3>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={handleClose}
              >
                &times;
              </button>
            </div>
            <div className="p-4">
              {selectedEvent && (
                <>
                  {selectedEvent.extendedProps.status !== "Open" && (
                    <p>
                      <strong>Patient Name:</strong>{" "}
                      {selectedEvent.extendedProps.patient_first_name}{" "}
                      {selectedEvent.extendedProps.patient_last_name}
                    </p>
                  )}
                  <p>
                    <strong>Status:</strong>{" "}
                    {selectedEvent.extendedProps.status}
                  </p>
                  <p>
                    <strong>Start:</strong>{" "}
                    {new Date(selectedEvent.start).toLocaleDateString("en-US")}
                  </p>
                  <p>
                    <strong>End:</strong>{" "}
                    {new Date(selectedEvent.end).toLocaleDateString("en-US")}
                  </p>
                </>
              )}
            </div>
            <div className="flex justify-end border-t p-4 space-x-2">
              {/* <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700" onClick={handleClose}>
          Close
        </button> */}
              {/* <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700">
          Confirm
        </button> */}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default ProviderCalender;
