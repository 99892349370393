// src/components/Calendar.js
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tippy from "tippy.js";
// import '@fullcalendar/common/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';
import "tippy.js/dist/tippy.css";
import ReactSelect from "react-select";
import Header from "./Header";
import { postData } from "../Networking/Api";
import Swal from "sweetalert2";
import Footer from "./Footer";
const shadowBottom = {
  boxShadow: "0 6px 6px -6px #ccc",
};
const Calendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [providers, setProviders] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [athenaId, setAthenaId] = useState();
  const [doctorCronoId, setDoctorCronoId] = useState();
  const [modmedId, setModmedId] = useState();
  const [samaraPractitionerIdModmed, setSamaraPractitionerIdModmed] =
    useState();
  const [events, setEvents] = useState([
    // { title: 'Focus time', date: '2024-06-10T08:00:00', details: 'Details about Focus time' },
    // { title: 'Focus time', date: '2024-06-10T09:00:00', details: 'Details about Focus time' },
    // { title: 'Confernce call', date: '2024-06-10T10:00:00', details: 'Details about Focus time' },
    // { title: 'Vc call', date: '2024-06-10T11:00:00', details: 'Details about Focus time' },
    // { title: 'Meeting', date: '2024-06-11T10:00:00', details: 'Details about the Meeting' },
  ]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventSync, setEventSync] = useState([]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const formatDateTimeUS = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };
  useEffect(() => {
    const filterEventsByDate = (date) => {
      const formattedDate = formatDate(date);
      const eventsOnSelectedDate = events.filter((event) =>
        event.start.startsWith(formattedDate)
      );
      setFilteredEvents(eventsOnSelectedDate);
      setSelectedEvent(eventsOnSelectedDate[0] || null);
    };

    filterEventsByDate(selectedDate);
  }, [selectedDate, events]);
  useEffect(() => {
    if (selectedProviderId !== null) {
      getPractitionersCalender();
    }
  }, [selectedProviderId, selectedDate]);

  const getPractitionersCalender = async () => {
    try {
      const payload = {
        practitioner_id: selectedProviderId, //105
        date: "2024-06-04", //formatDate(selectedDate)
      };

      const apiResponse = await postData(
        "practitioners/getPractitionersCalender",
        payload
      );

      if (apiResponse.code === 1) {
        const responsedata = apiResponse.data
          .filter(
            (event) => event.status === "Booked" || event.status === "Cancelled"
          )
          .map((event) => ({
            id: event.practitioner_calendars_id,
            title: `Appointment with ${event.patient_first_name} ${event.patient_last_name}`,
            start: event.start_date,
            end: event.end_date,
            details: `Patient Name: ${event.patient_first_name} ${
              event.patient_last_name
            }<br>
                  Start Time: ${formatDateTimeUS(event.start_date)}<br>
                  End Time: ${formatDateTimeUS(event.end_date)}<br>
                  Status: ${event.status}`,
            color: event.color,
          }));
        setEvents(responsedata);
        return apiResponse.data; // Assuming data is in the 'data' field
      } else {
        // No data found or some issue with the request
        return null;
      }
    } catch (error) {
      console.error("Error searching provider:", error);
      return null; // Error occurred or no data available
    }
  };
  const getSyncPractAppointment = async () => {
    if (!selectedProviderId) {
      Swal.fire({
        title: "Error",
        text: "Please Select Practitioner Before Sync",
        icon: "error",
        timer: 2000,
      });
      return;
    }
    try {
      const payload = {
        athenahealth_ids: athenaId ? [athenaId] : [],
        samara_practitioner_ids: athenaId ? [selectedProviderId] : [],
        reasonid: 1, //static
        departmentid: 1, //static
        drchrono_ids: doctorCronoId ? [doctorCronoId] : [],
        samara_practitioner_ids_drc: doctorCronoId ? [selectedProviderId] : [],
        since: formatDate(selectedDate),
        modmed_ids: modmedId ? [modmedId] : [],
        samara_practitioner_ids_modmed: samaraPractitionerIdModmed
          ? [samaraPractitionerIdModmed]
          : [],
      };
      console.log("syncPayload", payload);
      const response = await postData(
        "athenaapi/syncPractitionersAppointments",
        payload
      );
      console.log("Sync successful:", response.data);
      if (response.code === 1) {
        //getPractitionersCalender
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
          timer: 2000,
          // showConfirmButton: true,
        });
        getPractitionersCalender();
      }
      // Handle response data here
    } catch (error) {
      console.error("Error syncing appointments:", error);
      // Handle error here
    }
  };
  useEffect(() => {
    const storedClinicId = localStorage.getItem("clinicId");
    // console.log("clinicId", storedClinicId);
    const storedProAssistId = localStorage.getItem("clinicIdProAssist");
    // console.log("clinicId", storedProAssistId);
    const storedReferenceId = localStorage.getItem("refernceId");
    // console.log("storedReferenceId", storedReferenceId);
    const storedUserMasterId = localStorage.getItem("userMasterId");
    // console.log("storedUserMasterId", storedUserMasterId);
    const storedType = localStorage.getItem("accountType");
    console.log("storedType", storedType);

    const getProvidersOptions = async () => {
      try {
        // var accountType = localStorage.getItem("accountType");
        // var refernceId = localStorage.getItem("refernceId");
        // if (accountType == 'Practitioners') {
        //   var payload = {
        //     modelName: "practitioners",
        //     whereCondition: {
        //       id: refernceId,
        //       is_active: 1,
        //     },
        //   };
        // } else if (accountType == 'Assistances') {
        //   var payload = {
        //     modelName: "assistance_practitioners_mapping",
        //     whereCondition: {
        //       clinic_id: clinicId,
        //       is_active: 1,
        //     },
        //   };
        //   var assistance_practitioners_mapping_response = await postData("masters/getMasterList", payload);
        //   if (assistance_practitioners_mapping_response.code === 1) {
        //     var assistance_practitioners_mapping_data = assistance_practitioners_mapping_response.data;
        //     var varpractitionerIds = [];
        //     for (let i = 0; i < assistance_practitioners_mapping_data.length; i++) {
        //       const element = assistance_practitioners_mapping_data[i];
        //       varpractitionerIds.push(element.practitioner_id)
        //     }
        //   }
        //   console.log("varpractitionerIds--->", varpractitionerIds);
        //   var payload = {
        //     modelName: "practitioners",
        //     whereCondition: {
        //       id: varpractitionerIds,
        //       is_active: 1,
        //     },
        //   };
        // } else {
        //   var payload = {
        //     modelName: "practitioners",
        //     whereCondition: {
        //       clinic_id: clinicId,
        //       is_active: 1,
        //     },
        //   };
        // }
        const payload = {
          clinic_id: storedClinicId,
          reference_id: storedReferenceId,
          userMasterId: storedUserMasterId,
          type: storedType,
        };
        console.log("payload", payload);
        const response = await postData(
          "/practitioners/getPractitionersListForCalender",
          payload
        );
        console.log("providerListing...", response.data);
        if (response.code == 1) {
          console.log("providerData", response.data);
          setProviders(response.data);
          if (response.data.length > 0) {
            const firstProvider = response.data[0];
            setSelectedProviderId(firstProvider.id); // Setting selectedProviderId to the first provider's ID
            setAthenaId(firstProvider.athenahealth_id);
            setDoctorCronoId(firstProvider.drchrono_id);
            setModmedId(firstProvider.modmed_id);
            setSamaraPractitionerIdModmed(firstProvider.id);
          }
          console.log("providerDataid", response.data.id);
        }
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };
    getProvidersOptions();
  }, []);
  const providerOptions = providers.map((provider) => ({
    value: provider.id,
    label: `${provider.f_name} ${provider.l_name}`,
  }));

  const selectedProvider = providerOptions.find(
    (option) => option.value === selectedProviderId
  );
  const handleProviderChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedProviderId(selectedOption.value); // Setting selectedProviderId based on the selected option
      const selectedProvider = providers.find(
        (provider) => provider.id === selectedOption.value
      );
      setAthenaId(selectedProvider.athenahealth_id);
      setDoctorCronoId(selectedProvider.drchrono_id);
      setModmedId(selectedProvider.modmed_id);
      setSamaraPractitionerIdModmed(selectedProvider.id);
    } else {
      setSelectedProviderId(null);
      setAthenaId(null);
      setDoctorCronoId(null);
      setModmedId(null);
      setSamaraPractitionerIdModmed(null);
    }
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date.toISOString().split("T")[0];
    const eventsOnSelectedDate = events.filter((event) =>
      event.start.startsWith(formattedDate)
    );
    setFilteredEvents(eventsOnSelectedDate);
    setSelectedEvent(eventsOnSelectedDate[0] || null);
  };

  const handleEventClick = (info) => {
    const event = events.find((e) => e.id === info.event.id);
    setSelectedEvent(event);
  };

  const handleMouseEnter = (info) => {
    const startDate = new Date(info.event.start);
    const endDate = new Date(info.event.end);
    const formattedStartDate = startDate.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const formattedStartTime = startDate.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const formattedEndTime = endDate.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    tippy(info.el, {
      content: `
        <strong>${info.event.title}</strong><br>
        From ${formattedStartTime} TO ${formattedEndTime}
      `,
      allowHTML: true,
      interactive: true,
    }).show();
  };

  const handleMouseLeave = (info) => {
    tippy(info.el).destroy();
  };

  return (
    <>
      <Header />
      <div className="pt-16">
        <header
          className="w-full  min-h-16 px-4 py-1 md:flex md:items-center"
          style={shadowBottom}
        >
          <div className="w-full flex items-center justify-between mt-3">
            <div className="w-full flex items-center flex justify-between">
              <div>
                <span>EHR/PMS: Sync on Demand</span>
                <span
                  onClick={getSyncPractAppointment}
                  title="Sync Practitioner Appointments"
                  className="cursor-pointer border border-black m-3 px-1 py-1 px font-fontPoppins text-2xl font-semibold text-black hover:text-blueColor"
                >
                  <i className="fa-solid fa-sync"></i>
                </span>
              </div>
              <div>
                <div>
                  <label className="font-fontPoppins text-sm xss:text-xs font-medium">
                    Select Practitioner
                  </label>
                </div>
                <div className="w-80 xss:w-40">
                  <ReactSelect
                    value={selectedProvider}
                    onChange={handleProviderChange}
                    options={providerOptions}
                    className="w-80 xss:w-40 font-fontPoppins text-sm xss:text-xs font-medium"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="flex">
          {/* Left-side Date Picker */}
          <div className="w-1/5 p-4">
            <h2 className="text-xl font-bold mb-4">Select Date</h2>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              inline
            />
          </div>

          {/* Main Calendar */}
          <div className="w-3/5 p-4">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              headerToolbar={{
                start: "prev,next today",
                center: "title",
                end: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              events={events}
              dateClick={(info) => setSelectedDate(new Date(info.dateStr))}
              eventClick={handleEventClick}
              //dateClick={handleDateChange}
              eventColor="#1a73e8"
              className="shadow-lg rounded-lg"
              ref={(calendar) => {
                if (calendar) {
                  const calendarApi = calendar.getApi();
                  calendarApi.gotoDate(selectedDate); // Update the calendar view when the date picker changes
                }
              }}
              dayMaxEvents={1}
              eventMouseEnter={handleMouseEnter}
              eventMouseLeave={handleMouseLeave}
              eventMinHeight={30}
            />
          </div>

          {/* Right-side Event Details */}
          <div className="w-1/5 p-4">
            <h2 className="text-xl font-bold mb-4">
              {" "}
              {selectedDate
                ? selectedDate.toLocaleString("en-US", {
                    weekday: "short",
                    day: "numeric",
                    month: "short",
                  })
                : "Select a Date"}
            </h2>
            {filteredEvents.length > 0 ? (
              filteredEvents.map((event) => (
                <div
                  key={event.id}
                  className="border p-4 rounded-lg shadow mb-4"
                >
                  <h3 className="text-lg font-bold">{event.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: event.details }}></p>
                </div>
              ))
            ) : (
              <p>No Appointments On This Date</p>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Calendar;
