import { useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  loginPatient,
  logout,
  logoutPatient,
  logoutProvider,
  selectPatient,
  selectProvider,
  selectUser,
} from "../redux/actions/userSlice";
import { useEffect } from "react";
import { apiUrl, postData } from "../Networking/Api";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { logEvent } from "../ga";

const shadowBottom = {
  boxShadow: "0 6px 6px -6px #ccc",
};

const paddingNav = {
  padding: "16px",
};

const ActionTypes = {
  SHOW: "SHOW",
  HIDE: "HIDE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SHOW:
      return {
        ...state,
        [action.itemType]: { ...state[action.itemType], isVisible: true },
      };

    case ActionTypes.HIDE:
      return {
        ...state,
        [action.itemType]: { ...state[action.itemType], isVisible: false },
      };
    default:
      return state;
  }
};

function Header() {
  const [state, dispatch] = useReducer(reducer, {
    type1: { isVisible: false },
    type2: { isVisible: false },
    type3: { isVisible: false },
    type4: { isVisible: false },
  });
  const [user, setUser] = useState();
  const [providerUser, setProviderUser] = useState();
  const [accountType, setAccountType] = useState(null);
  const [userName, setUsername] = useState();
  const patientData = useSelector(selectPatient);
  const providerData = useSelector(selectProvider);
  const [assistantInfoLink, setAssistantInfoLink] = useState(false);
  const [mySubscriptionsLink, setMySubscriptionsLink] = useState(false);
  const [practitionerInfoLink, setPractitionerInfoLink] = useState(false);
  const [clinicPhotoLink, setClinicPhotoLink] = useState(false);
  const [insuranceInfoLink, setInsuranceInfo] = useState(false);
  const [ProfilePic, setProfilePic] = useState();
  // console.log("Patient User:", patientData);
  // console.log("Provider User:", providerData);
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   setUser(userData.isLoggedIn);
  // }, [userData]);
  useEffect(() => {
    const Username = localStorage.getItem("userName");
    const ProfilePic = localStorage.getItem("profilePic");
    setProfilePic(ProfilePic);
    var accountType = localStorage.getItem("accountType");
    var refernceId = localStorage.getItem("refernceId");
    if (accountType == "Clinic") {
      setPractitionerInfoLink(true);
      setAssistantInfoLink(true);
      setMySubscriptionsLink(true);
      setClinicPhotoLink(true);
      setInsuranceInfo(true);
    }
    if (accountType == "Practitioners") {
      setAssistantInfoLink(true);
    }
    if (accountType == "Assistances") {
      setPractitionerInfoLink(true);
      setInsuranceInfo(true);
      setClinicPhotoLink(true);
      setMySubscriptionsLink(true);
    }
    setUsername(Username);
    const storedAccountType = localStorage.getItem("accountType");
    setAccountType(storedAccountType);
    // console.log("storedAccountTYpe",storedAccountType);
  }, []);

  useEffect(() => {
    setUser(patientData);
  }, [patientData]);

  useEffect(() => {
    setProviderUser(providerData);
  }, [providerData]);
  // const handleLogout = () => {
  //   //localStorage.clear();
  //   Dispatch(logout());
  //   navigate("/");
  // };
  const handlePatientLogout = () => {
    googleLogout();
    localStorage.clear();
    Dispatch(logoutPatient());
    navigate("/");
    window.location.reload();
  };

  const handleProviderLogout = () => {
    localStorage.clear();
    Dispatch(logoutProvider());

    window.location.reload();
    navigate("/");
    // navigate("/", { replace: true });
  };
  const Login = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      try {
        // Get user info from Google
        const userInfoResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
        const userInfo = userInfoResponse.data;
        const email = userInfo.email;
        const l_name = userInfo.family_name;
        const f_name = userInfo.given_name;

        // Function to check if email exists in the database
        const checkEmailExists = async (email) => {
          const payload = {
            modelName: "patients",
            whereCondition: { email: email },
          };

          try {
            const apiResponse = await postData(
              "masters/getMasterList",
              payload
            );

            // console.log("API Response for email check:", apiResponse.data); // Log the response
            if (apiResponse.code == 1) {
              console.log(apiResponse.data[0], "apiResponse.data[0]");

              localStorage.setItem(
                "userCreateInfo",
                JSON.stringify(apiResponse.data[0])
              );

              localStorage.setItem("accountType", "Patient");
              const UserName =
                apiResponse.data[0].first_name +
                " " +
                apiResponse.data[0].last_name;
              localStorage.setItem("userName", UserName);
              Dispatch(
                loginPatient({
                  // user : payload,
                  isLoggedIn: true,
                })
              );

              logEvent("User", "Login", email);
              console.log("hello called logevent");

              navigate("/patientLanding");
              console.log("hello1 called logevent");

              return true;
            } // Check if the code is 1 meaning email exists
          } catch (error) {
            console.error("Error checking email existence:", error);
            return false; // In case of an error, treat it as if the email doesn't exist
          }
        };

        // Check if email exists
        const emailExists = await checkEmailExists(email);
        // console.log("Does email exist?", emailExists);

        if (emailExists) {
          // Email exists, store response in local storage
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
        } else {
          // Email does not exist, create a new record
          const createPayload = {
            modelName: "patients",
            inputData: {
              email: email,
              first_name: f_name,
              last_name: l_name,
            },
          };

          try {
            const createResponse = await postData(
              "masters/createAndUpdateMaster",
              createPayload
            );
            localStorage.setItem(
              "userCreateInfo",
              JSON.stringify(createResponse.data[0])
            );
            const UserName =
              // createResponse.data[0].first_name +
              // " " +
              // createResponse.data[0].last_name;
              createResponse.data.first_name +
              " " +
              createResponse.data.last_name;
            localStorage.setItem("userName", UserName);
            localStorage.setItem("accountType", "Patient");
            Dispatch(
              loginPatient({
                // user : payload,
                isLoggedIn: true,
              })
            );

            navigate("/patientLanding");
            logEvent("User", "Login", email);
            // console.log("New record created:", createResponse.data);
          } catch (error) {
            console.error("Error creating new record:", error);
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    },
  });
  const getAccountTypeHeading = () => {
    const storedAccountType = localStorage.getItem("accountType");
    if (storedAccountType === "Clinic") return "Organization";
    if (storedAccountType === "Practitioners") return "Practitioner";
    if (storedAccountType === "Assistances") return "Assistance";
    return "Provider"; // Default to "Provider" if no accountType matches
  };
  return (
    <header className="w-full fixed top-0 left-0 z-50 bg-white shadow-md">
      <div
        // className="mx-auto sm:flex md:flex flex px-4 bg-white shadow-sm"
        className="flex justify-between px-2"
        style={shadowBottom}
      >
        <div className="flex items-center gap-3 xss:justify-center">
          <span
            className="cursor-pointer"
            onClick={() =>
              dispatch({ type: ActionTypes.SHOW, itemType: "type2" })
            }
          >
            <i class="fa-solid fa-bars fa-lg"></i>
          </span>
          <span>
            <Link to={user ? "/patientLanding" : "/"}>
              <img src={"/samara-logo.png"} alt="logo" width="133px" />
            </Link>
          </span>
          {/* <span className="font-fontPoppins text-xs font-normal">
          Care, at the speed of thought!
        </span>
        <button className="w-28 py-2 bg-blueColor font-fontPoppins text-xs font-normal text-white rounded-full">
          See Demo <i className="fa-solid fa-circle-play"></i>
        </button> */}
        </div>

        <div className="ml-auto flex items-center xss:justify-center">
          <span className="cursor-pointer flex justify-center items-center gap-3 me-3">
            <NavLink
              to="/patientLanding"
              className={({ isActive }) =>
                `py-5 xss:py-3 font-fontPoppin text-sm text-black font-medium border-b-4 mb-1 ${
                  isActive ? "border-blue-500" : "border-white"
                }`
              }
            >
              {/* For Patients */}
            </NavLink>
            {/* <a
            href={" "}
            className="py-5 xss:py-3 font-fontPoppins text-sm text-black font-medium border-b-4 border-white hover:border-b-4 hover:border-blueColor hover:border-solid"
          >
            For Patients
          </a> */}
            <NavLink
              to="/"
              className={({ isActive }) =>
                `py-5 xss:py-3 font-fontPoppin text-sm text-black font-medium border-b-4 mb-1 ${
                  isActive ? "border-blue-500" : "border-white"
                }`
              }
            >
              {/* For Providers */}
            </NavLink>
            {/* <a
            href={" "}
            className="py-5 xss:py-3 font-fontPoppin text-sm text-black font-medium border-b-4 border-white hover:border-b-4 hover:border-blueColor hover:border-solid"
          >
            For Providers 
          </a> */}
          </span>
          <span className="cursor-pointer flex items-center gap-2 mt--2">
            {providerUser && (
              <img
                src={`${apiUrl}/${ProfilePic}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/clinicPlaceholder.png";
                }}
                alt=""
                className="w-10 h-10 rounded-full object-cover"
              />
            )}
            <p className="font-fontPoppin text-sm text-black font-medium">
              {userName}
            </p>
            {/* <Link
            onClick={() => login()}
            className="py-5 xss:py-3 font-fontPoppin text-sm text-black font-medium border-b-4 border-white hover:border-b-4 hover:border-blueColor hover:border-solid"
          >
            Log In
          </Link> */}
          </span>
          {/* <span className="cursor-pointer">
          <i class="fa-solid fa-search fa-lg"></i>
        </span> */}
        </div>
      </div>

      <div>
        {state.type2.isVisible && (
          <>
            <nav
              className="w-80 h-screen z-50 fixed inset-0 bg-white"
              style={paddingNav}
            >
              <header className="w-full flex justify-between">
                <img
                  src={"/samara-logo.png"}
                  alt="logo"
                  width="133px"
                  // className="max-w-full"
                />
                <span
                  className="cursor-pointer hover:text-blueColor"
                  onClick={() =>
                    dispatch({ type: ActionTypes.HIDE, itemType: "type2" })
                  }
                >
                  <i className="fa-solid fa-xmark fa-lg"></i>
                </span>
              </header>
              {accountType !== "Patient" && (
                <div className="w-full mt-4">
                  <h1 className="font-fontPoppins py-3 text-sm font-semibold text-gray-500 border border-gray-800 border-solid border-l-0 border-t-0 border-r-0">
                    {/* Provider */}
                    {getAccountTypeHeading()}
                  </h1>
                </div>
              )}
              <main
                className="w-full h-full overflow-auto flex flex-col"
                style={{ paddingBottom: "200px" }}
              >
                {accountType !== "Patient" && (
                  <div className="w-full">
                    {providerUser && (
                      <>
                        <div className="w-full flex mt-4">
                          <Link
                            to="/Dashboard"
                            className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                          >
                            {/* Dashboard */}
                            Insights & Analytics
                          </Link>
                        </div>
                        <div className="w-full flex justify-center items-center mt-2">
                          <button
                            onClick={() =>
                              dispatch({
                                type: ActionTypes.SHOW,
                                itemType: "type3",
                              })
                            }
                            className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor"
                          >
                            Accounts <i className="fa-solid fa-angle-right"></i>
                          </button>
                        </div>
                      </>
                    )}

                    {state.type3.isVisible && (
                      <div className="w-80 h-screen z-40 fixed left-0 top-0 bg-white p-4">
                        <header className="w-full flex justify-between">
                          <img
                            src={"./samara-logo.png"}
                            alt="logo"
                            width="133px"
                            className="max-w-full"
                          />
                          <span
                            className="cursor-pointer hover:text-blueColor"
                            onClick={() =>
                              dispatch({
                                type: ActionTypes.HIDE,
                                itemType: "type3",
                              })
                            }
                          >
                            <i className="fa-solid fa-xmark fa-lg"></i>
                          </span>
                        </header>
                        <div
                          className="w-full h-full mt-4 overflow-auto flex flex-col"
                          style={{ paddingBottom: "200px" }}
                        >
                          <h1
                            onClick={() =>
                              dispatch({
                                type: ActionTypes.HIDE,
                                itemType: "type3",
                              })
                            }
                            className="text-left cursor-pointer font-fontPoppins text-base font-semibold py-3 hover:text-blueColor"
                          >
                            <i className="fa-solid fa-arrow-left"></i> Accounts
                          </h1>
                          <ul className="list-none font-fontPoppins text-sm ps-4 mt-8">
                            <li className="mb-4 hover:text-blueColor">
                              {/* <a href={" "}>Clinic Info</a> */}
                              <Link to="/CreateClinic">Organization Info</Link>
                            </li>
                            {practitionerInfoLink && (
                              <li className="mb-4 hover:text-blueColor">
                                <Link to="/providerInfo">
                                  Practitioner Info
                                </Link>
                              </li>
                            )}
                            <li className="mb-4 hover:text-blueColor">
                              <Link to="/serviceProviderMappingProfile">
                                Appoinment Provider Mapping
                              </Link>
                            </li>

                            {assistantInfoLink && (
                              <li className="mb-4 hover:text-blueColor">
                                <Link to="/assistantInfo">
                                  Front Office Team
                                </Link>
                              </li>
                            )}
                            {insuranceInfoLink && (
                              <li className="mb-4 hover:text-blueColor">
                                <Link to="/insuranceInfo">Insurance Info</Link>
                              </li>
                            )}
                            {clinicPhotoLink && (
                              <li className="mb-4 hover:text-blueColor">
                                <Link to="/clinicmedia">
                                  Scheduling Widget & Hyperlink
                                </Link>
                              </li>
                            )}
                            {/* <li className="mb-4 hover:text-blueColor">
                          <Link to="/cliniccardDetails">Card Details</Link>
                        </li> */}
                            <li className="mb-4 hover:text-blueColor">
                              <Link to="/assistantProviderMapping">
                                Front Office Team Provider Mapping
                              </Link>
                            </li>
                            {/* <li className="mb-4 hover:text-blueColor">
                              <Link to="/AssistanceProfile">
                                Assistance Profile
                              </Link>
                            </li> */}
                            {mySubscriptionsLink && (
                              <li className="mb-4 hover:text-blueColor">
                                <Link to="/mySubscription">
                                  My Subscriptions
                                </Link>
                              </li>
                            )}
                            {/* <li className="mb-4 hover:text-blueColor">
                          <Link to="/ClinicBookingHistory">Booking History</Link>
                        </li> */}
                          </ul>
                        </div>
                      </div>
                    )}

                    <div className="w-full mt-2">
                      {providerUser ? (
                        <>
                          {/* <div className="w-full flex mt-2">
                            <Link
                              to="/providerCalender"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Provider Calender
                            </Link>
                          </div> */}
                          <div className="w-full flex mt-2">
                            <Link
                              to="/newcalender"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Provider Calender
                            </Link>
                          </div>
                          {/* <div className="w-full flex mt-2">
                            <Link
                              to="/newcalender"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              New Calender
                            </Link>
                          </div> */}
                          <div className="w-full flex mt-4">
                            <Link
                              to="/ClinicBookingHistory"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Booking Details
                            </Link>
                          </div>
                          {accountType === "Practitioners" && (
                            <div className="w-full flex mt-4">
                              <Link
                                to="/ProviderProfile"
                                className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                              >
                                Profile
                              </Link>
                            </div>
                          )}
                          {accountType === "Assistances" && (
                            <div className="w-full flex mt-4">
                              <Link
                                to="/AssistanceProfile"
                                className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                              >
                                Profile
                              </Link>
                            </div>
                          )}

                          <div className="w-full flex mt-4">
                            <Link
                              onClick={handleProviderLogout}
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Logout
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="w-full flex mt-4">
                            {/* <a
                    href={" "}
                    className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                  >
                    Log In
                  </a> */}
                            <Link
                              to="/login"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Log In
                            </Link>
                          </div>

                          <div className="w-full flex justify-center items-center mt-2">
                            {/* <a
                    href={" "}
                    className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                  >
                    Sign Up
                  </a> */}
                            <Link
                              to="/outpatientPracticeDetails2"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Sign Up
                            </Link>
                          </div>
                        </>
                      )}
                    </div>

                    {/* <div className="border border-gray-800 border-solid border-l-0 border-t-0 border-r-0 hover:border-blueColor">
                  <button
                    onClick={() =>
                      dispatch({ type: ActionTypes.SHOW, itemType: "type1" })
                    }
                    className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor"
                  >
                    Specialty <i className="fa-solid fa-angle-right"></i>
                  </button>
                </div> */}

                    {/*  */}
                    {state.type1.isVisible && (
                      <>
                        {/* <div className="w-80 h-screen z-40 fixed left-0 top-0 bg-white p-4">
                      <header className="w-full flex justify-between">
                        <img
                          src={"./samara-logo.png"}
                          alt="logo"
                          className="max-w-full"
                        />
                        <span
                          className="cursor-pointer hover:text-blueColor"
                          onClick={() =>
                            dispatch({
                              type: ActionTypes.HIDE,
                              itemType: "type2",
                            })
                          }
                        >
                          <i className="fa-solid fa-xmark fa-lg"></i>
                        </span>
                      </header>
                      <div
                        className="w-full h-full mt-4 overflow-auto flex flex-col"
                        style={{ paddingBottom: "200px" }}
                      >
                        <h1
                          onClick={() =>
                            dispatch({
                              type: ActionTypes.HIDE,
                              itemType: "type1",
                            })
                          }
                          className="text-left cursor-pointer font-fontPoppins text-base font-semibold py-3 hover:text-blueColor"
                        >
                          <i className="fa-solid fa-arrow-left"></i> Specialty
                        </h1>
                        <ul className="list-none font-fontPoppins text-sm ps-4 mt-8">
                          <li className="mb-4 hover:text-blueColor">
                            <a href={" "}>Weight loss</a>
                          </li>
                          <li className="mb-4 hover:text-blueColor">
                            <a href={" "}>Allergist</a>
                          </li>
                          <li className="mb-4 hover:text-blueColor">
                            <a href={" "}>Covid-19</a>
                          </li>
                          <li className="mb-4 hover:text-blueColor">
                            <a href={" "}>Dermatologist</a>
                          </li>
                          <li className="mb-4 hover:text-blueColor">
                            <a href={" "}>Infection</a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                      </>
                    )}

                    {/*  */}

                    {/* <div className="border border-gray-800 border-solid border-l-0 border-t-0 border-r-0 hover:border-blueColor">
                  <button className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor">
                    Medication <i className="fa-solid fa-angle-right"></i>
                  </button>
                </div> */}

                    {/* <div className="border border-gray-800 border-solid border-l-0 border-t-0 border-r-0 hover:border-blueColor">
                  <button className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor">
                    Symptom <i className="fa-solid fa-angle-right"></i>
                  </button>
                </div> */}

                    {/* <div className="border border-gray-800 border-solid border-l-0 border-t-0 border-r-0 hover:border-blueColor">
                  <button className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor">
                    Service <i className="fa-solid fa-angle-right"></i>
                  </button>
                </div> */}

                    {/* <div className="border border-gray-800 border-solid border-l-0 border-t-0 border-r-0 hover:border-blueColor">
                  <button className="w-full text-left font-fontPoppins text-base font-semibold py-3 flex justify-between hover:text-blueColor">
                    Life Situation <i className="fa-solid fa-angle-right"></i>
                  </button>
                </div> */}
                  </div>
                )}

                {accountType !== "Clinic" &&
                  accountType !== "Practitioners" &&
                  accountType !== "Assistances" && (
                    <div className="w-full mt-6">
                      <div className="w-full">
                        <h1 className="font-fontPoppins py-3 text-sm font-semibold text-gray-500 border border-gray-800 border-solid border-l-0 border-t-0 border-r-0">
                          Patient
                        </h1>
                      </div>
                      {/* <div className="w-full flex justify-center items-center mt-4">
                  <Link
                    to="/chooseAppoinment/:type/:name"
                    className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                  >
                    Book Appoinment
                  </Link>
                </div> */}
                      {user ? (
                        <>
                          <div className="w-full flex mt-4">
                            <Link
                              to="/patientLanding"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Home
                            </Link>
                          </div>
                          {/* <div className="w-full flex justify-center items-center mt-4">
                            <Link
                              to=""
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Profile
                            </Link>
                          </div> */}
                          <div className="w-full flex justify-center items-center mt-2">
                            <Link
                              to="/BookingHistory"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Booking Details
                            </Link>
                          </div>
                          <div className="w-full flex mt-2">
                            <Link
                              onClick={handlePatientLogout}
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Log out
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="w-full flex mt-4">
                            <Link
                              to="/patientLanding"
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Home
                            </Link>
                          </div>
                          <div className="w-full flex mt-4">
                            <Link
                              onClick={() => Login()}
                              className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                            >
                              Log In
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                {/* <div className="w-full mt-6">
                <div className="font-fontPoppins text-base font-semibold ">
                  Account
                </div>
                <div className="w-full flex mt-6">
                  <a
                    href={" "}
                    className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                  >
                    Log In
                  </a>
                </div>

                <div className="w-full flex justify-center items-center mt-6">
                  <Link
                        to="/chooseAppoinment"
                        className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                      >
                        Sign Up
                      </Link>
                  <span className="w-full font-fontPoppins text-f9 text-white rounded-full font-normal bg-green-500 py-1 px-1">
                    20% OFF WELCOME COUPON
                  </span>
                </div>

                <div className="w-full flex mt-6">
                  <a
                    href={" "}
                    className="w-full font-fontPoppins text-base font-semibold hover:text-blueColor"
                  >
                    Refer a Friend
                  </a>
                </div>
              </div> */}
              </main>
            </nav>
            <div className="bg-black opacity-30 z-20 fixed left-0 top-0 inset-0"></div>
          </>
        )}
      </div>

      {/* <div className="bg-black opacity-30 z-20 fixed left-0 top-0 inset-0"></div> */}
    </header>
  );
}

export default Header;
