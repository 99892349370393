// import React, { useState, useEffect } from "react";
// import { MdArrowBack } from "react-icons/md";
// import { postData } from "../Networking/Api";
// import { useNavigate, useLocation } from "react-router-dom";
// import Header from "./Header";
// import Footer from "./Footer";

// const FAQ = () => {
//   const [faqItems, setFaqItems] = useState([]);
//   const [openItems, setOpenItems] = useState([]);
//   const [isFaqOpen, setIsFaqOpen] = useState(true);
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     fetchAllFAQItems();
//   }, []);

//   const fetchAllFAQItems = async () => {
//     let allFaqItems = [];
//     let page = 1;
//     const pageSize = 10;
//     let hasMoreData = true;

//     while (hasMoreData) {
//       try {
//         const response = await postData("masters/getMasterList", {
//           modelName: "faqs",
//           pagination: {
//             page,
//             pageSize,
//           },
//           is_active: 1,
//         });

//         if (response.code === 1) {
//           const fetchedItems = response.data;
//           allFaqItems = [...allFaqItems, ...fetchedItems];
//           if (fetchedItems.length < pageSize) {
//             hasMoreData = false;
//           } else {
//             page++;
//           }
//         } else {
//           console.error("Failed to fetch FAQs:", response.message);
//           hasMoreData = false;
//         }
//       } catch (error) {
//         console.error("Error fetching FAQs:", error);
//         hasMoreData = false;
//       }
//     }

//     // Sort the FAQ items by the sequence value
//     allFaqItems.sort((a, b) => a.sequence - b.sequence);
//     setFaqItems(allFaqItems);
//     setOpenItems(new Array(allFaqItems.length).fill(false));
//   };

//   const toggleItem = (index) => {
//     const newOpenItems = [...openItems];
//     newOpenItems[index] = !newOpenItems[index];
//     setOpenItems(newOpenItems);
//   };

//   const handleGoBack = () => {
//     navigate("/", { state: { showActiveModal: true } });
//   };

//   return (
//     <>
//       <Header />
//       <div className="pt-16">
//         {isFaqOpen && (
//           <div className="container mx-auto mb-8 px-4 sm:px-8">
//             <div className="py-8 text-center">
//               <div className="flex items-center justify-start mb-4">
//                 <MdArrowBack
//                   className="text-lg cursor-pointer"
//                   onClick={handleGoBack}
//                 />
//                 <span className="ml-2 cursor-pointer" onClick={handleGoBack}>
//                   Back
//                 </span>
//               </div>
//               <h2 className="text-3xl sm:text-2xl font-bold mb-4">FAQs</h2>
//               {faqItems.map((item, index) => (
//                 <div
//                   key={item.id}
//                   className="mb-4 p-4 rounded-lg shadow-md border border-gray-300"
//                 >
//                   <div
//                     className="flex justify-between items-center cursor-pointer text-left"
//                     onClick={() => toggleItem(index)}
//                   >
//                     <h3 className="font-bold text-lg sm:text-xl">
//                       {item.faq_title}
//                     </h3>
//                     <div className="flex-shrink-0">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="h-6 w-6"
//                         fill="none"
//                         viewBox="0 0 24 24"
//                         stroke="currentColor"
//                         strokeWidth={2}
//                       >
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           d={
//                             openItems[index]
//                               ? "M6 18L18 6M6 6l12 12"
//                               : "M12 4v16m8-8H4"
//                           }
//                         />
//                       </svg>
//                     </div>
//                   </div>
//                   {openItems[index] && (
//                     <div className="mt-2 pl-6 text-base sm:text-lg text-left">
//                       <div
//                         dangerouslySetInnerHTML={{ __html: item.description }}
//                       ></div>
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}
//         <Footer />
//       </div>
//     </>
//   );
// };

// export default FAQ;

import React, { useState, useEffect } from "react";
import { MdArrowBack } from "react-icons/md";
import { postData } from "../Networking/Api";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ProviderLandingPageFooter from "./ProviderLandingPageFooter/ProviderLandingPageFooter";

const FAQ = () => {
  const [faqItems, setFaqItems] = useState([]);
  const [openItems, setOpenItems] = useState([]);
  const [isFaqOpen, setIsFaqOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchAllFAQItems();
  }, []);

  const fetchAllFAQItems = async () => {
    let allFaqItems = [];
    let page = 1;
    const pageSize = 10;
    let hasMoreData = true;

    while (hasMoreData) {
      try {
        const response = await postData("masters/getMasterList", {
          modelName: "faqs",
          pagination: {
            page,
            pageSize,
          },
        });

        if (response.code === 1) {
          const fetchedItems = response.data;
          allFaqItems = [...allFaqItems, ...fetchedItems];
          if (fetchedItems.length < pageSize) {
            hasMoreData = false;
          } else {
            page++;
          }
        } else {
          console.error("Failed to fetch FAQs:", response.message);
          hasMoreData = false;
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
        hasMoreData = false;
      }
    }

    // Filter the FAQ items by active status and sort by sequence
    const activeFaqItems = allFaqItems
      .filter((item) => item.is_active === 1)
      .sort((a, b) => a.sequence - b.sequence);

    setFaqItems(activeFaqItems);
    setOpenItems(new Array(activeFaqItems.length).fill(false));
  };

  const toggleItem = (index) => {
    const newOpenItems = [...openItems];
    newOpenItems[index] = !newOpenItems[index];
    setOpenItems(newOpenItems);
  };

  const handleGoBack = () => {
    navigate("/", { state: { showActiveModal: true } });
  };

  return (
    <>
      <Header />
      <div className="pt-16">
        {isFaqOpen && (
          <div className="container mx-auto mb-8 px-4 sm:px-8">
            <div className="py-8 text-center">
              <div className="flex items-center justify-start mb-4">
                <MdArrowBack
                  className="text-lg cursor-pointer"
                  onClick={handleGoBack}
                />
                <span className="ml-2 cursor-pointer" onClick={handleGoBack}>
                  Back
                </span>
              </div>
              <h2 className="text-3xl sm:text-2xl font-bold mb-4">FAQs</h2>
              {faqItems.map((item, index) => (
                <div
                  key={item.id}
                  className="mb-4 p-4 rounded-lg shadow-md border border-gray-300"
                >
                  <div
                    className="flex justify-between items-center cursor-pointer text-left"
                    onClick={() => toggleItem(index)}
                  >
                    <h3 className="font-bold text-lg sm:text-xl">
                      {item.faq_title}
                    </h3>
                    <div className="flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d={
                            openItems[index]
                              ? "M6 18L18 6M6 6l12 12"
                              : "M12 4v16m8-8H4"
                          }
                        />
                      </svg>
                    </div>
                  </div>
                  {openItems[index] && (
                    <div className="mt-2 pl-6 text-base sm:text-lg text-left">
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <ProviderLandingPageFooter />
      </div>
    </>
  );
};

export default FAQ;
