import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Swal from "sweetalert2";
import { postData } from "../Networking/Api";
import { decrypt } from "./CryptoUtil";
import { MdStar } from "react-icons/md";
import moment from "moment";

export const RatingPage = () => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [clinicId, setClinicId] = useState("");
  const [errors, setErrors] = useState({});
  const { encryptedAppointmentId, encryptedPatientId } = useParams();
  // const decryptedAppointmentId = decrypt(
  //   decodeURIComponent(encryptedAppointmentId)
  // );
  // const decryptedPatientId = decrypt(decodeURIComponent(encryptedPatientId));
  const [bookingData, setBookingData] = useState(null);
  const [clinic_locations, setClinic_locations] = useState("");
  const [loading, setLoading] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!encryptedAppointmentId || !encryptedPatientId) {
      navigate("/");
    }
  }, [encryptedAppointmentId, encryptedPatientId, navigate]);

  useEffect(() => {
    const fetchBookingData = async () => {
      if (!encryptedPatientId) return;

      try {
        setLoading(true);

        const payload = {
          patient_id: encryptedPatientId,
          appointment_id: encryptedAppointmentId,
        };

        const response = await postData(
          "patients/getPatientsBookingHistory",
          payload
        );

        if (response.success) {
          const booking = response.data.find(
            (booking) => booking.id === parseInt(encryptedAppointmentId)
          );
          if (booking) {
            // console.log("booking.clinic_id------", booking.clinic_id);
            setClinicId(booking.clinic_id);
            setClinic_locations(booking?.clinic_locations?.google_business_link);
            setBookingData(booking);

            if (booking.is_submitted_ratting === 1) {
              Swal.fire({
                title: "Thank You",
                text: "Thanks For Your Review! It's Already Been Submitted",
                icon: "info",
              }).then(() => {
                navigate("/");
              });
            }
          } else {
            console.error("No matching booking data found");
          }
        } else {
          console.error("Failed to fetch booking data");
        }
      } catch (error) {
        console.error("Error fetching booking data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookingData();
  }, [encryptedAppointmentId, encryptedPatientId, navigate]);

  const handleStarClick = async (index) => {
    const newRating = index + 1;
    setRating(newRating);

    if (newRating >= 4) {
      if (clinic_locations != null) {
        const result = await Swal.fire({
          title: "Are you sure?",
          text: "Do You Want To Submit Your Rating On Google?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Submit It!",
        });

        if (result.isConfirmed) {
          const googleReviewUrl = clinic_locations;
          window.open(googleReviewUrl, "_blank");
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;
    const errors = {};

    if (rating === 0) {
      errors.rating = "Please select a rating";
      isValid = false;
    }
    if (!review.trim()) {
      errors.review =
        "Please Share Your Valueable Review Here. Your Feedback Matters!";
      isValid = false;
    }

    setErrors(errors);

    if (isValid) {
      try {
        var ratingName = '';
        if (rating == 1) {
          ratingName = 'ONE';
        } else if (rating == 2) {
          ratingName = 'TWO';
        } else if (rating == 3) {
          ratingName = 'THREE';
        } else if (rating == 4) {
          ratingName = 'FOUR';
        } else if (rating == 5) {
          ratingName = 'FIVE';
        }
        const currentDate = moment().format('YYYY-MM-DD');
        const firstPayload = {
          modelName: "patient_appointment_ratings",
          inputData: {
            patient_appointment_id: encryptedAppointmentId,
            rating: ratingName,
            comment: review,
            is_active: 1,
            is_submitted_rating: 1,
            is_rating_from: 0,
            clinic_id: clinicId,
            reviewtime: currentDate,
          },
        };

        const firstResponse = await postData(
          "masters/createAndUpdateMaster",
          firstPayload
        );

        if (firstResponse.code === 1) {
          const secondPayload = {
            modelName: "patient_appointments",
            id: encryptedAppointmentId,
            patient_id: encryptedPatientId,

            inputData: {
              is_submitted_ratting: 1,
            },
          };

          const secondResponse = await postData(
            "masters/createAndUpdateMaster",
            secondPayload
          );

          if (secondResponse.code === 1) {
            Swal.fire({
              title: "Success",
              text: "Review Submitted Successfully",
              icon: "success",
              timer: 2000,
              showConfirmButton: true,
            });

            setRating(0);
            setReview("");
            setErrors({});

            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            console.error(
              "Failed to update booking history:",
              secondResponse.error
            );
            Swal.fire({
              title: "Error",
              text: "Failed To Update Booking History",
              icon: "error",
            });
          }
        } else {
          console.error("Failed to submit review:", firstResponse.error);
          Swal.fire({
            title: "Error",
            text: "Failed To Submit Review",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error submitting review:", error);
        Swal.fire({
          title: "Error",
          text: "Failed To Submit Review",
          icon: "error",
        });
      }
    }
  };

  const formatDate = (dateTimeString) => {
    const utcTimestamp = dateTimeString;
    const localTimestamp = moment
      .utc(utcTimestamp)
      .tz("America/New_York")
      .format("MM/DD/YYYY");
    console.log("Retrieved local timestamp:", localTimestamp);
    return localTimestamp;
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: "UTC",
    };
    const date = new Date(dateTimeString);
    return date.toLocaleDateString("en-US", options);
  };

  const formatDateTime = (dateTimeString) => {
    const utcTimestamp = dateTimeString;
    const localTimestamp = moment
      .utc(utcTimestamp)
      .tz("America/New_York")
      .format("HH:mm A");
    console.log("Retrieved local timestamp:", localTimestamp);
    return localTimestamp;
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "UTC",
    };
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString(undefined, options);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow flex justify-center bg-gray-100">
        <div className="bg-white p-6 rounded-lg w-full max-w-2xl md:flex md:flex-row lg:flex-row xl:flex-row">
          <div
            className="md:w-1/2 xl:w-1/2 pr-8 mb-4 md:mb-0"
            style={{ margin: "4rem" }}
          >
            <h3
              className="mb-6 font-semibold text-2xl text-gray-900"
              style={{ fontFamily: "Arial, sans-serif" }}
            >
              <span className="flex items-center ">
                Star Rating Review Form
                <MdStar className="text-gray-800 ml-1" />
              </span>
            </h3>
            <p className="mb-6 font-semibold text-sm text-gray-500">
              YOUR WORDS MATTER....
            </p>
            <h2
              className="text-5xl mt-6 font-semibold mb-8"
              style={{ fontFamily: "serif" }}
            >
              We Would Love To Hear About Your Experience!
            </h2>

            {bookingData ? (
              <div className="grid grid-cols-1 gap-2">
                <div className="flex">
                  <strong className="w-1/2">Clinic:</strong>
                  <span>{bookingData.clinics?.name}</span>
                </div>
                <div className="flex">
                  <strong className="w-1/2">Practitioner:</strong>
                  <span className="truncate w-1/2">
                    {bookingData.practitioners?.f_name}{" "}
                    {bookingData.practitioners?.l_name}
                  </span>
                </div>
                <div className="flex">
                  <strong className="w-1/2">Appointment Date:</strong>
                  <span>{formatDate(bookingData?.appointment_date_time)}</span>
                </div>
                <div className="flex">
                  <strong className="w-1/2">Appointment Time:</strong>
                  <span>
                    {formatDateTime(bookingData.appointment_date_time)}
                  </span>
                </div>
                <div className="flex">
                  <strong className="w-1/2">Reason:</strong>
                  <span
                    className="w-1/2"
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                  >
                    {bookingData.reason || "N/A"}
                  </span>
                </div>
                <div className="flex">
                  <strong className="w-1/2">Status:</strong>
                  <span>{bookingData.status}</span>
                </div>
              </div>
            ) : (
              <p>No booking data found for this appointment ID.</p>
            )}
          </div>

          <div
            className="md:w-1/2 xl:w-1/2 bg-blue-50 rounded-lg shadow-xl border border-gray p-8 "
            style={{ margin: "5rem", boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}
          >
            <form onSubmit={handleSubmit}>
              <div className="mb-4 mt-4">
                <label className="block font-bold text-gray-700 mb-2">
                  Your Rating
                </label>
                <div className="flex items-center space-x-1">
                  {[...Array(5)].map((_, index) => (
                    <span
                      key={index}
                      className="text-4xl cursor-pointer"
                      style={{
                        color:
                          index < (hoverIndex !== null ? hoverIndex : rating)
                            ? "#f59e0b"
                            : "#718096",
                      }}
                      onClick={() => handleStarClick(index)}
                      onMouseEnter={() => setHoverIndex(index + 1)}
                      onMouseLeave={() => setHoverIndex(null)}
                    >
                      &#9733;
                    </span>
                  ))}
                </div>
                {errors.rating && (
                  <p className="text-red-500 text-xs">{errors.rating}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  className="block font-bold text-gray-700 mb-2"
                  htmlFor="review"
                >
                  Write Feedback
                </label>
                <textarea
                  id="review"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows="4"
                  placeholder="Share your thoughts..."
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                ></textarea>
                {errors.review && (
                  <p className="text-red-500 text-xs">{errors.review}</p>
                )}
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="w-auto py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:ring-4 focus:ring-blue-300"
                >
                  Submit Review
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
