import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import { postData, apiUrl } from "../../Networking/Api";
import Swal from "sweetalert2";
import Loader2 from "../Loader2";

function AssistantInfo() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    assistant_profile_photo: "",
  });
  const [errors, setErrors] = useState({});
  const [userDetails, setUserDetails] = useState([]);
  const [isRunnig, setIsRunning] = useState(false);
  const fileInputRef = useRef(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [assistanceisChecked, setAssistanceisChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    const storedClinicId = localStorage.getItem("clinicId");
    getAssistanceDetails(storedClinicId);
  }, []);
  const storedAccountType = localStorage.getItem("accountType");
  console.log("accountType....", storedAccountType);

  const checkEmailExists = async (email) => {
    try {
      const payload = {
        modelName: "assistances",
        whereCondition: {
          email: email,
          is_active: 1,
        },
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };

  // const getAssistanceDetails = async (clinicId) => {
  //   try {
  //     setLoading(true);
  //     var accountType = localStorage.getItem("accountType");
  //     var refernceId = localStorage.getItem("refernceId");

  //     const payload = {
  //       modelName: "assistances",
  //       whereCondition: {
  //         clinic_id: clinicId,
  //         is_active: 1,
  //       },
  //     };

  //     const response = await postData("masters/getMasterList", payload);

  //     setUserDetails(response.data);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error fetching clinic details:", error);
  //   }
  // };
  const getAssistanceDetails = async (clinicId) => {
    try {
      setLoading(true);
      const accountType = localStorage.getItem("accountType");
      const practitionerId = localStorage.getItem("refernceId");
      if (accountType === "Practitioners") {
        // Fetch the mapping data
        const payload = {
          modelName: "assistance_practitioners_mapping",
          pagination: {
            page: 1,
            pageSize: 500,
          },
          whereCondition: {
            practitioner_id: practitionerId,
            is_active: 1,
          },
          relations: [
            {
              module: "assistances",
            },
          ],
        };

        const response = await postData("masters/getMasterList", payload);

        if (response.code === 1) {
          // Extract assistance data from the response
          const assistanceData = response.data.map((item) => item?.assistances);
          setUserDetails(assistanceData);
        }
      } else {
        // Existing logic for non-Practitioners
        const payload = {
          modelName: "assistances",
          whereCondition: {
            clinic_id: clinicId,
            is_active: 1,
          },
        };

        const response = await postData("masters/getMasterList", payload);
        setUserDetails(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching clinic details:", error);
    }
  };

  const handleEdit = (index) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const provider = userDetails[index];
    setFormData({
      firstName: provider.f_name,
      lastName: provider.l_name,
      email: provider.email,
      assistant_profile_photo: provider.assistant_profile_photo,
    });
    setEditingIndex(index);
  };
  const handleDelete = async (index) => {
    try {
      if (index === editingIndex) {
        Swal.fire({
          title: "Cannot Delete!",
          text: "You Cannot Delete A Record While It Is Being Edited.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
      const assistantId = userDetails[index];
      const payload = {
        modelName: "assistances",
        id: assistantId.id,
        inputData: { is_active: 0 },
      };

      const confirmationResult = await Swal.fire({
        title: "Are You Sure?",
        text: "You Won't Be Able To Revert This!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete It!",
      });

      if (confirmationResult.isConfirmed) {
        const response = await postData(
          "masters/createAndUpdateMaster",
          payload
        );

        if (response.code === 1) {
          Swal.fire({
            title: "Deleted!",
            text: "Assistant Record Has Been Deleted.",
            icon: "success",
          });

          const updatedAssistant = [...userDetails];
          updatedAssistant.splice(index, 1);
          setUserDetails(updatedAssistant);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = async (e) => {
    var { name, value, files } = e.target;
    if (name === "firstName" || name === "lastName") {
      value = value.replace(/[^a-zA-Z]/g, "");
    }
    if (name === "email") {
      value = value.replace(/^\s+/, "");
    }
    if (name === "assistant_profile_photo" && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, assistant_profile_photo: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };
  const validateForm = async () => {
    let isValid = true;
    const errors = {};
    if (!formData.firstName) {
      isValid = false;
      errors.firstName = "First Name Is Required";
    } else if (formData.firstName.length < 3) {
      isValid = false;
      errors.firstName = "First Name Must Be At Least 3 Characters Long";
    }
    if (!formData.lastName) {
      isValid = false;
      errors.lastName = "Last Name Is Required";
    } else if (formData.lastName.length < 3) {
      isValid = false;
      errors.lastName = "Last Name Must Be At Least 3 Characters Long";
    }
    if (!formData.email.trim()) {
      isValid = false;
      errors.email = "Email Is Required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)
    ) {
      isValid = false;
      errors.email = "Invalid Email Format.";
    }
    // if (!formData.assistant_profile_photo) {
    //   isValid = false;
    //   errors.assistant_profile_photo = "Profile Photo Is Required";
    // }
    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!assistanceisChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }
    const isValid = await validateForm();
    if (isValid) {
      const storedClinicId = localStorage.getItem("clinicId");
      const clinicId = storedClinicId;
      const emailExists = await checkEmailExists(formData.email);
      if (
        emailExists &&
        (editingIndex === null ||
          (editingIndex !== null &&
            formData.email !== userDetails[editingIndex].email))
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "This Email Already Exists",
        }));
        return;
      }
      if (editingIndex !== null && isValid) {
        try {
          setLoading(true);
          const providerId = userDetails[editingIndex];

          const payload = {
            modelName: "assistances",
            id: providerId.id,
            inputData: {
              clinic_id: clinicId,
              f_name: formData.firstName,
              email: formData.email,
              l_name: formData.lastName,
              assistant_profile_photo: formData.assistant_profile_photo,
              is_active: 1,
            },
          };

          const response = await postData(
            "masters/createAndUpdateMaster",
            payload
          );
          setLoading(false);
          getAssistanceDetails(clinicId);

          if (response.code == 1) {
            setLoading(false);

            Swal.fire({
              title: "Success",
              text: "Assistance Info Updated Successfully",
              icon: "success",
              timer: 2000,
              showConfirmButton: true,
            });
          } else {
            setLoading(false);
            Swal.fire({
              title: "Error",
              text: "Error Updating Assistance Info ",
              icon: "error",
              timer: 2000,
              showConfirmButton: true,
            });
          }
          // setTimeout(() => {

          // }, 2000);

          setEditingIndex(null);
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            assistant_profile_photo: "",
          });
          fileInputRef.current.value = null;
          setErrors("");
        } catch (error) {
          console.error("Error:", error);
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const payload = {
            modelName: "assistances",
            inputData: {
              clinic_id: clinicId,
              f_name: formData.firstName,
              email: formData.email,
              l_name: formData.lastName,
              assistant_profile_photo: formData.assistant_profile_photo,
              is_active: "1",
            },
          };
          const response = await postData(
            "masters/createAndUpdateMaster",
            payload
          );
          getAssistanceDetails(clinicId);

          setLoading(false);

          if (response.code == 1) {
            Swal.fire({
              title: "Success",
              text: "Assistnce Info Added Successfully",
              icon: "success",
              timer: 2000,
              showConfirmButton: true,
            });
          } else {
            setLoading(false);
            Swal.fire({
              title: "Error",
              text: "Error Adding Assistance  ",
              icon: "error",
              timer: 2000,
              showConfirmButton: true,
            });
          }

          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            assistant_profile_photo: "",
          });
          fileInputRef.current.value = null;
        } catch (error) {
          console.error("Error:", error);
          setLoading(false);
        }
      }
    } else {
      console.log("Form validation failed. Cannot submit.");
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <div>
            <div>
              <div className="container mx-auto px-4 mt-8 ">
                <h1 className="font-fontPoppins md:text-2xl text-center font-semibold xss:text-xs sm:text-lg leading-normal mt-3 pb-7">
                  Front Office Team
                </h1>
                <div className="bg-gray-100 rounded-lg p-3">
                  <div className="w-full">
                    <div className="w-full md:flex items-center gap-3">
                      <div className="w-full relative">
                        <label className="font-fontPoppins text-xs font-semibold">
                          First Name<span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                        {errors.firstName && (
                          <p className="text-red-500 text-xs absolute">
                            {errors.firstName}
                          </p>
                        )}
                      </div>
                      <div className="w-full xss:mt-3 sm:mt-3 relative">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Last Name<span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          placeholder="Last Name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                        {errors.lastName && (
                          <p className="text-red-500 text-xs absolute">
                            {errors.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="w-full md:flex items-center gap-3 mt-3">
                      <div className="w-full">
                        <label className="font-fontPoppins  text-xs font-semibold">
                          Email<span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          placeholder="Enter Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <p className="text-red-500 text-xs">{errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="w-full md:flex flex-start gap-3 mt-3">
                      <div className="sm:w-80">
                        <label className="font-fontPoppins  text-xs font-semibold">
                          Profile Photo
                        </label>
                        <div className="flex items-center gap-3">
                          <div className="w-full relative">
                            <span className=" z-10 absolute px-3 py-1">
                              <i className="fa-solid fa-camera"></i>
                            </span>
                            <input
                              className="rounded-full w-full h-9 px-3 text-sm indent-5 py-1 font-fontPoppins text-gray-400  focus:outline-none focus:ring ring-gray-200 shadow"
                              // className="w-full h-9 indent-5  text-center rounded-full px-4 py-1 text-xs font-fontPoppins text-gray-400  focus:outline-none focus:ring ring-gray-200 shadow"
                              type="file"
                              placeholder="Upload"
                              name="assistant_profile_photo"
                              ref={fileInputRef}
                              onChange={handleChange}
                              accept="image/*"
                            />
                            {errors.assistant_profile_photo && (
                              <p className="text-red-500 text-xs">
                                {errors.assistant_profile_photo}
                              </p>
                            )}
                            {formData.assistant_profile_photo && (
                              <img
                                src={
                                  formData.assistant_profile_photo.startsWith(
                                    "data:"
                                  )
                                    ? formData.assistant_profile_photo
                                    : `${apiUrl}/${formData.assistant_profile_photo}` ||
                                      "/placeholder.png"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "/placeholder.png";
                                }}
                                alt="photo"
                                className="absolute top-1/2 transform -translate-y-1/2 right-2 w-9 h-9 rounded-full"
                              />
                            )}
                          </div>
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            //disabled={storedAccountType === "Practitioners"}
                            className="w-24 h-9 bg-blueColor text-white font-fontPoppins text-xs rounded-full"
                            // className={`w-24 h-9 text-white font-fontPoppins text-xs rounded-full ${
                            //   storedAccountType === "Practitioners"
                            //     ? "bg-gray-400 cursor-not-allowed"
                            //     : "bg-blueColor"
                            // }`}
                          >
                            {editingIndex !== null ? "Update" : "Add"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overflow-auto container mx-auto px-4 mt-8">
              <table className="overflow-auto  border-collapse table-auto w-full text-sm">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="rounded-tl-lg font-fontPoppins  bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                      First Name
                    </th>
                    <th className="font-fontPoppins bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                      Last Name
                    </th>
                    <th className="font-fontPoppins  bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                      Email
                    </th>
                    <th className="font-fontPoppins  bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                      Profile Photo
                    </th>
                    {/* {storedAccountType !== "Practitioners" && ( */}
                    <th className="rounded-tr-lg font-fontPoppins  bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                      Actions
                    </th>
                    {/* )} */}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {userDetails.map((details, index) => (
                    <tr>
                      <td className="font-fontPoppins  border-b border-slate-100 p-3 pl-8">
                        {details?.f_name}
                      </td>
                      <td className="font-fontPoppins  border-b border-slate-100 p-3 pl-8">
                        {details?.l_name}
                      </td>
                      <td className="font-fontPoppins  border-b border-slate-100 p-3 pl-8">
                        {details?.email}
                      </td>
                      <td className="font-fontPoppins  border-b border-slate-100 p-3 pl-8">
                        <img
                          src={`${apiUrl}/${details?.assistant_profile_photo}`}
                          alt=""
                          className=" w-10 h-10 rounded-full"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/placeholder.png";
                          }}
                        />
                      </td>
                      <td className="font-fontPoppins  border-b border-slate-100 p-3 pl-8">
                        <span
                          onClick={() => handleEdit(index)}
                          className="text-gray-700 mr-2 cursor-pointer hover:text-blueColor"
                        >
                          <i className="fa-solid fa-pencil"></i> Edit
                        </span>
                        {storedAccountType == "Clinic" && (
                          <span
                            onClick={() => handleDelete(index)}
                            className="text-gray-700 mr-2 cursor-pointer hover:text-red-500"
                          >
                            <i className="fa-solid fa-trash"></i> Delete
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex mt-4 items-center gap-2">
                <input
                  type="checkbox"
                  checked={assistanceisChecked}
                  onChange={(e) => setAssistanceisChecked(e.target.checked)}
                />

                <span className="font-fontPoppins text-xs font-semibold relative">
                  I have verified all the information and provide consent to
                  Samara Well to use and display this information accordingly.
                </span>
              </div>
              {errorMessage && (
                <p className="text-red-500  text-xs absolute">{errorMessage}</p>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </>
    </>
  );
}

export default AssistantInfo;
