import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postData, apiUrl } from "../../Networking/Api";
import moment from "moment/moment";
import Loader from "../Loader";
import Loader2 from "../Loader2";

const MySubscription = () => {
  const [metadata, setmetadata] = useState([]);
  const [isBuyBtn, setisBuyBtn] = useState(false);
  const [data, setdata] = useState([]);
  const [activeSubscription, setactiveSubscription] = useState([]);
  const [loading, setLoading] = useState(false);
  const webUrl = window.location.origin;

  useEffect(() => {
    getSubscriptionData();
    getActiveSubscriptionData();
  }, []);
  const accountType = localStorage.getItem("accountType");

  const getSubscriptionData = async () => {
    try {
      setLoading(true);
      const payload = {
        modelName: "subscription_masters",
        whereCondition: {
          type: "Clinic",
          is_active: 1,
        },
      };
      const Response = await postData("masters/getMasterList", payload);

      const filteredData = Response.data.filter((record) => record.price !== 0);
      setdata(filteredData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error checking email existence:", error);
      return false; // Error occurred or email doesn't exist
    }
  };
  const getActiveSubscriptionData = async () => {
    try {
      setLoading(true);
      const storedClinicId = localStorage.getItem("clinicId");
      const clinicId = storedClinicId;
      const payload = {
        modelName: "clinic_subscriptions",
        whereCondition: {
          clinic_id: clinicId,
          is_active: 1,
        },
      };
      const Response = await postData("masters/getMasterList", payload);
      const getActiveSubscriptionData = Response.data.filter(
        (record) => record.price !== 0
      );

      if (Response.code == 1) {
        console.log(
          "getActiveSubscriptionData-->",
          getActiveSubscriptionData[0]
        );
        setactiveSubscription(getActiveSubscriptionData[0]);
        setLoading(false);
      } else {
        console.log("Response--->else---->");
        setisBuyBtn(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error checking email existence:", error);
      return false; // Error occurred or email doesn't exist
    }
  };

  ///////////////////////////////
  const checkout = async (id) => {
    const storedClinicId = localStorage.getItem("clinicId");
    const clinicId = storedClinicId;
    setLoading(true);
    const res = await postData("stripegateway/checkEmailIsExist", metadata);
    // console.log("res createSubscriptionCheckoutSessionForSignup---->", res);
    if (res.code == 0) {
      setLoading(false);
      alert(res.message);
      return false;
    } else {
      console.log("clinicId--->", clinicId);
      fetch(
        apiUrl +
          "/api/stripegateway/createSubscriptionCheckoutSessionForSignup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({
            id: id,
            email: "Organization1111@gmail.com",
            metadata: "NA",
            apiUrl: webUrl,
            clinicId: clinicId,
          }),
        }
      )
        .then((res) => {
          setLoading(false);
          if (res.ok) return res.json();
          console.log(res);
          return res.json().then((json) => Promise.reject(json));
        })
        .then(({ session }) => {
          window.location = session.url;
        })
        .catch((e) => {
          setLoading(false);
          console.log(e.error);
        });
    }
  };

  const redirectToCustomerPortal = (stripe_customer_id) => {
    console.log("stripe_customer_id-1111---", stripe_customer_id);
    setLoading(true);
    //var vebURL = 'http://localhost:3000';
    fetch(apiUrl + "/api/stripegateway/create-customer-portal-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // eslint-disable-next-line no-undef
      body: JSON.stringify({ customerId: stripe_customer_id, apiUrl: webUrl }),
    })
      .then((res) => {
        // console.log("-------------create-customer-portal-session--------res----->", res);

        if (res.ok) return res.json();
        console.log(res);
        setLoading(false);
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location.href = url; // Redirect to the Customer Portal URL
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error redirecting to customer portal:", error);
      });
  };
  console.log("isBuybtn", isBuyBtn);
  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <>
            <div className="flex flex-col items-center w-full mx-auto min-h-screen diagonal-background overflow-x-hidden text-center">
              {!isBuyBtn && accountType !== "Assistances" && (
                <div className="col-6 mt-10">
                  <button
                    className="w-40 h-12 bg-blueColor text-white font-fontPoppins text-xs rounded-full"
                    onClick={() =>
                      redirectToCustomerPortal(
                        activeSubscription.stripe_customer_id
                      )
                    }
                  >
                    Manage Subscription
                  </button>
                </div>
              )}
              <div
                className="grid lg:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-5 z-40 place-items-center w-9/12 mx-auto
        mt-10"
              >
                {data.map((item, idx) => (
                  // <div key={idx} className={`bg-white px-6 py-8 rounded-xl text-[#4f7cff] w-full mx-auto grid
                  // place-items-center border border-gray-400`}>
                  <div
                    key={idx}
                    className={`bg-white px-6 py-8 rounded-xl text-[#4f7cff] w-full mx-auto grid
              place-items-center ${
                item.id === activeSubscription.subscription_id &&
                "border-[16px] border-green-500"
              } ${
                      item.id != activeSubscription.subscription_id &&
                      " border-[16px] border-gray-400"
                    }`}
                  >
                    <img
                      src="/basic.svg"
                      alt=""
                      width={200}
                      height={200}
                      className="h-40"
                    />
                    <div className="text-4xl text-slate-700 text-center py-4 font-bold">
                      {item.name}
                    </div>
                    <p className="lg:text-sm text-xs text-center px-6 text-slate-500">
                      Unlimited consultations with our experienced healthcare
                      providers.
                      <br />
                      Access to a variety of specialists without the need for
                      referrals.
                      <br />
                      Routine check-ups, preventive screenings, and wellness
                      exams included.
                    </p>

                    {item.id != activeSubscription.subscription_id ? (
                      <div className="text-4xl text-center font-bold py-4">
                        <span className="text-base line-through">
                          ${item.actual_price}
                        </span>
                        <br />
                        <span className="text-2xl ml-2">
                          Starting At -${item.price}
                        </span>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            fontSize: "2.25rem",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          <span style={{ fontSize: "1.25rem" }}>
                            Starting At -${activeSubscription.price}
                          </span>
                        </div>
                        {/* <div style={{ fontSize: '2.25rem', textAlign: 'center', fontWeight: 'bold' }}>
                      <span style={{ fontSize: '1.25rem', fontWeight: 'bold', color: "black" }}> Start Date:</span>
                      <span style={{ fontSize: '1.25rem', color: "black" }}>{moment(activeSubscription.start_date).format("MM-DD-YYYY")}</span>
                      <span style={{ fontSize: '1.25rem', fontWeight: 'bold', color: "black" }}> End Date:</span>
                      <span style={{ fontSize: '1.25rem', color: "black" }}>{moment(activeSubscription.end_date).format("MM-DD-YYYY")}</span>
                    </div> */}
                      </>
                    )}

                    <div className="mx-auto flex justify-center items-center my-3">
                      {item.id === activeSubscription.subscription_id ? (
                        <>
                          <div className="row">
                            <div className="col-6">
                              <button className="bg-green-600 text-white rounded-md text-base uppercase w-auto py-2 px-4 font-bold">
                                Subscribed
                              </button>
                            </div>
                            {/* <div className="col-6">
                          <button
                            className="bg-blue-500 text-white px-4 text-base uppercase w-auto py-2 px-4 font-bold rounded-lg mt-2"
                            onClick={() => redirectToCustomerPortal(activeSubscription.stripe_customer_id)}>
                            Manage Subscription
                          </button>
                        </div> */}
                          </div>
                        </>
                      ) : (
                        ""
                        // <button onClick={() => checkout(Number(item.id))} className="bg-[#3d5fc4] text-white rounded-md text-base uppercase w-24 py-2 font-bold" >Buy</button>
                      )}
                      {isBuyBtn ? (
                        <>
                          <button
                            onClick={() => checkout(Number(item.id))}
                            className="w-24 h-9 bg-blueColor text-white font-fontPoppins text-xs rounded-full"
                          >
                            Buy
                          </button>
                        </>
                      ) : (
                        ""

                        // <button onClick={() => checkout(Number(item.id))} className="bg-[#3d5fc4] text-white rounded-md text-base uppercase w-24 py-2 font-bold" >Buy</button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
          <Footer />
        </div>
      </>
    </>
  );
};
export default MySubscription;
