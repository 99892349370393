import { Link } from "react-router-dom";

function ClinicBookingFooter() {
  return (
    <>
      <footer className="w-full mt-7 mb-4">
        <div className="mx-auto md:flex md:items-center xss:text-center sm:text-center gap-3 px-4">
          <span className="text-xs font-fontPoppins text-gray-500">
            Secure scheduling by
          </span>
          <div className="xss:flex xss:justify-center sm:flex sm:justify-center">
            <Link to="/patientLanding">
              <img
                src="/samara-logo.png"
                alt="Samara Well Logo"
                width="133px"
              />
            </Link>
          </div>

          <nav className="ms-auto xss:text-center sm:text-center">
            <span
              href={" "}
              className="font-fontPoppins text-xs text-gray-500 hover:text-gray-700"
            >
              HIPAA Compliant Digital Health
            </span>

            <span className="font-fontPoppins text-xs text-gray-500"> | </span>
            <span
              href={" "}
              className="font-fontPoppins text-xs text-gray-500 hover:text-gray-700"
            >
              Privacy Policy
            </span>
            <span className="font-fontPoppins text-xs text-gray-500"> | </span>
            <span
              href={" "}
              className="font-fontPoppins text-xs text-gray-500 hover:text-gray-700"
            >
              Terms Of Service
            </span>
          </nav>
        </div>
      </footer>
    </>
  );
}

export default ClinicBookingFooter;
