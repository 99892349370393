// import React from "react";

// const EventPopup = ({
//   isOpen,
//   onClose,
//   onSave,
//   onDelete,
//   eventDetails,
//   setEventDetails,
//   isEditMode,
// }) => {
//   if (!isOpen) return null;

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setEventDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg w-96">
//         <h2 className="text-xl font-bold mb-4">
//           {isEditMode ? "Edit Event" : "Add Event"}
//         </h2>
//         <label className="block mb-2">
//           Title:
//           <input
//             type="text"
//             name="title"
//             value={eventDetails.title}
//             onChange={handleInputChange}
//             className="w-full border p-2 rounded"
//             disabled={isEditMode}
//           />
//         </label>
//         <label className="block mb-2">
//           Start Date/Time:
//           <input
//             type="datetime-local"
//             name="start"
//             value={eventDetails.start}
//             onChange={handleInputChange}
//             className="w-full border p-2 rounded"
//             disabled={isEditMode}
//           />
//         </label>
//         <label className="block mb-2">
//           End Date/Time:
//           <input
//             type="datetime-local"
//             name="end"
//             value={eventDetails.end}
//             onChange={handleInputChange}
//             className="w-full border p-2 rounded"
//             disabled={isEditMode}
//           />
//         </label>
//         <label className="block mb-4">
//           Description:
//           <textarea
//             name="description"
//             value={eventDetails.description}
//             onChange={handleInputChange}
//             className="w-full border p-2 rounded"
//             disabled={isEditMode}
//           />
//         </label>
//         <div className="flex justify-center space-x-4">
//           {isEditMode && (
//             <button
//               onClick={onDelete}
//               className="bg-red-500 text-white px-4 py-2 rounded"
//             >
//               Cancel
//             </button>
//           )}
//            {!isEditMode && (
//           <button
//             onClick={onSave}
//             className="bg-blue-500 text-white px-4 py-2 rounded"
//           >
//             {/* {isEditMode ? "Save Changes" : "Add Event"} */}
//             Add Event
//           </button>
//            )}
//           <button
//             onClick={onClose}
//             className="bg-gray-500 text-white px-4 py-2 rounded"
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EventPopup;
import React, { useEffect, useState } from "react";

const EventPopup = ({
  isOpen,
  onClose,
  onSave,
  onDelete,
  eventDetails,
  setEventDetails,
  isEditMode,
}) => {
  const [errors, setErrors] = useState({});
const [minDate, setMinDate] = useState(""); // State to hold the minimum date

useEffect(() => {
  // Set minimum date and time to the current date and time
  const now = new Date();
  const formattedNow = now.toISOString().slice(0, 16); // Format for datetime-local input
  setMinDate(formattedNow);
}, []);
  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
console.log("eventDetails.start", eventDetails.start);
  const validateFields = () => {
    const errors = {};

    if (!eventDetails.title) {
      errors.title = "Title Is Required";
    }
    if (!eventDetails.start) {
      errors.start = "Start Date And Time Are Required";
    }
    if (!eventDetails.end) {
      errors.end = "End Date And Time Are Required";
    } else if (new Date(eventDetails.end) <= new Date(eventDetails.start)) {
      errors.end = "End Date And Time Must Be After Start Date And Time";
    }
    if (!eventDetails.description) {
      errors.description = "Description Is Required";
    }

    return errors;
  };

  const handleSaveClick = () => {
    const validationErrors = validateFields();
    console.log("validationErrors", validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      onSave();
    } else {
      setErrors(validationErrors);
    }
  };
const handleClose = () => {
  setErrors({}); // Clear the validation errors
  onClose(); // Close the popup
};

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">
          {isEditMode ? "Edit Event" : "Add Event"}
        </h2>
        <label className="block mb-2">
          Title:
          <input
            type="text"
            name="title"
            value={eventDetails.title}
            onChange={handleInputChange}
            className="w-full border p-2 rounded"
            disabled={isEditMode}
          />
          {errors.title && <p className="text-red-500">{errors.title}</p>}
        </label>
        <label className="block mb-2">
          Start Date/Time:
          <input
            type="datetime-local"
            name="start"
            value={eventDetails.start || ""}
            onChange={handleInputChange}
            className="w-full border p-2 rounded"
            min={minDate}
            disabled={isEditMode}
          />
          {errors.start && <p className="text-red-500">{errors.start}</p>}
        </label>
        <label className="block mb-2">
          End Date/Time:
          <input
            type="datetime-local"
            name="end"
            value={eventDetails.end || ""}
            onChange={handleInputChange}
            className="w-full border p-2 rounded"
            min={eventDetails.start || minDate}
            disabled={isEditMode}
          />
          {errors.end && <p className="text-red-500">{errors.end}</p>}
        </label>
        <label className="block mb-4">
          Description:
          <textarea
            name="description"
            value={eventDetails.description}
            onChange={handleInputChange}
            className="w-full border p-2 rounded"
            disabled={isEditMode}
          />
          {errors.description && (
            <p className="text-red-500">{errors.description}</p>
          )}
        </label>
        <div className="flex justify-center space-x-4">
          {isEditMode && (
            <button
              onClick={onDelete}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          )}
          {!isEditMode && (
            <button
              onClick={handleSaveClick}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Event
            </button>
          )}
          <button
            onClick={handleClose}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventPopup;
