import React from "react";
import { postData } from "../Networking/Api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../ga";

const CommonModal = ({ isOpen, onClose, requestFrom, headerText }) => {
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    ehr: "",
  });
  const [errors, setErrors] = React.useState({});
  const navigate = useNavigate("");

  const handleChange = (e) => {
    var { name, value } = e.target;
    if (name === "firstName" || name === "lastName") {
      value = value.replace(/[^a-zA-Z]/g, "");
    }
    if (name === "email" || name === "companyName" || name === "ehr") {
      value = value.replace(/^\s+/, "");
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleConfirm = async (event) => {
    console.log("request", requestFrom);
    event.preventDefault();

    const isValid = await validateForm();
    if (isValid) {
      try {
        const payload = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          companyName: formData.companyName,
          ehr: formData.ehr,
          requestFrom: requestFrom,
        };
        console.log("request", requestFrom);
        const response = await postData(
          "novu/sendDemoEmailNotification",
          payload
        );

        Swal.fire({
          title: "Success",
          text: "Request Send successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: true,
        });

        logEvent(
          "User",
          requestFrom === "Pricing" ? "getPricing" : "contactUs",
          formData.email
        );

        setTimeout(() => {
          navigate(`/`);
        }, 2000);

        setFormData({
          firstName: "",
          lastName: "",
          email: "",

          companyName: "",
          ehr: "",
        });
      } catch (error) {
        console.error("Error sending data:", error);
      }
    }
  };

  const validateForm = async () => {
    let isValid = true;
    const errors = {};
    if (!formData.firstName) {
      isValid = false;
      errors.firstName = "First Name Is Required";
    } else if (formData.firstName.length < 2) {
      isValid = false;
      errors.firstName = "First Name Must Be At Least 2 Characters Long";
    }
    if (!formData.lastName) {
      isValid = false;
      errors.lastName = "Last Name Is Required";
    } else if (formData.lastName.length < 2) {
      isValid = false;
      errors.lastName = "Last Name Must Be At Least 2 Characters Long";
    }
    if (!formData.email.trim()) {
      isValid = false;
      errors.email = "Email Is Required";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)
    ) {
      isValid = false;
      errors.email = "Invalid Email Format";
    }
    if (!formData.companyName) {
      isValid = false;
      errors.companyName = "Company Name Is Required";
    }
    if (!formData.ehr) {
      isValid = false;
      errors.ehr = "EHR Is Required";
    }
    setErrors(errors);
    return isValid;
  };
  const handleClose = () => {
    setErrors({});
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      ehr: "",
    });
    onClose();
  };
  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center p-1 shadow-lg mt-3 bg-grayColor rounded-lg">
          <div
            className="bg-white rounded-lg shadow-lg"
            style={{
              width: "30rem",
              borderRadius: "0.5rem",
            }}
          >
            <div className="flex items-center justify-between px-8 mt-2 mb-2">
              <div className="flex-1 text-center">
                <h2 className="text-2xl font-bold">{headerText}</h2>
              </div>
              <div className="flex-none">
                <svg
                  onClick={handleClose}
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div
              className="px-8 pb-8"
              style={{
                maxHeight: "350px",
                overflowY: "auto",
              }}
            >
              <div className="mb-4">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-bold text-gray-700"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter The First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                />
                {errors.firstName && (
                  <p className="text-red-500 text-sm">{errors.firstName}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-bold text-gray-700"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter The Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                />
                {errors.lastName && (
                  <p className="text-red-500 text-sm">{errors.lastName}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-bold text-gray-700"
                >
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="companyName"
                  className="block text-sm font-bold text-gray-700"
                >
                  Company Name
                </label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="Enter Company Name"
                  value={formData.companyName}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                />
                {errors.companyName && (
                  <p className="text-red-500 text-sm">{errors.companyName}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="ehr"
                  className="block text-sm font-bold text-gray-700"
                >
                  EHR or PMS
                </label>
                <input
                  type="text"
                  id="ehr"
                  name="ehr"
                  placeholder="Enter EHR Or PMS"
                  value={formData.ehr}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                />
                {errors.ehr && (
                  <p className="text-red-500 text-sm">{errors.ehr}</p>
                )}
              </div>

              <div className="flex justify-end mb-3">
                <button
                  className="bg-blue-500 text-white font-bold px-4 py-2 rounded-md hover:bg-blue-700"
                  onClick={handleConfirm}
                >
                  Confirm
                </button>
                <button
                  className="ml-2 bg-gray-300 text-gray-800 font-bold px-4 py-2 rounded-md hover:bg-gray-400"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommonModal;
