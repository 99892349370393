import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";

const PopupModal = ({
  isOpen,
  onClose,
  googleLocations,
  samaraLocations,
  onSubmit,
}) => {
  const [selectedSamaraLocations, setSelectedSamaraLocations] = useState({});

  useEffect(() => {
    const initialSamaraLocations = googleLocations.reduce((acc, location) => {
      if (location.clinic_location_id) {
        acc[location.id] = location.clinic_location_id;
      }
      return acc;
    }, {});
    setSelectedSamaraLocations(initialSamaraLocations);
  }, [googleLocations]);

  const handleSamaraLocationChange = (locationId, samaraLocationId) => {
    setSelectedSamaraLocations({
      ...selectedSamaraLocations,
      [locationId]: samaraLocationId,
    });
  };

  const handleSubmit = () => {
    const mappings = googleLocations.map((location) => ({
      id: location.id,
      clinic_location_id: selectedSamaraLocations[location.id] || "",
    }));
    console.log(mappings);
    onSubmit(mappings);
    onClose();
    resetSelections();
  };

  const handleCancel = () => {
    resetSelections();
    onClose();
  };

  const resetSelections = () => {
    setSelectedSamaraLocations({});
  };

  if (!isOpen) return null;

  // Create a Set of selected Samara locations to filter options
  const selectedLocationsSet = new Set(Object.values(selectedSamaraLocations).filter(id => id));

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-60 lg:w-1/2">
        <h2 className="text-xl font-bold mb-4 text-center">
          {/* Locations Mapping */}
          Select To Match The Google Business Location
        </h2>
        <ul className="mb-4 space-y-2 lg:space-y-4">
          {googleLocations.map((location) => (
            <li
              key={location.id}
              className="flex flex-row lg:flex-row items-center justify-between bg-gray-100 p-2 rounded-md lg:p-4"
            >
              <span className="text-base w-1/2 mb-2 lg:mb-0">
                {location.label}
              </span>
              {/* <select
                value={selectedSamaraLocations[location.id] || ""}
                onChange={(e) =>
                  handleSamaraLocationChange(location.id, e.target.value)
                }
                className="block w-full lg:w-1/2 px-4 py-2 border border-gray-300 rounded-md"
              >
                <option value="">Select a Samara location</option>
                {samaraLocations
                  .filter(
                    (samaraLocation) =>
                      !selectedLocationsSet.has(samaraLocation.id) ||
                      samaraLocation.id === selectedSamaraLocations[location.id]
                  )
                  .map((samaraLocation) => (
                    <option key={samaraLocation.id} value={samaraLocation.id}>
                      {samaraLocation.label}
                    </option>
                  ))}
              </select> */}
              <ReactSelect
                value={samaraLocations.find(
                  (samaraLocation) =>
                    samaraLocation.id === selectedSamaraLocations[location.id]
                )}
                onChange={(selectedOption) =>
                  handleSamaraLocationChange(location.id, selectedOption.value)
                }
                options={samaraLocations
                  .filter(
                    (samaraLocation) =>
                      !selectedLocationsSet.has(samaraLocation.id) ||
                      samaraLocation.id === selectedSamaraLocations[location.id]
                  )
                  .map((samaraLocation) => ({
                    value: samaraLocation.id,
                    label: samaraLocation.label,
                  }))}
                className="block w-1/2"
                classNamePrefix="react-select"
                placeholder="Select To Match The Google Business Location"
              />
            </li>
          ))}
        </ul>
        <div className="flex justify-center space-x-4">
          <button
            onClick={handleCancel}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;