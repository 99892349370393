import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { useState, useEffect, useRef } from "react";
import StepsForRegistration from "./StepsForRegistration";
import { postData } from "../Networking/Api";
import Loader2 from "./Loader2";

function BrandIdentityAndMedia() {
  const location = useLocation();
  const {
    userDetails,
    organizationData,
    providerData,
    clinicServicesMapping,
    clinicServicesData,
    providerTimeslots,
    records,
    addLocations,
  } = location.state || {};

  const navigate = useNavigate();
  const [logoImage, setLogoImage] = useState("");
  const [facilityImages, setFacilityImages] = useState([]);
  const [OrganizationData, setOrganizationData] = useState({});
  const [ProviderData, setProviderData] = useState({});
  const [ClinicService, setClinicServicesData] = useState({});
  const [ClinicServiceMapping, setClinicServicesMapping] = useState({});
  const [addLocation, setAddLocation] = useState([]);
  const [providersTimeslot, setProvidersTimeSlot] = useState({});
  const [UserDetails, setUserDetails] = useState([]);
  const [recordss, setRecords] = useState([]);
  const [loading, setLoading] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const organizationId = localStorage.getItem("organizationId");

      if (organizationId) {
        try {
          setLoading(true);
          const payload = {
            modelName: "subscription_temp",
            whereCondition: {
              id: parseInt(organizationId),
            },
          };

          const apiResponse = await postData("masters/getMasterList", payload);

          if (apiResponse.code === 1 && apiResponse.data.length > 0) {
            setLoading(false);
            const parsedPayloadData = JSON.parse(
              apiResponse.data[0].payload_data
            );

            const {
              clinic_services_data,
              clinic_services_mapping,
              practitioners_data,
              providerTimeslots,
              clinics_data,
              clinic_locations,
              assistances,
              clinic_insurances,
              clinic_photo,
            } = parsedPayloadData;
            setUserDetails(assistances || []);
            setClinicServicesData(clinic_services_data || []);
            setClinicServicesMapping(clinic_services_mapping || []);
            setAddLocation(clinic_locations || []);
            setProvidersTimeSlot(providerTimeslots || []);
            setOrganizationData(clinics_data || []);
            setProviderData(practitioners_data || []);
            setRecords(clinic_insurances || []);
            setFacilityImages(clinic_photo || []);
            if (clinics_data && clinics_data.logo_image) {
              setLogoImage(clinics_data.logo_image);
            }
          } else {
            setLoading(false);
            console.error("No data found for organizationId:", organizationId);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  const handleLogoImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const logoImageBase64 = e.target.result.toString();
          setLogoImage(logoImageBase64);
          setOrganizationData((prevData) => ({
            ...prevData,
            logo_image: logoImageBase64,
          }));
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFacilityImageUpload = (e) => {
    const files = e.target.files;
    if (files) {
      const imageFiles = Array.from(files);
      const promises = imageFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e.target && e.target.result) {
              resolve({ file: e.target.result.toString(), is_active: 1 });
            } else {
              reject("Failed to read file");
            }
          };
          reader.onerror = () => {
            reject("File reading failed");
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises)
        .then((newImages) => {
          const updatedFacilityImages = [...facilityImages, ...newImages];
          setFacilityImages(updatedFacilityImages);
        })
        .catch((error) => {
          console.error("Error reading files:", error);
        });
    }
  };

  const removeAllImages = () => {
    fileInputRef.current.value = null;
    setFacilityImages([]);
    localStorage.removeItem("facilityImages");
  };

  const handleNavigate = async () => {
    try {
      setLoading(true);
      const organizationId = localStorage.getItem("organizationId");
      let payload;

      if (organizationId) {
        const fetchPayload = {
          modelName: "subscription_temp",
          whereCondition: {
            id: parseInt(organizationId),
          },
        };

        const apiResponse = await postData(
          "masters/getMasterList",
          fetchPayload
        );

        let existingData = {};
        if (apiResponse.code === 1 && apiResponse.data.length > 0) {
          existingData = JSON.parse(apiResponse.data[0].payload_data);
        }
        console.log(
          "updated",

          facilityImages
        );
        const isFirstTime = !existingData.clinic_photo;

        if (isFirstTime) {
          payload = {
            modelName: "subscription_temp",
            id: organizationId,
            inputData: {
              payload_data: JSON.stringify({
                clinics_data: {
                  ...OrganizationData,
                  logo_image: logoImage,
                },
                practitioners_data: ProviderData,
                clinic_services_data: ClinicService,
                clinic_services_mapping: ClinicServiceMapping,
                clinic_locations: addLocation,
                providerTimeslots: providersTimeslot,
                assistances: UserDetails,
                clinic_insurances: recordss,
                clinic_photo: facilityImages,
              }),
            },
          };
        } else {
          const updatedData = {
            ...existingData,
            clinics_data:
              {
                ...existingData.clinics_data,
                logo_image: logoImage,
              } || OrganizationData,
            practitioners_data: existingData.practitioners_data || ProviderData,
            clinic_services_data:
              existingData.clinic_services_data || ClinicService,
            clinic_services_mapping:
              existingData.clinic_services_mapping || ClinicServiceMapping,
            providerTimeslots:
              existingData.providerTimeslots || providersTimeslot,
            clinic_locations: existingData.clinic_locations || addLocation,
            assistances: existingData.assistances || UserDetails,
            clinic_insurances: existingData.clinic_insurances || recordss,
            clinic_photo: facilityImages,
          };

          payload = {
            modelName: "subscription_temp",
            id: organizationId,
            inputData: {
              payload_data: JSON.stringify(updatedData),
            },
          };
          console.log("updatedData", updatedData);
        }
      }

      const response = await postData("masters/createAndUpdateMaster", payload);
      setLoading(false);
      navigate("/pricing", {
        state: {
          userDetails,
          organizationData,
          providerData,
          clinicServicesMapping,
          clinicServicesData,
          providerTimeslots,
          records,
          facilityImages,
          addLocations,
        },
      });
    } catch (error) {
      setLoading(false);
      console.error("Error saving data:", error);
    }
  };
  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />

        <div className="pt-16">
          <div className="container mx-auto px-4 flex items-center gap-16 xss:gap-2 sm:gap-5">
            <StepsForRegistration />
            <h1 className="font-fontPoppins text-2xl font-semibold xss:text-xs sm:text-lg leading-normal">
              Brand Identity & Media
            </h1>
          </div>

          <section className="w-full">
            <div className="container mx-auto px-4">
              <div className="bg-grayColorLight rounded-lg p-3">
                <div className="w-full">
                  {/* <div className="w-full">
                  <label className="font-fontPoppins text-xs font-semibold">
                    Add Clinic/Brand Logo
                  </label>
                  <div className="md:w-80"></div>
                </div> */}

                  <div className="w-full mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Add Media Showcasing Your Facility
                    </label>
                    <div className="md:w-80">
                      <div className="w-full relative">
                        <span className="z-10 absolute left-3 top-3">
                          <i className="fa-solid fa-camera"></i>
                        </span>
                        <input
                          onChange={handleFacilityImageUpload}
                          className="w-full h-9 indent-5 rounded-full px-4 py-1 font-fontPoppins text-gray-400 mt-2 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="file"
                          accept="image/*"
                          placeholder="Upload"
                          multiple
                          ref={fileInputRef}
                        />

                        <div className="mt-3 flex flex-wrap w-full gap-4">
                          {facilityImages.map((imageUrl, index) => (
                            <div key={index} className="relative">
                              <img
                                src={imageUrl.file}
                                alt={`Selected Image ${index + 1}`}
                                className="object-cover w-20 h-20"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full mt-3">
                    <button
                      onClick={removeAllImages}
                      className="font-fontPoppins text-xs font-normal text-gray-700 border-0 hover:text-red-500"
                    >
                      <i className="fa-solid fa-trash"></i> Remove All
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="w-full mt-10 xss:mt-5 sm:mt-5 lg:min-h-190">
            <div className="container mx-auto px-4 text-center">
              <button
                onClick={handleNavigate}
                className="w-52 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
              >
                <Link
                  to="/pricing"
                  state={{
                    userDetails,
                    organizationData,
                    providerData,
                    clinicServicesMapping,
                    clinicServicesData,
                    providerTimeslots,
                    records,
                    facilityImages,
                    clinicServicesData,
                  }}
                >
                  <span className="me-2">Skip / Subscription</span>
                  <i className="fa-solid fa-arrow-right-long"></i>
                </Link>
              </button>
            </div>
          </section>

          <Footer />
        </div>
      </>
    </>
  );
}

export default BrandIdentityAndMedia;
