// import { useState } from "react";
// import Header from "./Header";
// import Footer from "./Footer";
// import { useLocation, useNavigate } from "react-router-dom";
// import { postData } from "../Networking/Api";
// import StepsForRegistration from "./StepsForRegistration";

// function PaymentSetup() {
//   const [isOpen, setIsOpen] = useState(false);
//   const location = useLocation();
//    const navigate = useNavigate()
//   const {userDetails,organizationData,providerData,clinicServicesMapping,clinicServicesData,records,facilityImages,subscriptionData} = location.state || {};
//   console.log( 'wkpkpl[qwlkfkfkfkfk',{userDetails,organizationData,providerData,clinicServicesMapping,clinicServicesData,records,facilityImages,subscriptionData});
//   console.log("images",facilityImages);
//   console.log("userDatailsPayment",userDetails);
//   console.log("recordsPayment",records);
//   console.log("recordsServices",clinicServicesData);
//   console.log("paymentServiceProvider",clinicServicesMapping)
//   console.log("subscriptionData",subscriptionData);
//   console.log("PaymentOrg",organizationData);
//   console.log("payProvider",providerData)

//   const [formData, setFormData] = useState({
//     cardNumber: "",
//     expiration: "",
//     cvc: "",
//     country: "",
//     password: "",
//     is_active: 1,
//   });
//   const [errors, setErrors] = useState({});
//   const validateForm = () => {
//     let isValid = true;
//     const errors = {};
//     if (!formData.cardNumber) {
//       isValid = false;
//       errors.cardNumber = "Card Number is required";
//     }
//     if (!formData.expiration)
//       {
//         isValid = false;
//         errors.expiration = "Expiration  is required";
//       } 
//     if (!formData.cvc) {
//       isValid = false;
//       errors.cvc = 'CVC is required.';
//     } 
//     if (!formData.country) {
//       isValid = false;
//       errors.country = "Country is required";
//     }
//     if (!formData.password) {
//       isValid = false;
//       errors.password = "Password is required";
//     }
//     setErrors(errors);
//     return isValid;
//   };
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === 'expiration') {
//       const numericValue = value.replace(/[^\d]/g, '');
//       let formattedValue = numericValue;
//       if (numericValue.length >= 3) {
//         formattedValue = numericValue.slice(0, 2) + '/' + numericValue.slice(2, 4);
//       }
//       setFormData({ ...formData, [name]: formattedValue });
//     } else if (name === 'cardNumber' || name === 'cvc') {
//       // Allow only numeric input
//       const numericValue = value.replace(/[^\d]/g, '');
//       setFormData({ ...formData, [name]: numericValue });
//     } else {
//       setFormData({ ...formData, [name]: value });
//     }
//     setErrors({ ...errors, [name]: '' });
//   };
//   const handleSubmit = async () => {
//     if(validateForm()){
//       console.log("PaymentData",formData);
//       console.log("UserPostDetails",{ 
//         clinics_data: organizationData,
//         practitioners_data:providerData,
//         clinic_photo:facilityImages,
//         assistances:userDetails,
//         clinic_insurances:records,
//         clinic_card_details:formData,
//         clinic_services_data:clinicServicesData,
//         clinic_services_mapping:clinicServicesMapping,
//         clinic_subscriptions:subscriptionData,   
//       });
//       try {
//         const payload = {
//           clinics_data: organizationData,
//           practitioners_data:providerData,
//           clinic_photo:facilityImages,
//           assistances:userDetails,
//           clinic_insurances:records,
//           clinic_card_details:formData,
//           clinic_services_data:clinicServicesData,
//           clinic_services_mapping:clinicServicesMapping,
//           clinic_subscriptions:subscriptionData,   
//         };
//         console.log('payloadpayload',payload);
//         const response = await postData('auth/clinicSignupCreate', payload);
//         console.log("ClinicSignupResponse",response);
//         if(response.code==1){
//           setIsOpen(true); // Open the popup after form submission
//           localStorage.clear();
//           if(setIsOpen){
//             setTimeout(() => {
//               navigate('/outpatientPracticeDetails2')
//             }, 5000);
//           }
        
         
//         }
    
//       } catch (error) {
//         if (error && error.response) {
//           console.log('Invalid credentials. Please try again.');
//         } else {
//           console.error("Error posting data to API", error);
//         }
//       }
//       // setIsOpen(true); // Open the popup after form submission
//       // localStorage.clear();
//     } 
//   };
//   return (
//     <>
//       <Header />

//       {/* <section className="w-full py-2">
//         <div className="container mx-auto px-4 flex items-center gap-80 xss:gap-2 sm:gap-5">
//           <div>
//             <p className="font-fontPoppins text-xs text-gray-400">
//               Steps for registration
//             </p>
//             <nav
//               className="bg-repeat-x bg-center flex items-center text-center gap-3 xss:gap-2 mt-1"
//               style={{ backgroundImage: "url(./images/d-line.png)" }}
//             >
//               <span
//                 className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins border-2 border-solid border-greenColorDark bg-greenColorDark text-white rounded-lg cursor-pointer"
//                 title="CLINIC DETAILS"
//               >
//                 1
//               </span>
//               <span
//                 className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins border-2 border-solid border-greenColorDark bg-greenColorDark text-white rounded-lg cursor-pointer"
//                 title="CLINIC DETAILS"
//               >
//                 2
//               </span>
//               <span
//                 className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins border-2 border-solid border-greenColorDark bg-greenColorDark text-white rounded-lg cursor-pointer"
//                 title="CLINIC DETAILS"
//               >
//                 3
//               </span>
//               <span
//                 className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins border-2 border-solid border-greenColorDark bg-greenColorDark text-white rounded-lg cursor-pointer"
//                 title="CLINIC DETAILS"
//               >
//                 4
//               </span>
//               <span
//                 className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins border-2 border-solid border-greenColorDark bg-greenColorDark text-white rounded-lg cursor-pointer"
//                 title="CLINIC DETAILS"
//               >
//                 5
//               </span>
//               <span
//                 className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins border-2 border-solid border-greenColorDark bg-greenColorDark text-white rounded-lg cursor-pointer"
//                 title="CLINIC DETAILS"
//               >
//                 6
//               </span>
//               <span className="h-5 w-5 flex items-center justify-center text-xs font-fontPoppins border-2 border-solid border-greenColorDark bg-greenColorLight text-greenColorDark rounded-lg cursor-pointer">
//                 7
//               </span>
//             </nav>
//           </div>

//           <h1 className="font-fontPoppins text-2xl font-semibold xss:text-xs sm:text-lg leading-normal">
//             Payment Setup
//           </h1>
//         </div>
//       </section> */}
//       <div className="container mx-auto px-4 flex items-center gap-16 xss:gap-2 sm:gap-5">
//       <StepsForRegistration/>
//       <h1 className="font-fontPoppins text-2xl font-semibold xss:text-xs sm:text-lg leading-normal">
//       Payment Setup
//           </h1>
//           </div>

//       <section className="w-full">
//         <div className="container mx-auto px-4">
//           <div className="bg-grayColorLight rounded-lg p-3">
//             <div className="lg:w-1/3 sm:w-4/5 md:w-4/5 mx-auto">
//               <div className="w-full flex justify-between gap-2">
//                 <button className="bg-white rounded-lg ease-in-out shadow-sm hover:shadow-lg border border-blue-500 hover:translate-x-1 w-20 h-12">
//                   <i className="fa-solid fa-credit-card"></i>
//                   <span className="font-fontPoppins text-xs font-medium">
//                     Card
//                   </span>
//                 </button>
//                 <button className="bg-white rounded-lg ease-in-out shadow-sm hover:shadow-lg hover:translate-x-1 w-20 h-12">
//                   <i className="fa-brands fa-apple-pay text-3xl"></i>
//                 </button>
//                 <button className="bg-white rounded-lg ease-in-out shadow-sm hover:shadow-lg hover:translate-x-1 w-20 h-12">
//                   <i className="fa-brands fa-amazon-pay text-3xl"></i>
//                 </button>
//                 <button className="bg-white rounded-lg ease-in-out shadow-sm hover:shadow-lg hover:translate-x-1 w-20 h-12">
//                   <i className="fa-brands fa-paypal text-3xl"></i>
//                 </button>
//                 <button className="bg-white rounded-lg ease-in-out shadow-sm hover:shadow-lg hover:translate-x-1 w-20 h-12">
//                   <i className="fa-solid fa-building-columns text-3xl"></i>
//                 </button>
//               </div>

//               <div className="w-full mt-3">
//                 <div>
//                   <label className="font-fontPoppins text-xs font-semibold">
//                     Card Number
//                   </label>
//                   <div className="w-full relative">
//                     <span className="z-10 absolute right-3 top-2">
//                       <i className="fa-brands fa-cc-mastercard text-red-500"></i>
//                       <i className="fa-brands fa-cc-visa text-blue-500"></i>
//                       <i className="fa-brands fa-cc-mastercard text-blue-400"></i>
//                       <i className="fa-brands fa-cc-jcb text-red-500"></i>
//                     </span>
//                     <input
//                       className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
//                       type="text"
//                       placeholder="xxxx xxxx xxxx"
//                       name="cardNumber"
//                       value={formData.cardNumber}
//                       maxLength={12}
//                       onChange={handleChange}
//                     />
//                     {errors.cardNumber && (
//                       <p className="text-red-500 text-xs">{errors.cardNumber}</p>
//                     )}
//                   </div>
//                 </div>

//                 <div className="w-full md:flex justify-between gap-3 mt-3">
//                   <div className="w-full">
//                     <label className="font-fontPoppins text-xs font-semibold">
//                       Expiration
//                     </label>
//                     <input
//                       className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
//                       type="text"
//                       placeholder="MM/YY"
//                       name="expiration"
//                       value={formData.expiration}
//                       onChange={handleChange}
//                     />
//                     {errors.expiration && (
//                       <p className="text-red-500 text-xs">{errors.expiration}</p>
//                     )}
//                   </div>
//                   <div className="w-full xss:mt-3 sm:mt-3">
//                     <label className="font-fontPoppins text-xs font-semibold">
//                       CVC
//                     </label>
//                     <input
//                       className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
//                       type="text"
//                       placeholder="CVC"
//                       name="cvc"
//                       value={formData.cvc}
//                       onChange={handleChange}
//                       maxLength={4}
//                     />
//                     {errors.cvc && (
//                       <p className="text-red-500 text-xs">{errors.cvc}</p>
//                     )}
//                   </div>
//                 </div>

//                 <div className="w-full md:flex justify-between gap-3 mt-3">
//                   <div className="w-full">
//                     <label className="font-fontPoppins text-xs font-semibold">
//                       Country
//                     </label>
//                     <input
//                       className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
//                       type="text"
//                       placeholder="United States of America"
//                       name="country"
//                       value={formData.country}
//                       onChange={handleChange}
//                     />
//                     {errors.country && (
//                       <p className="text-red-500 text-xs">{errors.country}</p>
//                     )}
//                   </div>
//                 </div>

//                 <div className="w-full md:flex justify-between gap-3 mt-3">
//                   <div className="w-full">
//                     <label className="font-fontPoppins text-xs font-semibold">
//                       Password
//                     </label>
//                     <input
//                       className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
//                       type="password"
//                       placeholder="**********"
//                       name="password"
//                       value={formData.password}
//                       onChange={handleChange}
//                     />
//                     {errors.password && (
//                       <p className="text-red-500 text-xs">{errors.password}</p>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className="w-full mt-5 xss:mt-5 sm:mt-5 lg:min-h-10">
//         <div className="container mx-auto px-4 text-center">
//           <button
//             onClick={handleSubmit}
//             className="w-24 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
//           >
//             <span>Finish</span>
//           </button>
//         </div>
//       </section>

//       {isOpen && (
//         <div className="like-popup w-full h-full bg-gray-400 opacity-95 z-20 fixed inset-0">
//           <div className="w-full text-right">
//             <span
//               onClick={() => setIsOpen(!isOpen)}
//               className="closeBtn ml-auto cursor-pointer text-gray-800 mr-4 mt-4"
//             >
//               <i className="fa-solid fa-xmark"></i>
//             </span>
//           </div>
//           <div className="w-full h-full flex items-center justify-center">
//             <div className="w-80 bg-white p-3 rounded-lg opacity-1 py-8">
//               <p className="text-center text-lg font-fontPoppins font-bold">
//                 Your 30-day Free Membership Starts Now!
//               </p>
//               <div className="relative">
//                 <button className="w-12 h-12 bg-blueColor rounded-full border-0 z-30 absolute left-1/2 -ms-6 top-2">
//                   <i className="fa-solid fa-thumbs-up text-white"></i>
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       <Footer />
//     </>
//   );
// }

// export default PaymentSetup;










import { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { postData } from "../Networking/Api";
import StepsForRegistration from "./StepsForRegistration";

function PaymentSetup() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
   const navigate = useNavigate()
  const {userDetails,organizationData,providerData,clinicServicesMapping,clinicServicesData,records,facilityImages,subscriptionData} = location.state || {};
  console.log( 'details',{userDetails,organizationData,providerData,clinicServicesMapping,clinicServicesData,records,facilityImages,subscriptionData});


  const [formData, setFormData] = useState({
    cardNumber: "",
    expiration: "",
    cvc: "",
    country: "",
    password: "",
    is_active: 1,
  });
  const [errors, setErrors] = useState({});
  const [userPostDetails,setUserPostDetails] = useState({
    organizationData : '',
    providerData:'',
    clinicServicesData: '',
    cliniServicesMapping: '',
    userDetails: '',
    records: '',
    facilityImages: '',
    subscription:'',
  });

  console.log("UserPostDetails",{ 
    clinics_data: userPostDetails.organizationData,
    practitioners_data:userPostDetails.providerData,
    clinic_photo:userPostDetails.facilityImages,
    assistances:userPostDetails.userDetails,
    clinic_insurances:userPostDetails.records,
    clinic_card_details:formData,
    clinic_services_data:userPostDetails.clinicServicesData,
    clinic_services_mapping:userPostDetails.cliniServicesMapping,
    clinic_subscriptions:userPostDetails.subscription,  

  });

  
  useEffect(()=> {
  const organizationData = JSON.parse(localStorage.getItem('organizationData')) || [];
  setUserPostDetails((prevDetails) => ({
    ...prevDetails,
    organizationData: organizationData,
  }));
  console.log('organizationData',organizationData);

  const providerData = JSON.parse(localStorage.getItem('providerData')) || [];
  setUserPostDetails((prevDetails) => ({
    ...prevDetails,
    providerData: providerData,
  }));
  console.log('providerData',providerData);

  const clinicServicesData = JSON.parse(localStorage.getItem('clinic_services_data')) || [];
  setUserPostDetails((prevDetails) => ({
    ...prevDetails,
    clinicServicesData: clinicServicesData,
  }));
  console.log('clinicServicesData',clinicServicesData);
  
  const cliniServicesMapping = JSON.parse(localStorage.getItem('clinic_services_mapping')) || [];
  setUserPostDetails((prevDetails) => ({
    ...prevDetails,
    cliniServicesMapping: cliniServicesMapping,
  }));
  console.log('cliniServicesMapping',cliniServicesMapping);
  
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || [];
  setUserPostDetails((prevDetails) => ({
    ...prevDetails,
    userDetails: userDetails,
  }));
  console.log('userDetails',userDetails);
  
  const records = JSON.parse(localStorage.getItem('records')) || [];
  setUserPostDetails((prevDetails) => ({
    ...prevDetails,
    records: records,
  }));
  console.log('records',records);

  const facilityImages = JSON.parse(localStorage.getItem('facilityImages')) || [];
  setUserPostDetails((prevDetails) => ({
    ...prevDetails,
    facilityImages: facilityImages,
  }));
  console.log('facilityImages',facilityImages);

  const subscription = JSON.parse(localStorage.getItem('subscription')) || [];
  setUserPostDetails((prevDetails) => ({
    ...prevDetails,
    subscription: subscription,
  }));
  console.log('subscription',subscription);
  },[])
  const validateForm = () => {
    let isValid = true;
    const errors = {};
    if (!formData.cardNumber) {
      isValid = false;
      errors.cardNumber = "Card Number is required";
    }
    if (!formData.expiration)
      {
        isValid = false;
        errors.expiration = "Expiration  is required";
      } 
    if (!formData.cvc) {
      isValid = false;
      errors.cvc = 'CVC is required.';
    } 
    if (!formData.country) {
      isValid = false;
      errors.country = "Country is required";
    }
    if (!formData.password) {
      isValid = false;
      errors.password = "Password is required";
    }
    setErrors(errors);
    return isValid;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'expiration') {
      const numericValue = value.replace(/[^\d]/g, '');
      let formattedValue = numericValue;
      if (numericValue.length >= 3) {
        formattedValue = numericValue.slice(0, 2) + '/' + numericValue.slice(2, 4);
      }
      setFormData({ ...formData, [name]: formattedValue });
    } else if (name === 'cardNumber' || name === 'cvc') {
      // Allow only numeric input
      const numericValue = value.replace(/[^\d]/g, '');
      setFormData({ ...formData, [name]: numericValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: '' });
  };

 

  const handleSubmit = async () => {
    if(validateForm()){
      console.log("PaymentData",formData);
      console.log("UserPostDetails",{ 
        clinics_data: userPostDetails.organizationData,
        practitioners_data:userPostDetails.providerData,
        clinic_photo:userPostDetails.facilityImages,
        assistances:userPostDetails.userDetails,
        clinic_insurances:userPostDetails.records,
        clinic_card_details:formData,
        clinic_services_data:userPostDetails.clinicServicesData,
        clinic_services_mapping:userPostDetails.cliniServicesMapping,
        clinic_subscriptions:userPostDetails.subscription,   
      });
      try {
        const payload = {
          clinics_data: userPostDetails.organizationData,
          practitioners_data:userPostDetails.providerData,
          clinic_photo:userPostDetails.facilityImages,
          assistances:userPostDetails.userDetails,
          clinic_insurances:userPostDetails.records,
          clinic_card_details:formData,
          clinic_services_data:userPostDetails.clinicServicesData,
          clinic_services_mapping:userPostDetails.cliniServicesMapping,
          clinic_subscriptions:userPostDetails.subscription,   
        };
        console.log('payloadpayload',payload);
        const response = await postData('auth/clinicSignupCreate', payload);
        console.log("ClinicSignupResponse",response);
        if(response.code==1){
          setIsOpen(true); 
          localStorage.clear();
          if(setIsOpen){
            setTimeout(() => {
              navigate('/')
            }, 5000);
          }
        
         
        }
    
      } catch (error) {
        if (error && error.response) {
          console.log('Invalid credentials. Please try again.');
        } else {
          console.error("Error posting data to API", error);
        }
      }
    } 
  };
  return (
    <>
      <Header />
      <div className="container mx-auto px-4 flex items-center gap-16 xss:gap-2 sm:gap-5">
      <StepsForRegistration/>
      <h1 className="font-fontPoppins text-2xl font-semibold xss:text-xs sm:text-lg leading-normal">
      Payment Setup
          </h1>
          </div>

      <section className="w-full">
        <div className="container mx-auto px-4">
          <div className="bg-grayColorLight rounded-lg p-3">
            <div className="lg:w-1/3 sm:w-4/5 md:w-4/5 mx-auto">
              <div className="w-full flex justify-between gap-2">
                <button className="bg-white rounded-lg ease-in-out shadow-sm hover:shadow-lg border border-blue-500 hover:translate-x-1 w-20 h-12">
                  <i className="fa-solid fa-credit-card"></i>
                  <span className="font-fontPoppins text-xs font-medium">
                    Card
                  </span>
                </button>
                <button className="bg-white rounded-lg ease-in-out shadow-sm hover:shadow-lg hover:translate-x-1 w-20 h-12">
                  <i className="fa-brands fa-apple-pay text-3xl"></i>
                </button>
                <button className="bg-white rounded-lg ease-in-out shadow-sm hover:shadow-lg hover:translate-x-1 w-20 h-12">
                  <i className="fa-brands fa-amazon-pay text-3xl"></i>
                </button>
                <button className="bg-white rounded-lg ease-in-out shadow-sm hover:shadow-lg hover:translate-x-1 w-20 h-12">
                  <i className="fa-brands fa-paypal text-3xl"></i>
                </button>
                <button className="bg-white rounded-lg ease-in-out shadow-sm hover:shadow-lg hover:translate-x-1 w-20 h-12">
                  <i className="fa-solid fa-building-columns text-3xl"></i>
                </button>
              </div>

              <div className="w-full mt-3">
                <div>
                  <label className="font-fontPoppins text-xs font-semibold">
                    Card Number
                  </label>
                  <div className="w-full relative">
                    <span className="z-10 absolute right-3 top-2">
                      <i className="fa-brands fa-cc-mastercard text-red-500"></i>
                      <i className="fa-brands fa-cc-visa text-blue-500"></i>
                      <i className="fa-brands fa-cc-mastercard text-blue-400"></i>
                      <i className="fa-brands fa-cc-jcb text-red-500"></i>
                    </span>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="xxxx xxxx xxxx"
                      name="cardNumber"
                      value={formData.cardNumber}
                      maxLength={12}
                      onChange={handleChange}
                    />
                    {errors.cardNumber && (
                      <p className="text-red-500 text-xs">{errors.cardNumber}</p>
                    )}
                  </div>
                </div>

                <div className="w-full md:flex justify-between gap-3 mt-3">
                  <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Expiration
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="MM/YY"
                      name="expiration"
                      value={formData.expiration}
                      onChange={handleChange}
                    />
                    {errors.expiration && (
                      <p className="text-red-500 text-xs">{errors.expiration}</p>
                    )}
                  </div>
                  <div className="w-full xss:mt-3 sm:mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      CVC
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="CVC"
                      name="cvc"
                      value={formData.cvc}
                      onChange={handleChange}
                      maxLength={4}
                    />
                    {errors.cvc && (
                      <p className="text-red-500 text-xs">{errors.cvc}</p>
                    )}
                  </div>
                </div>

                <div className="w-full md:flex justify-between gap-3 mt-3">
                  <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Country
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="United States of America"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                    />
                    {errors.country && (
                      <p className="text-red-500 text-xs">{errors.country}</p>
                    )}
                  </div>
                </div>

                <div className="w-full md:flex justify-between gap-3 mt-3">
                  <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Password
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="password"
                      placeholder="**********"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <p className="text-red-500 text-xs">{errors.password}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full mt-5 xss:mt-5 sm:mt-5 lg:min-h-10">
        <div className="container mx-auto px-4 text-center">
          <button
            onClick={handleSubmit}
            className="w-24 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
          >
            <span>Finish</span>
          </button>
        </div>
      </section>

      {isOpen && (
        <div className="like-popup w-full h-full bg-gray-400 opacity-95 z-20 fixed inset-0">
          <div className="w-full text-right">
            <span
              onClick={() => setIsOpen(!isOpen)}
              className="closeBtn ml-auto cursor-pointer text-gray-800 mr-4 mt-4"
            >
              <i className="fa-solid fa-xmark"></i>
            </span>
          </div>
          <div className="w-full h-full flex items-center justify-center">
            <div className="w-80 bg-white p-3 rounded-lg opacity-1 py-8">
              <p className="text-center text-lg font-fontPoppins font-bold">
                Your 30-day Free Membership Starts Now!
              </p>
              <div className="relative">
                <button className="w-12 h-12 bg-blueColor rounded-full border-0 z-30 absolute left-1/2 -ms-6 top-2">
                  <i className="fa-solid fa-thumbs-up text-white"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}

export default PaymentSetup;



