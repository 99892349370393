// eslint-disable-next-line no-unused-vars
import React from "react";
import { useNavigate } from "react-router-dom";
// import cancel from "/cancel.png";

const Cancel = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <div className="m-0 p-0 bg-[#FDFDFD] min-h-screen">
        <div className="w-full min-h-[80vh] flex flex-col justify-center items-center">
          <div className="my-10 text-red-600 text-2xl mx-auto flex flex-col justify-center items-center">
            <img
              src="/cancel.png"
              alt="cancel"
              width={220}
              height={220}
              className="mix-blend-multiply"
            />
            <h3 className="text-4xl pt-20 font-bold text-center text-slate-700">
              Something Went Wrong
            </h3>
            <a
              href="/"
              className="w-auto uppercase bg-slate-900 text-white text-xl my-16 px-8 py-3 rounded"
            >
              Go To Homepage
            </a>
          </div>
        </div>
      </div> */}
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-4">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md text-center">
          <svg
            className="w-20 h-20 text-red-500 mx-auto mb-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M18.364 5.636l-12.728 12.728m0-12.728l12.728 12.728"
            ></path>
          </svg>
          <h1 className="text-2xl font-bold mb-2 text-gray-800">
            Payment Failed
          </h1>
          <p className="text-gray-600 mb-6">
            Unfortunately, We Couldn't Process Your Payment. Please Try Again.
          </p>
          <button
            className="bg-red-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
            onClick={() => navigate("/pricing")}
          >
            Retry Payment
          </button>
          {/* <button
          className="mt-4 text-gray-600 underline hover:text-gray-800"
          onClick={() => window.location.href = '/support'}
        >
          Contact Support
        </button> */}
        </div>
      </div>
    </>
  );
};

export default Cancel;
