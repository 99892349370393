import React from 'react';

const CardStates = ({ title, children, color }) => {
  return (
    <div
      className="w-52 h-52 border border-gray-300 flex flex-col justify-center items-center p-4 shadow-lg rounded-lg"
      style={{ backgroundColor: color }}
    >
      <h3 className="mb-4 text-lg font-bold text-center">{title}</h3>
      <div className="flex justify-center items-center flex-grow">
        {children}
      </div>
    </div>
  );
};

export default CardStates;
