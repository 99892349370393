import React from "react";
import Select from "react-select";

const CustomSelect = ({ options, value, onChange, placeholder, name }) => {
  const formattedOptions = options.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  const selectedOption = formattedOptions.find((opt) => opt.value === value);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "40px",
      borderRadius: "9999px",
      borderColor: "#D1D5DB",

      // boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
      "&:hover": {
        // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      // color: state.isSelected ? "black" : "black",
      // backgroundColor: state.isSelected ? "#E5E7EB" : "#FFF",
      padding: "10px",
      margin: "4px 0",
      fontFamily: "'Poppins', sans-serif",

      "&:hover": {
        backgroundColor: "#3B82F6",
        color: "#FFF",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <Select
      value={selectedOption}
      onChange={(selectedOption) =>
        onChange({ target: { name, value: selectedOption.value } })
      }
      options={formattedOptions}
      className="w-full text-sm font-fontPoppins  font-500"
      styles={customStyles}
      placeholder={placeholder}
    />
  );
};

export default CustomSelect;
