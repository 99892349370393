import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postData } from "../../Networking/Api";

//import ThirdPartyHeader from "./ThirdPartyHeader";
import CustomSelect from "../CustomSelect";
//import ThirdPartyFooter from "./ThirdPartyFooter";
import Loader from "../Loader";
import CustomSelectHealth from "../CustomSelectHealth";
import ClinicBookingHeader from "./ClinicBookingHeader";
import ClinicBookingFooter from "./ClinicBookingFooter";
import Swal from "sweetalert2";
import { encrypt } from "../CryptoUtil";
import Loader2 from "../Loader2";

function CliniBookAnAppoinment() {
  // let { clinicName, city: urlCity, street: urlStreet } = useParams();
  let { clinicName, city: urlCity, street: urlStreet } = useParams();
  if (!urlStreet && urlCity && urlCity.includes("-")) {
    [urlCity, urlStreet] = urlCity.split("-");
  }

  // console.log("clinicName", clinicName);
  const [selectedOptions, setSelectedOptions] = useState({
    patientType: "",
    insuranceType: "",
    appointmentType: "",
    chooseCarrierAndPlan: "",
  });
  const [selectedOptionsId, setSelectedOptionsId] = useState({
    patientTypeId: "",
    insuranceTypeId: "",
    appointmentTypeId: "",
    chooseCarrierAndPlanId: "",
  });
  const [options, setOptions] = useState({
    patientType: [],
    insuranceType: [],
    appointmentType: [],
  });
  const [insurancePlans, setInsurancePlans] = useState([]);
  const [clinicId, setClinicId] = useState("");
  const [loading, setLoading] = useState(true);
  const [streetid, setStreetId] = useState("");
  const [clinicNameParam, setClinicNameParam] = useState("");
  const [cityStreetNameParam, setCityStreetNameParam] = useState("");
  // const [cityname, setCityName] = useState("");
  // const [streetName, setStreetname] = useState("");
  const [cityname, setCityName] = useState(urlCity || "");
  const [streetName, setStreetname] = useState(urlStreet || "");
  const navigate = useNavigate();

  useEffect(() => {
    if (!urlCity || !urlStreet) {
      setCityStreetNameParam(clinicName);
    } else {
      setCityStreetNameParam(clinicName);
      setClinicNameParam(`${urlCity}-${urlStreet}`);
    }
    // // console.log("ClinicNameParam-->", clinicNameParam);
    // // console.log("CityStreetNameParam-->", cityStreetNameParam);
  }, []);
  useEffect(() => {
    const fetchClinicdata = async () => {
      try {
        setLoading(true);
        let payload;

        if (!urlCity || !urlStreet) {
          payload = {
            clinicName: clinicName,
            nohearder: 1,
          };
          setCityStreetNameParam(clinicName);
        } else {
          setCityStreetNameParam(clinicName);
          setClinicNameParam(`${urlCity}-${urlStreet}`);
          payload = {
            clinicName: clinicName,
            cityStreetName: `${urlCity}-${urlStreet}`,
            nohearder: 1,
          };
        }
        const response = await postData("clinics/getClinicInfo", payload);
        //const data = response.data;
        setLoading(false);
        if (response.code === 0) {
          Swal.fire("Clinic location not found").then(() => {
            navigate("/patientLanding"); // Redirect to patient landing page
          });
        } else if (response.code === 1) {
          setClinicId(response.clinics.id);
          // console.log("clinicId", response.clinics.id);
          setStreetId(response.clinic_locations_data.id);
          if (!urlCity) {
            setCityName(response.clinic_locations_data.city);
          }
          if (!urlStreet) {
            setStreetname(response.clinic_locations_data.street);
          }
          // console.log("streeid", response.clinic_locations_data.id);
          fetchOptions(response.clinics.id); // Set streetId from clinic_locations_data.id
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchClinicdata();
  }, [clinicName]);

  const fetchOptions = async (clinicId) => {
    try {
      setLoading(true);
      const response = await postData("patients/getAppoinmentTypeDataNew", {
        type: "clinicServices",
        id: clinicId,
      });
      const data = response.data;
      setLoading(false);
      //setClinicId(data.clinicData[0].id);
      setOptions({
        patientType: data.patientType.map((pt) => ({
          id: pt.id,
          name: pt.name,
        })),
        insuranceType: data.insuranceType.map((it) => ({
          id: it.id,
          name: it.health_insurance_carrier_name,
        })),
        appointmentType: data.appointmentType.map((at) => ({
          id: at.id,
          name: at.name,
        })),
      });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchInsurancePlans = async (insuranceId) => {
    try {
      const response = await postData("masters/getMasterList", {
        modelName: "clinic_insurances",
        whereCondition: {
          clinic_id: clinicId,
          health_insurance_carrier_id: insuranceId,
        },
        relations: [{ module: "insurance_masters" }],
      });

      const plans = response.data.map((plan) => ({
        id: plan.id,
        name: plan.insurance_masters.insurance_name,
      }));

      return plans;
    } catch (error) {
      console.error("Error fetching insurance plans:", error);
      return [];
    }
  };

  const handleDropdownChange = async (category, key) => {
    const stringifyKey = key.target.value;
    let selectedOption;
    if (category === "chooseCarrierAndPlan") {
      selectedOption = insurancePlans.find(
        (opt) => opt.id.toString() === stringifyKey.toString()
      );
    } else {
      selectedOption = options[category].find(
        (opt) => opt.id.toString() === stringifyKey.toString()
      );
    }
    if (selectedOption) {
      const updatedOptions = {
        ...selectedOptions,
        [category]: selectedOption.name,
      };
      setSelectedOptions(updatedOptions);

      const updatedOptionsId = {
        ...selectedOptionsId,
        [`${category}Id`]: selectedOption.id,
      };
      setSelectedOptionsId(updatedOptionsId);

      if (category === "insuranceType") {
        const plans = await fetchInsurancePlans(selectedOption.id);
        setInsurancePlans(plans);

        setSelectedOptionsId((prevOptionsId) => ({
          ...prevOptionsId,
          chooseCarrierAndPlanId: "",
        }));
      }
    } else {
      console.error(
        `Selected option with key ${key} not found in category ${category}`
      );
    }
  };

  const handleInsuranceTypeChange = (type) => {
    const updatedOptions = {
      ...selectedOptions,
      insuranceType: type === "Choose Carrier and Plan" ? "" : type,
    };
    setSelectedOptions(updatedOptions);

    const updatedOptionsId = {
      ...selectedOptionsId,
      insuranceTypeId:
        type === "No Insurance"
          ? "noInsurance"
          : type === "Other"
          ? "other"
          : "",
      chooseCarrierAndPlanId:
        type === "No Insurance"
          ? "noInsurance"
          : type === "Other"
          ? "other"
          : "",
    };

    setSelectedOptionsId(updatedOptionsId);
    if (type === "Choose Carrier And Plan") {
      const updatedOptionsId = {
        ...selectedOptionsId,
        insuranceTypeId: "",
        chooseCarrierAndPlanId: "",
      };
      setSelectedOptionsId(updatedOptionsId);
    }
    //  if ((type = "No Insurance")) {
    //   const updatedOptionsId = {
    //     ...selectedOptionsId,
    //     insuranceTypeId: "",
    //     chooseCarrierAndPlanId: "noInsurance",
    //   };
    //   setSelectedOptionsId(updatedOptionsId);
    // }
  };

  const handleSaveAndNavigate = () => {
    const {
      patientTypeId,
      insuranceTypeId,
      appointmentTypeId,
      chooseCarrierAndPlanId,
    } = selectedOptionsId;

    const formattedCity = cityname.toLowerCase().replace(/\s+/g, "-");
    const formattedStreet = streetName.toLowerCase().replace(/\s+/g, "-");
    const encryptedappointmentTypeId = encrypt(appointmentTypeId.toString());
    const urlSafeAppointmentTypeId = encodeURIComponent(
      encryptedappointmentTypeId
    );
    localStorage.removeItem("selectedSlot");
    localStorage.removeItem("selectedDoctor");
    localStorage.removeItem("selectedDate");
    localStorage.removeItem("selectedAppointment");
    const url = `/clinicaddbookingdetails/${clinicName}/${formattedCity}-${formattedStreet}/${patientTypeId}/${chooseCarrierAndPlanId}/${urlSafeAppointmentTypeId}`;
    navigate(url);
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        <ClinicBookingHeader
          clinicNameParam={clinicNameParam}
          cityStreetNameParam={cityStreetNameParam}
        />

        <AppointmentForm
          selectedOptions={selectedOptions}
          selectedOptionsId={selectedOptionsId}
          setSelectedOptions={setSelectedOptions}
          setSelectedOptionsId={setSelectedOptionsId}
          handleDropdownChange={handleDropdownChange}
          handleSaveAndNavigate={handleSaveAndNavigate}
          handleInsuranceTypeChange={handleInsuranceTypeChange}
          options={options}
          insurancePlans={insurancePlans}
        />
        <ClinicBookingFooter />
      </>
    </>
  );
}

function AppointmentForm({
  selectedOptions,
  selectedOptionsId,
  setSelectedOptions,
  setSelectedOptionsId,
  handleDropdownChange,
  handleSaveAndNavigate,
  options,
  insurancePlans,
  handleInsuranceTypeChange,
}) {
  const [isSelect, setIsSelect] = useState(
    selectedOptions.insuranceType === "Choose Carrier and Plan"
  );

  const handleSave = () => {
    handleSaveAndNavigate();
  };

  const handleButtonClick = (category, option) => {
    const selectedOption = options[category].find((opt) => opt.name === option);
    if (selectedOption) {
      const updatedOptions = {
        ...selectedOptions,
        [category]: selectedOption.name,
      };
      setSelectedOptions(updatedOptions);

      const updatedOptionsId = {
        ...selectedOptionsId,
        [`${category}Id`]: selectedOption.id,
      };
      setSelectedOptionsId(updatedOptionsId);
    }
  };

  return (
    <section className="w-full min-h-mh480 mt-8">
      <div className="container mx-auto px-4">
        <div className="bg-grayColor rounded-lg p-8 shadow-md">
          {Object.keys(options).map((category) => (
            <div className="w-full  mb-6" key={category}>
              <label className="font-fontPoppins text-lg font-semibold text-gray-700 mb-2 block">
                {/* {category === "insuranceType" ? "Insurance Coverage" : category.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}{' '} */}
                {(category === "insuranceType" && "Choose Your Insurance") ||
                  (category === "patientType" &&
                    "Are You A New Or An Existing Patient") ||
                  (category === "appointmentType" && "Appointment Type")}{" "}
                {(selectedOptions[category] !== "Choose Carrier And Plan" &&
                  selectedOptions[category] &&
                  category !== "insuranceType") ||
                (category === "insuranceType" &&
                  selectedOptionsId.chooseCarrierAndPlanId) ? (
                  <CheckIcon />
                ) : null}
              </label>
              {category === "insuranceType" ? (
                <>
                  <div
                    style={{ width: "fit-content" }}
                    className={` flex flex-wrap items-center justify-start mt-2`}
                  >
                    <button
                      style={
                        isSelect
                          ? { backgroundColor: "black", color: "white" }
                          : {}
                      }
                      className="p-3 mt-2  ml-1  h-10 bg-white  font-fontPoppins text-sm font-semibold bg-white hover:bg-black hover:text-white rounded-full"
                      onClick={() => {
                        handleInsuranceTypeChange("Choose Carrier And Plan");
                        setIsSelect(true);
                      }}
                    >
                      Choose Carrier And Plan
                    </button>
                    <button
                      style={
                        selectedOptions.insuranceType === "No Insurance" &&
                        !isSelect
                          ? { backgroundColor: "black", color: "white" }
                          : {}
                      }
                      className="p-3 mt-2  ml-1  h-10 bg-white  font-fontPoppins text-sm font-semibold bg-white hover:bg-black hover:text-white rounded-full"
                      onClick={() => {
                        handleInsuranceTypeChange("No Insurance");
                        setIsSelect(false);
                      }}
                    >
                      No Insurance
                    </button>
                    <button
                      style={
                        selectedOptions.insuranceType === "Other" && !isSelect
                          ? { backgroundColor: "black", color: "white" }
                          : {}
                      }
                      className="p-3 ml-1 mt-2  h-10 bg-white  font-fontPoppins text-sm font-semibold bg-white hover:bg-black hover:text-white rounded-full"
                      onClick={() => {
                        handleInsuranceTypeChange("Other");
                        setIsSelect(false);
                      }}
                    >
                      Other
                    </button>
                  </div>

                  {isSelect && (
                    <div className=" md:w-1/2">
                      <div className=" font-bold m-1 mt-3">
                        Insurance Carrier
                      </div>
                      <CustomSelect
                        value={selectedOptionsId.insuranceTypeId}
                        onChange={(key) =>
                          handleDropdownChange("insuranceType", key)
                        }
                        options={options.insuranceType.map((option) => ({
                          value: option.id,
                          label: option.name,
                        }))}
                        placeholder="Select Your Insurance Carrier"
                      />
                      <div className="font-bold m-1 mt-3">Insurance Plan</div>
                      <CustomSelectHealth
                        name="selectedPlanBooking"
                        value={selectedOptionsId.chooseCarrierAndPlanId}
                        onChange={(key) =>
                          handleDropdownChange("chooseCarrierAndPlan", key)
                        }
                        options={insurancePlans.map((plan) => ({
                          value: plan.id,
                          label: plan.name,
                        }))}
                        placeholder="Select You Insurance Plan"
                      />
                    </div>
                  )}
                </>
              ) : category === "appointmentType" ||
                category === "patientType" ? (
                <div
                  style={{ width: "fit-content" }}
                  className={` flex flex-wrap items-center justify-start mt-2`}
                >
                  {options[category].map((option) => (
                    <button
                      key={option.id}
                      style={
                        selectedOptions[category] === option.name
                          ? { backgroundColor: "black", color: "white" }
                          : null
                      }
                      onClick={() => handleButtonClick(category, option.name)}
                      className="p-3 ml-1 mt-2 h-10 bg-white  font-fontPoppins text-sm font-semibold bg-white hover:bg-black hover:text-white rounded-full"
                    >
                      {option.name}
                    </button>
                  ))}
                </div>
              ) : (
                <CustomSelect
                  value={selectedOptionsId[`${category}Id`]}
                  onChange={(key) => handleDropdownChange(category, key)}
                  options={options[category].map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                  placeholder={`Select ${category}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="container mx-auto px-4 mt-6 text-center">
        {/* {(insuranceType === "Choose Carrier and Plan" && selectedOptionsId.chooseCarrierAndPlanId ==="other" || "noInsurance" )  */}
        {Object.entries(selectedOptionsId).every(([key, value]) => value) ? (
          <BtnBlue handleSave={handleSave} />
        ) : (
          <BtnGray />
        )}
      </div>
    </section>
  );
}

function CheckIcon() {
  return (
    <span>
      <i className="fa-solid fa-check-circle text-sky-400"></i>
    </span>
  );
}

function BtnGray() {
  return (
    <button className="w-56 h-10 font-fontPoppins font-bold bg-gray-300 text-base rounded-full text-gray-600 font-semibold hover:opacity-85 hover:cursor-not-allowed">
      Choose Appointment <i className="fa-solid fa-arrow-right-long"></i>
    </button>
  );
}

function BtnBlue({ handleSave }) {
  return (
    <button
      onClick={handleSave}
      className="w-56 h-10 font-fontPoppins font-bold bg-blueColor text-base rounded-full text-white font-semibold hover:opacity-85"
    >
      Choose Appointment <i className="fa-solid fa-arrow-right-long"></i>
    </button>
  );
}

export default CliniBookAnAppoinment;
